import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router';
import { useCanUseAwsFeatures } from './hooks/useCanUseAwsFeatures/useCanUseAwsFeatures';
import CoSellLandingPage from './pages/CoSellLandingPage';
import UnifiedOpportunityForm from './pages/UnifiedOpportunityForm';
import { UnifiedOpportunityView } from './utilities/constants';
import UnifiedInvitationDetails from './pages/UnifiedInvitationDetails';

/**
 * Entry point for CoSell V3 (UnifiedCoSell) which contains providers and routes
 */
export const CoSellV3Root = () => {
  const { path } = useRouteMatch();
  const { canUse } = useCanUseAwsFeatures();

  return (
    <ReactQueryClientProvider>
      <Switch>
        <Route component={CoSellLandingPage} exact path={path} />
        {canUse.opportunities.create && (
          <Route
            component={CreateOpportunity}
            exact
            path={`${path}/opportunity/:cloud`}
          />
        )}
        <Route
          component={OpportunityDetails}
          exact
          path={`${path}/opportunity/:cloud/:opportunityId`}
        />
        {canUse.opportunities.edit && (
          <Route
            component={OpportunityDetails}
            exact
            path={`${path}/opportunity/:cloud/:opportunityId/edit`}
          />
        )}
        <Route
          component={UnifiedInvitationDetails}
          exact
          path={`${path}/invitation/:cloud/:invitationId`}
        />
        <Redirect to={path} />
      </Switch>
    </ReactQueryClientProvider>
  );
};

/** component for creating an opportunity
 *
 *  Note: this component exists to handle custom props that exist from the previous implementation, before we used nested routes
 */
const CreateOpportunity = () => {
  const { url } = useRouteMatch();
  const params = useParams();
  return (
    <UnifiedOpportunityForm
      view={UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM}
      match={{
        params,
        url: `${url}`,
      }}
    />
  );
};

/**
 * This component is used for both viewing and editing an opportunity.
 *
 * Note: this component exists to handle custom props that exist from the previous implementation, before we used nested routes
 *
 * TODO:
 * - update this to use a react-router Switch with nested routes.
 * - remove the requirement for passing params/match to UnifiedOpportunityForm, so we can use the built-in react-router hooks useParams and useRouteMatch
 */
const OpportunityDetails = () => {
  const { url, path } = useRouteMatch();
  const params = useParams();
  const isEdit = path.endsWith('/edit');
  return (
    <UnifiedOpportunityForm
      view={
        !isEdit
          ? UnifiedOpportunityView.CO_SELL_DETAILS
          : UnifiedOpportunityView.EDIT_CO_SELL_FORM
      }
      match={{
        params,
        url: `${url}`,
      }}
    />
  );
};
