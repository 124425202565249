import { gql, NetworkStatus } from '@apollo/client';
import { useState } from 'react';
import { Button } from '@tackle-io/platform-ui';
import { Box, List, Typography } from 'vendor/material';
import {
  useChangeUsersVendorMutation,
  useGetVendorsQuery,
  useGetAccountListOrganizationVendorsQuery,
} from 'generated/graphql';

// ASSETS
import useStyles from './AccountList.styles';

import { compact, findIndex, without } from 'lodash';

import AccountListItem, { LoadingAccountListItem } from './AccountListItem';
import { useAuth } from 'vendor/auth0';
import { ampli } from 'utils/analytics/ampli';
import { VendorEnvironmentEnum } from 'utils/constants';

const PAGE_SIZE = 50;
const LOAD_MORE_SCROLL_OFFSET = 50;

export const GET_VENDORS_QUERY = gql`
  query getVendors($token: String, $size: Int, $search: String) {
    vendors(pageToken: $token, pageSize: $size, search: $search) {
      vendors {
        id
        name
        logo_url
        vendor_type
        production_vendor_id
      }
      nextPageToken
    }
  }
`;

export const ACCOUNT_LIST_GET_ORG_VENDORS_QUERY = gql`
  query getAccountListOrganizationVendors {
    currentVendors {
      id
      logo_url
      name
      vendor_type
      production_vendor_id
    }
  }
`;

export const CHANGE_USERS_VENDOR_MUTATION = gql`
  mutation changeUsersVendor($vendorid: String!) {
    switchVendors(vendorid: $vendorid) {
      vendorId
    }
  }
`;

export const useSwapVendors = ({ onCompleted }) => {
  // is there anything we can do eliminate avoid this store?
  // without it this component is completely independent of the
  // rest of the application.
  const { getAccessTokenSilently } = useAuth();
  const [mutation, mutationState] = useChangeUsersVendorMutation({
    onCompleted,
  });

  const swapVendors = (vendorid: string): void => {
    mutation({ variables: { vendorid } }).then(async ({ data: d }) => {
      // currently if this call fails, we are logged out.
      if (!mutationState?.error && d.switchVendors?.vendorId === vendorid) {
        ampli.vendorAccountSwitched({ new_vendor_id: vendorid });
        await getAccessTokenSilently({ ignoreCache: true });
        window.location.reload();
        return;
      } else {
        throw Error('Failed To Change Vendors, Please Try Again.');
      }
    });
  };

  return {
    swapVendors,
    ...mutationState,
  };
};

type AccountListProps = {
  filterValue: string;
  activeAccountId: string;
  isSuperAdmin: boolean;
};

const Loading = () => {
  return (
    <>
      <LoadingAccountListItem />
      <LoadingAccountListItem />
      <LoadingAccountListItem />
      <LoadingAccountListItem />
      <LoadingAccountListItem />
    </>
  );
};

const Empty = ({ filterValue }: { filterValue: string }) => {
  const classes = useStyles();

  return (
    <List className={classes.accountList}>
      <Typography variant="body2" className={classes.accountListZero}>
        No account matches "{filterValue}".
      </Typography>
    </List>
  );
};

type FailedProps = {
  onRetry: () => void;
};

const Failed = ({ onRetry }: FailedProps) => {
  const classes = useStyles();

  return (
    <List className={classes.accountList}>
      <Typography
        align="center"
        variant="body2"
        className={classes.accountListFailed}
      >
        Failed to load accounts.
        <Box mt={2}>
          <Button variant="outlined" onClick={() => onRetry()}>
            Retry
          </Button>
        </Box>
      </Typography>
    </List>
  );
};

const AccountList = ({
  filterValue,
  activeAccountId,
  isSuperAdmin = false,
}: AccountListProps) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState(null);
  const [swapSucceeded, setSwapSucceeded] = useState(false);
  const queryVariables = {
    token: null,
    size: PAGE_SIZE,
    search: filterValue,
  };

  const {
    data: vendorData,
    fetchMore,
    refetch,
    error: vendorError,
    networkStatus: vendorNetworkStatus,
  } = useGetVendorsQuery({
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: isSuperAdmin,
  });

  const {
    data: orgData,
    error: orgError,
    networkStatus: orgNetworkStatus,
  } = useGetAccountListOrganizationVendorsQuery({
    skip: !isSuperAdmin,
  });

  const data = isSuperAdmin
    ? orgData?.currentVendors
    : vendorData?.vendors?.vendors;

  // Sandbox vendor_type is not allowed in the Account List
  // Users must default to the production account and use the environment switcher
  const vendors =
    data?.filter(
      (vendor) => vendor.vendor_type !== VendorEnvironmentEnum.SANDBOX,
    ) ?? [];

  const loadingStatuses = [
    NetworkStatus.refetch,
    NetworkStatus.fetchMore,
    NetworkStatus.loading,
    NetworkStatus.setVariables,
  ];
  const showLoading =
    loadingStatuses.includes(vendorNetworkStatus) ||
    loadingStatuses.includes(orgNetworkStatus);

  const handleFetchNextPage = () => {
    const { nextPageToken } = vendorData?.vendors || {};
    if (nextPageToken && !showLoading) {
      fetchMore({
        variables: {
          ...queryVariables,
          token: nextPageToken,
        },

        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          return {
            vendors: {
              ...fetchMoreResult.vendors,
              vendors: [
                ...(previousQueryResult?.vendors?.vendors ?? []),
                ...fetchMoreResult.vendors.vendors,
              ],
            },
          };
        },
      });
    }
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    if (scrollHeight - scrollTop <= clientHeight + LOAD_MORE_SCROLL_OFFSET) {
      handleFetchNextPage();
    }
  };

  const handleSwapSuccess = () => {
    setSwapSucceeded(true);
  };

  const {
    swapVendors,
    loading: swapLoading,
    error: swapError,
  } = useSwapVendors({ onCompleted: handleSwapSuccess });

  const handleClickItem = (item: { id: string }) => {
    if (!swapLoading) {
      setSelectedId(item.id);
      swapVendors(item.id);
    }
  };

  if (vendorError || orgError) {
    return <Failed onRetry={() => refetch()} />;
  }

  if (!showLoading && vendors?.length === 0) {
    return <Empty filterValue={filterValue} />;
  }

  const selectedIndex = findIndex(
    vendors,
    (vendor) => vendor.id === activeAccountId,
  );
  const activeVendor = vendors[selectedIndex];

  const sortedVendors = compact([
    activeVendor,
    ...without(vendors, activeVendor),
  ]);

  return (
    <List className={classes.accountList} onScroll={handleScroll}>
      {sortedVendors.map((v) => (
        <AccountListItem
          key={v.id}
          account={v}
          active={activeAccountId === v.id}
          loading={swapLoading && selectedId === v.id}
          onClick={handleClickItem}
          failed={swapError && selectedId === v.id}
          succeeded={swapSucceeded && selectedId === v.id}
        />
      ))}
      {showLoading && <Loading />}
    </List>
  );
};

export default AccountList;
