import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { CustomerAccount } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { pick } from 'lodash-es';

interface InvitationDetails {
  customer?: CustomerAccount;
}

const getFlattenedProps = (detailProps: InvitationDetails) => ({
  ...pick(detailProps.customer, [
    'companyName',
    'industry',
    'websiteUrl',
    'countryCode',
  ]),
});

const AceCustomerDetails: React.FC<InvitationDetails> = (detailProps) => {
  const { companyName, industry, websiteUrl, countryCode } =
    getFlattenedProps(detailProps);

  return (
    <DetailsSectionCard title="Customer details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Customer company name"
          value={companyName}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_COMPANY_NAME}
        />

        <InfoItem
          itemTitle="Industry vertical"
          value={industry}
          dataId={coSellAceOpportunityFormFieldsDataId.CITY}
        />
        <InfoItem
          itemTitle="Customer website"
          value={websiteUrl}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_WEBSITE}
        />
        <InfoItem
          itemTitle="Country"
          value={countryCode}
          dataId={coSellAceOpportunityFormFieldsDataId.COUNTRY_CODE}
        />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceCustomerDetails;
