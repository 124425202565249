import React from 'react';
import { Box, Grid } from 'vendor/material';
import useStyles from './DetailsCard.style';

interface SectionRow {
  label: string;
  value: string | React.ReactNode;
  footer?: string | React.ReactNode;
}

interface CardBodySection {
  sectionHeader: string;
  rows: SectionRow[];
}

interface DetailsCardProps {
  icon: React.ReactNode;
  headerText: string;
  cardBodySections: CardBodySection[];
}

const DetailsCard: React.FC<DetailsCardProps> = ({
  icon,
  headerText,
  cardBodySections,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.cardHeader}>
        {icon}
        <span>{headerText}</span>
      </Box>
      <Box className={classes.cardBody}>
        {cardBodySections.map((section, sectionIndex) => (
          <Grid
            container
            spacing={2}
            className={classes.cardBodySection}
            key={sectionIndex}
          >
            <Grid item xs={12}>
              <span className={classes.sectionHeader}>
                {section.sectionHeader}
              </span>
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={4}
              className={classes.sectionRow}
            >
              {section.rows.map((row, rowIndex) => (
                <Grid
                  item
                  sm={4}
                  className={classes.itemContainer}
                  key={rowIndex}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    gridGap={4}
                  >
                    <span className={classes.sectionItemHeader}>
                      {row.label}
                    </span>
                    <span className={classes.sectionItemContent}>
                      {row.value}
                    </span>
                    {row.footer && (
                      <span className={classes.sectionItemFooter}>
                        {row.footer}
                      </span>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default DetailsCard;
