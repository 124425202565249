import { Button } from '@tackle-io/platform-ui';
import TackleAnimatedHandshake from 'components/TackleAnimatedHandshake/TackleAnimatedHandshake';
import { Grid, makeStyles, Typography } from 'vendor/material';
import { LightbulbOnOutline } from 'mdi-material-ui';
import { useState } from 'react';
import StartAWSOffer from './StartAWSOffer';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: '100%',
    background: 'linear-gradient(to bottom, #FFFFFF99, #DEF0F399)',
  },
  expertTip: {
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    '& svg': {
      fill: theme.palette.YELLOW500,
      fontSize: '1.7em',
      verticalAlign: 'text-bottom',
    },
  },
  button: {
    backgroundColor: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
}));

const OffersCallToAction = () => {
  const classes = useStyles();

  const [startingNewAWSOffer, setStartingNewAWSOffer] = useState(false);
  if (startingNewAWSOffer) {
    return <StartAWSOffer onCancel={() => setStartingNewAWSOffer(false)} />;
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item xs container>
        <Grid
          item
          xs
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <TackleAnimatedHandshake />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              Create private offers, track buyer progress, and book revenue
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              align="center"
              className={classes.expertTip}
            >
              <LightbulbOnOutline fontSize="inherit" /> Tackle expert tip
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              Cloud Marketplace transactions on Tackle can be up to 1.5x larger
              than other channels.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs>
          <Button
            fullWidth
            variant="outlined"
            appearance="primary"
            className={classes.button}
            onClick={() => setStartingNewAWSOffer(true)}
          >
            Create AWS Offer
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            disabled
            fullWidth
            variant="outlined"
            appearance="primary"
            className={classes.button}
            onClick={() => alert('not yet implemented')}
          >
            Create Microsoft Offer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OffersCallToAction;
