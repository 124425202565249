import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import { SyncedIcon } from 'packages/cosell/assets';
import React from 'react';

export interface RightRailContentConfig {
  title: string;
  icon?: JSX.Element;
  guidanceText: string | React.ReactNode;
  subTitle?: string;
  subIcon?: JSX.Element;
}

// TITLES CONSTANTS
export const NEW_INVITATION = 'New invitation';
const INVITATION_ACCEPTED = 'Invitation accepted';
const INVITATION_REJECTED = 'Invitation rejected';
export const CLOUD_CONTACTS_TITLE = 'Cloud contacts';

// GUIDANCE TEXTS CONSTANTS
export const NEW_INVITATION_GUIDANCE_TEST =
  'You’ve been invited to co-sell with your AWS cloud partner! Accept it to view all the invitation details and start co-selling with your cloud partner, or reject the invitation.';
export const NO_CLOUD_CONTACT_GUIDANCE_TEXT =
  'You don’t have any cloud contacts yet. Your cloud partner will share them after this co-sell opportunity has been submitted and approved for co-selling.';

const INVITATION_ACCEPTED_GUIDANCE_TEXT =
  'This invitation was accepted for co-selling! View the opportunity to start co-selling with your cloud partner.';
const INVITATION_REJECTED_GUIDANCE_TEXT =
  'This invitation was rejected for the reason above. You can contact your cloud partner to follow up.';
export const getRightRailContentConfig = (
  status: AceInvitationCloudStatusEnum,
): RightRailContentConfig => {
  // handle default status configuration
  const config: Record<AceInvitationCloudStatusEnum, RightRailContentConfig> = {
    [AceInvitationCloudStatusEnum.PENDING]: {
      title: NEW_INVITATION,
      icon: <SyncedIcon />,
      guidanceText: NEW_INVITATION_GUIDANCE_TEST,
    },
    [AceInvitationCloudStatusEnum.ACCEPTED]: {
      title: INVITATION_ACCEPTED,
      icon: <SyncedIcon />,
      guidanceText: INVITATION_ACCEPTED_GUIDANCE_TEXT,
    },
    [AceInvitationCloudStatusEnum.REJECTED]: {
      title: INVITATION_REJECTED,
      icon: <SyncedIcon />,
      guidanceText: INVITATION_REJECTED_GUIDANCE_TEXT,
    },
  };

  return config[status];
};

// the cloud partner will share cloud contacts after approve or reject the opportunity
export const shouldRenderCloudContact = (
  status: AceInvitationCloudStatusEnum,
): boolean => {
  const approvedOrRejected = [
    AceInvitationCloudStatusEnum.REJECTED,
    AceInvitationCloudStatusEnum.ACCEPTED,
  ];
  return approvedOrRejected.includes(status);
};
