import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';

export enum PrivateOfferFeatureFlags {
  MARKETPLACE_NOTIFICATION = 'private-offers-cloud-outage',
  USE_AZURE_MARKETPLACE_PRICING_V1 = 'ui-azure-offers-use-marketplace-pricing-v1-version',
  GCP_FLAT_FEE_USAGE_ENABLED = 'gcp-offers-enable-flat-fee-usage-pricing',
}

// redefining this for now since the FeatureFlags interface isn't exported from AmplitudeFlagsProvider.d.ts
export type FeatureFlags = ReturnType<typeof useAmpliFeatureFlags>['flags'];

const enabledFlag = 'on';

export const isFeatureFlagEnabled = (
  flags: FeatureFlags,
  flag: PrivateOfferFeatureFlags | string,
): boolean => {
  return flags[flag] === enabledFlag;
};
