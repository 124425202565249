import { PrivateOfferV2 } from 'stores/privateOffers/PrivateOfferV2';
import { Box, Grid } from 'vendor/material';

const AZUREOffersForOpportunity = ({
  azureOffers,
}: {
  azureOffers: PrivateOfferV2[];
}) => {
  return (
    <Box height="100%">
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <span>Microsoft not yet implemented</span>
        </Grid>
        <Grid item>
          <pre>{JSON.stringify(azureOffers, null, 2)}</pre>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AZUREOffersForOpportunity;
