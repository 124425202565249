import classnames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import { Typography } from 'vendor/material';
import { HeaderColumn } from '../../types';
import {
  defaultInvitationColumnsObj,
  InvitationsTableColumnNames,
} from '../../utils';
import SortHeader from '../SortHeader/SortHeader';
import useStyles from './TableHeader.styles';
import { InvitationColumnKey } from '../../../../helpers/urlParamsHelper';

interface TableHeaderProps {
  tableColumns: HeaderColumn[];
  setColumnWidths: Dispatch<SetStateAction<Object>>;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  tableColumns,
  setColumnWidths,
}) => {
  const classes = useStyles();

  // Gets the widths of the column headers and passes that value to the
  // TableRowCell in order to maintain consistency
  const getColumnWidths = useCallback((): void => {
    let columnObj = { ...defaultInvitationColumnsObj };

    for (let c in headerRowRef?.current?.children) {
      const name = headerRowRef?.current?.children[c].id;
      const width = headerRowRef?.current?.children[c].clientWidth;
      columnObj[name] = width;
    }
    setColumnWidths(columnObj);
  }, [setColumnWidths]);

  useLayoutEffect(() => {
    getColumnWidths();
  }, [getColumnWidths]);

  const headerRowRef = useRef<HTMLDivElement>(null);
  const flexGrowSmallColumns = [
    InvitationsTableColumnNames.DATE_RECEIVED,
    InvitationsTableColumnNames.INVITATION_STATUS,
    InvitationsTableColumnNames.EXPIRATION_DATE,
  ];
  const flexGrowMediumColumns = [InvitationsTableColumnNames.CUSTOMER];
  const flexFromLargeColumns = [InvitationsTableColumnNames.PROJECT_NAME];

  const shouldAlignRight = (
    columnName: InvitationsTableColumnNames,
  ): boolean => {
    const rightAlignmentColumns = [InvitationsTableColumnNames.SYNC_STATUS];
    return rightAlignmentColumns.includes(columnName);
  };

  return (
    <div className={classes.container} role={'row'} ref={headerRowRef}>
      {tableColumns.map((column) => {
        return (
          <div
            key={column.field}
            className={classnames(classes.headerColumnWrapper, {
              [classes.flexGrowCheckbox]:
                column.field === InvitationsTableColumnNames.CHECKBOX,
              [classes.fixedSyncStatus]:
                column.field === InvitationsTableColumnNames.SYNC_STATUS,
              [classes.flexGrowSm]: flexGrowSmallColumns.includes(column.field),
              [classes.flexGrowMd]: flexGrowMediumColumns.includes(
                column.field,
              ),
              [classes.flexGrowLg]: flexFromLargeColumns.includes(column.field),
              [classes.alignRight]: shouldAlignRight(column.field),
            })}
            id={column.field}
            role={'columnheader'}
            tabIndex={0}
            data-id={column.dataId}
          >
            {column.field !== InvitationsTableColumnNames.DATE_RECEIVED ? (
              <>
                <Typography className={classes.headerText}>
                  {column.headerName}
                </Typography>
              </>
            ) : (
              <SortHeader
                columnKey={InvitationColumnKey[column.field]}
                sortState={{
                  sortKey: InvitationColumnKey[column.field],
                  orderAscending: false,
                }}
              >
                <Typography className={classes.headerText}>
                  {column.headerName}
                </Typography>
              </SortHeader>
            )}
          </div>
        );
      })}
    </div>
  );
};
