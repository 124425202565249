import { useSalesforceCoSellWidgetDetailTable } from './SalesforceCoSellWidgetDetailTable.styles';
import { Box, Grid } from 'vendor/material';
import { SalesforceCoSellWidgetDetailTableItem as SFDataItem } from '../SalesforceCoSellWidgetDetailTableItem/SalesforceCoSellWidgetDetailTableItem';
import { AwsCoSellStageTag } from '../../AwsCoSellStageTag';
import { AwsCoSellStatusTag } from '../../AwsCoSellStatusTag';
import { toDateTime } from 'utils/dates';
import { CurrencyCode, formatValueWithCurrencyPrefix } from 'utils/currency';
import { DisplayCloudType } from 'pages/CoSell/types/enums';
import { TextWithCloudIconPrefix } from '../../TextWithCloudIconPrefix/TextWithCloudIconPrefix';
import {
  AceOpportunityReviewStatusEnum,
  AceOpportunityStageEnum,
  SourceEnum,
} from 'packages/cosell/src/types/enums';
import { ExpectedCustomerSpend } from 'packages/cosell/src/types/AceOpportunity.type';

export const SalesforceCoSellWidgetDetailTable = ({
  opportunity,
}: {
  opportunity: {
    cloudProviderId: string;
    companyName: string;
    created: string;
    crmId: string;
    hasErrors: boolean;
    /** tackle co-sell opportunity id */
    id: string;
    /** ISO 8601 format */
    lastModified: string;
    opportunityType: string;
    source: SourceEnum;
    /** ISO 8601 format */
    targetCloseDate: string;
    title: string;
    pendingRequestIds?: string[];
    status?: AceOpportunityReviewStatusEnum;
    stage?: AceOpportunityStageEnum;
    expectedCustomerSpend?: ExpectedCustomerSpend;
  };
}) => {
  const classes = useSalesforceCoSellWidgetDetailTable();

  // TODO: derive this from the opportunity object
  const cloudType = DisplayCloudType.AWS;

  const {
    // created,
    // crmId,
    expectedCustomerSpend,
    lastModified,
    // source,
    targetCloseDate,
    title,
  } = opportunity;

  return (
    <div className={classes.content}>
      {/** inline alert here:
       * https://www.figma.com/design/7c1FYOoYt90UnD8zfjNcOa/UX-1610%3A-Read-only-SFDC-Views?node-id=3096-21528&m=dev
       */}
      <Grid container direction="column" className={classes.tableRow}>
        <SFDataItem title="Co-Sell Name">
          <TextWithCloudIconPrefix icon={{ provider: cloudType }}>
            {title}
          </TextWithCloudIconPrefix>
        </SFDataItem>
      </Grid>
      {/* <Grid container direction="row" className={classes.tableRow} spacing={1}>
        <SFDataItem title="Source partner">{source}</SFDataItem>
      </Grid> */}
      <Grid container direction="row" className={classes.tableRow}>
        <SFDataItem title="Co-sell status">
          <div className={classes.tag}>
            <AwsCoSellStatusTag status={opportunity.status} />
          </div>
        </SFDataItem>
        <SFDataItem title="Co-sell stage">
          <div className={classes.tag}>
            <AwsCoSellStageTag stage={opportunity.stage} />
          </div>
        </SFDataItem>
      </Grid>

      <Grid container direction="row" className={classes.tableRow}>
        <SFDataItem title="Last updated">
          {lastModified ? toDateTime(lastModified).toFormat('MM/dd/yy') : '-'}
        </SFDataItem>
        <SFDataItem title="Target close date">
          {targetCloseDate
            ? toDateTime(opportunity.targetCloseDate).toFormat('MM/dd/yy')
            : '-'}
        </SFDataItem>
      </Grid>

      {expectedCustomerSpend && (
        <>
          {expectedCustomerSpend.map(
            ({ amount, currencyCode, frequency, targetCompany }, i) => (
              <Grid
                container
                direction="row"
                key={`${amount}-${i}`}
                className={classes.tableRow}
              >
                <SFDataItem
                  key={`${amount}-${currencyCode}-${i}`}
                  title={`${targetCompany} expected customer spend`}
                  width={12}
                >
                  <Box>
                    <span>{currencyCode}</span>{' '}
                    {formatValueWithCurrencyPrefix(
                      amount,
                      currencyCode as CurrencyCode,
                      /**  currency format without prefix */
                      '0,0.00',
                    )}{' '}
                    <span>{frequency?.toLowerCase()}</span>
                  </Box>
                </SFDataItem>
              </Grid>
            ),
          )}
        </>
      )}
    </div>
  );
};
