import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.12))',
    borderRadius: '4px',
  },
  cardHeader: {
    background: '#FFFFFF',
    width: '100%',
    display: 'flex',
    gap: '8px',
    padding: theme.spacing(2, 3),
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#253858',
  },
  cardBody: {
    background: '#FAFBFC',
    width: '100%',
    padding: '24px',
    flexDirection: 'column',
    display: 'flex',
    gap: '24px',
  },
  cardBodySection: {},
  sectionHeader: {
    color: '#253858',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  sectionRow: {
    // borderBottom: '1px solid #EBECF0'
  },
  itemContainer: {},
  sectionItemHeader: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: '#5E6C84',
  },
  sectionItemContent: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: '#091E42',
  },
  sectionItemFooter: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: '#5E6C84',
  },
}));

export default useStyles;
