import { SelectFormFieldOption } from 'pages/CoSell/types';
import { MsftNeedFromCloudEnumValues } from 'pages/CoSell/types/enums';

export const MsftNeedFromCloudList: SelectFormFieldOption[] = [
  {
    title: 'General or other',
    value: MsftNeedFromCloudEnumValues.GENERAL_OR_OTHER,
  },
  {
    title: 'Workload - specific value proposition',
    value: MsftNeedFromCloudEnumValues.WORKLOAD,
  },
  {
    title: 'Customer technical architecture',
    value: MsftNeedFromCloudEnumValues.CUSTOMER_TECHNICAL_ARCHITECTURE,
  },
  {
    title: 'Proof of concept / Demo',
    value: MsftNeedFromCloudEnumValues.PROOF_OF_CONCEPT,
  },
  {
    title: 'Quotes / Licensing',
    value: MsftNeedFromCloudEnumValues.QUOTES,
  },
  {
    title: 'Post - sales customer success',
    value: MsftNeedFromCloudEnumValues.POST_SALES_CUSTOMER_SUCCESS,
  },
  {
    title: 'No help required at this time',
    value: MsftNeedFromCloudEnumValues.NO_HELP_REQUIRED,
  },
];
