export const getTackleSessionFromOtk = async (otk: string) => {
  const otkRequest = await fetch(
    `${process.env.REACT_APP_TKL_AUTH_SERVICE_URL}/api/v1/auth/one-time-keys/exchange`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key: otk,
      }),
    },
  );

  if (otkRequest.ok) {
    const otkRequestJson =
      (await otkRequest.json()) as IOneTimeKeyExchangeResponse;
    return otkRequestJson;
  }
};

export interface IOneTimeKeyExchangeResponse {
  /** auth token used as Bearer token for Tackle API requests */
  access_token: string;
  expires_in_seconds: number;
  context: SalesforceCanvasContext;
}

/** available canvas components/actions. */
export type ComponentId = 'CanvasApp' | 'Setup' | 'TackleWidget';

/** data for salesforce record id, etc */
type RecordType = {
  Id: string;
  /** this should be a union of known objects */
  objectName: 'Opportunity';
};

interface SalesforceCanvasContext {
  client: Client;
  dimensions: Dimensions;
  /**
   * All data contained in Parameters is controlled by our tackle salesforce team.
   * if values are duplicated in the parent session object, use the values here.
   * Parameters vary depending on the Parameter.context value
   */
  parameters: Parameters;
}

interface Client {
  instanceId: string;
  oauthToken: string;
  instanceUrl: string;
  /** currently not implemented, so always returns null */
  refreshToken: null;
  targetOrigin: string;
}

/** dimensions of Canvas App in pixels */
interface Dimensions {
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string;
  clientWidth: string;
  clientHeight: string;
}

type TacklePermission =
  | 'tackle_create_co_sells'
  | 'tackle_create_co_sells'
  | 'tackle_integration_connection'
  | 'tackle_view_co_sell_details';

/**
 *  TODO: update this to conditionally include fields based on the context
 */
interface Parameters {
  componentId: ComponentId;
  namespace: string;
  permissions: Record<TacklePermission, boolean>;
  redirectUri: string;
  /** returned for single record contexts */
  record?: RecordType;
  /** returned for bulk record contextx */
  records?: RecordType[];
  /** authenticated tackle vendor id */
  vendorId: string;
  /** payload context from previous step
   *  TODO:
   *  - set to a union of all possible payloads for better type inference & safety
   */
  payload?: Record<string, any> & {
    record: RecordType;
    action: string;
    domain: 'cosell' | 'offers';
    cloud: string;
  };
}
