import { useQuery, useQueryClient } from '@tanstack/react-query';
import coSellClient from '../coSellClient';
import { useMutation } from '@tanstack/react-query';
import { TackleOperationId } from '../utils';
import {
  CreateAceOpportunityRequest,
  UpdateAceOpportunityRequest,
} from 'packages/cosell/src/types/requests/AceOpportunityRequest';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

export const ACE_OPPORTUNITY_QUERY_KEY = 'co-sell-aws-opportunity' as const;

export const useAceOpportunity = ({
  opportunityId,
}: {
  opportunityId?: string;
}) => {
  const queryClient = useQueryClient();

  const aceOpportunityQuery = useQuery({
    queryKey: [ACE_OPPORTUNITY_QUERY_KEY, opportunityId],
    queryFn: () => coSellClient.getOpportunityByIdV3(opportunityId!),
    enabled: !!opportunityId,
    initialData: {} as AceOpportunityResponse,
    /** we only want to return opportunities that have been created in the cloud */
    select: (data) => {
      if (!!data && 'id' in data) {
        return data;
      }
      return {} as AceOpportunityResponse;
    },
  });

  const createAceOpportunity = useMutation({
    mutationFn: async ({
      requestBody,
      tackleOperationId,
    }: {
      requestBody: CreateAceOpportunityRequest;
      tackleOperationId: TackleOperationId;
    }) => await coSellClient.createOpportunity(requestBody, tackleOperationId),
  });

  /**TODO: refactor implementation to pass in opportunityId */
  const updateAceOpportunity = useMutation({
    mutationFn: async ({
      requestBody,
      tackleOperationId,
    }: {
      requestBody: UpdateAceOpportunityRequest;
      tackleOperationId: TackleOperationId;
    }) =>
      await coSellClient.updateOpportunity(
        requestBody,
        /** opportunity has to exist to get to this point */
        opportunityId!,
        tackleOperationId,
      ),

    onSuccess: () => {
      queryClient.invalidateQueries([ACE_OPPORTUNITY_QUERY_KEY, opportunityId]);
    },
  });

  return {
    aceOpportunityQuery,
    createAceOpportunity,
    updateAceOpportunity,
  };
};
