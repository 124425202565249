import { Button, ProviderIcon } from '@tackle-io/platform-ui';
import { Box, Divider, Grid, makeStyles, Typography } from 'vendor/material';
import { useCanvasSession } from '../src/useCanvasSession';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  contents: {
    overflow: 'auto',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
}));

const StartAWSOffer = ({ onCancel }: { onCancel: () => void }) => {
  const classes = useStyles();
  const { canvasClient, namespace, record } = useCanvasSession();
  return (
    <Box px={2} height="100%">
      <Grid
        container
        direction="column"
        className={classes.container}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5">
            <Grid container spacing={2}>
              <Grid item>
                <ProviderIcon provider="aws" />
              </Grid>
              <Grid item>Create Offer</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid
          item
          xs
          container
          direction="column"
          wrap="nowrap"
          spacing={2}
          className={classes.contents}
        >
          <Grid item>
            <Typography>Offer type</Typography>
          </Grid>
          <Grid item>
            <label>
              <input type="radio" name="offerType" value="direct" />
              Direct
            </label>
          </Grid>
          <Grid item>
            <label>
              <input type="radio" name="offerType" value="partner" />
              Partner
            </label>
          </Grid>
          <Grid item>
            <Typography>
              Create new or associate to an existing offer
            </Typography>
          </Grid>
          <Grid item>
            <label>
              <input type="radio" name="newOrAbo" value="new" />
              New offer
            </label>
          </Grid>
          <Grid item>
            <label>
              <input type="radio" name="newOrAbo" value="abo" />
              Associate to an existing offer
            </label>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs>
            <Button
              fullWidth
              variant="outlined"
              appearance="primary"
              className={classes.button}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              fullWidth
              variant="contained"
              appearance="primary"
              className={classes.button}
              onClick={() =>
                Sfdc.canvas.client.publish(canvasClient, {
                  name: `${namespace}.offers`,
                  payload: {
                    navigate: 'CanvasApp',
                    action: 'create',
                    domain: 'offers',
                    cloud: 'aws',
                    record,
                  },
                })
              }
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StartAWSOffer;
