import { useSaasDocumentationRequiredToLaunchCheck } from 'packages/cosell/src/hooks/useSaasDocumentationRequiredToLaunchCheck';
import { useAceVendorSolutionsQuery } from './useAceVendorSolutionsQuery';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';

/**
 * provides Ace configuration data specific to the vendor including:
 *  - solutions list
 *  - isSaasDocumentationRequiredToLaunch
 */
export const useAceVendorConfig = () => {
  const { vendorId } = useCoSellContext();

  const solutionsListQuery = useAceVendorSolutionsQuery({ vendorId });
  const {
    isSaasDocumentationRequiredToLaunch,
    isCustomWorkflowLoading,
    isErrorLoadingCustomWorkflow,
  } = useSaasDocumentationRequiredToLaunchCheck({ vendorId });

  return {
    vendorId,
    isLoading: solutionsListQuery.isLoading || isCustomWorkflowLoading,
    isError: solutionsListQuery.isError || isErrorLoadingCustomWorkflow,
    solutionsList: solutionsListQuery.data,
    isSaasDocumentationRequiredToLaunch,
  };
};
