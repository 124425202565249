import { makeStyles } from 'vendor/material';

export const useSalesforceCoSellWidgetDetailTable = makeStyles((theme) => ({
  content: {
    color: theme.palette.NEUTRAL700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    paddingInline: theme.spacing(1),
  },
  tableRow: {
    marginBottom: theme.spacing(1.5),
  },
  tag: {
    '& span': {
      fontSize: `12px !important`,
    },
  },
}));
