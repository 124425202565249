import React from 'react';
import useStyles from './RightRailButtonSection.style';
import { Box } from 'vendor/material';
import { useCanUseAwsFeatures } from 'packages/cosell/src/hooks/useCanUseAwsFeatures/useCanUseAwsFeatures';
import { Button } from '@tackle-io/platform-ui';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';

interface RightRailButtonSectionProps {
  status: AceInvitationCloudStatusEnum;
}

const RightRailButtonSection: React.FC<RightRailButtonSectionProps> = ({
  status,
}) => {
  const classes = useStyles();

  const variantByStatus = 'contained';

  const { canUse } = useCanUseAwsFeatures();

  return (
    <Box className={classes.root}>
      {status === AceInvitationCloudStatusEnum.ACCEPTED && (
        <Button
          disabled={!canUse.invitations.view}
          variant={variantByStatus}
          data-id={coSellDetailsPageDataId.VIEW_OPPORTUNITY}
          appearance="primary"
          onClick={() => {}}
        >
          View co-sell
        </Button>
      )}

      {status === AceInvitationCloudStatusEnum.PENDING && (
        <Box className={classes.actionButton}>
          <Button
            disabled={!canUse.invitations.close}
            variant="contained"
            data-id={coSellDetailsPageDataId.ACCEPT_INVITATION}
            appearance="primary"
            onClick={() => {}}
          >
            Accept
          </Button>
          <Button
            disabled={!canUse.invitations.close}
            variant="contained"
            data-id={coSellDetailsPageDataId.REJECT_INVITATION}
            appearance="destructive"
            onClick={() => {}}
          >
            Reject
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RightRailButtonSection;
