import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { extractLatestAceOpportunityEventErrors } from './extractLatestAceOpportunityEventErrors';

/**
 * Fetches opportunity events for a given opportunity ID
 * @param opportunityId - the opportunity ID
 * @returns opportunityEvents - the opportunity events
 * @returns opportunityEventsError - the opportunity events error
 * @returns isOpportunityEventsLoading - whether the opportunity events are loading
 * @returns opportunityErrors - the errors from the latest opportunity event with errors
 */
const useOpportunityEventsQuery = (opportunityId: string) => {
  const {
    data: opportunityEvents,
    error: opportunityEventsError,
    isLoading: isOpportunityEventsLoading,
  } = useQuery({
    queryKey: [opportunityId, 'ace-opportunity-events'],
    queryFn: () => coSellClient.getOpportunityEvents(opportunityId),
    enabled: !!opportunityId,
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
  });

  /** provide enough data to describe error clearly and associate to event */
  const aceOpportunityErrors = useMemo(() => {
    const events = opportunityEvents?.events ?? [];
    if (!events.length) return [];

    return extractLatestAceOpportunityEventErrors({
      events,
    });
  }, [opportunityEvents]);

  return {
    opportunityEvents,
    opportunityEventsError,
    isOpportunityEventsLoading,
    aceOpportunityErrors,
  };
};

export default useOpportunityEventsQuery;
