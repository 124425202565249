import { Switch } from '@tackle-io/platform-ui';
import { makeStyles, Typography } from 'vendor/material';
import { cosellUIElements } from 'utils/pendoEnums';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '2rem',
  },
  switchLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL700,
    userSelect: 'none',
  },
}));

export const CoSellVersionToggle = ({
  enabled,
  onChange,
}: {
  enabled: boolean;
  onChange: () => void;
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      data-id={cosellUIElements.CO_SELL_PREVIEW_SWTICH}
    >
      <Switch
        data-testid={cosellUIElements.CO_SELL_PREVIEW_SWTICH}
        checked={enabled}
        onChange={onChange}
        label={
          <Typography className={classes.switchLabel} component="span">
            Preview the new co-sell experience
          </Typography>
        }
      />
    </div>
  );
};
