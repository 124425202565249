import { Box, Tab, Tabs } from 'vendor/material';
import useStyles from './ContractTabs.style';
import { useState } from 'react';
import DetailsTab from './components/DetailsTab/DetailsTab';
import PaymentScheduleTab from './components/PaymentScheduleTab/PaymentScheduleTab';
import PaymentsTab from './components/PaymentsTab/PaymentsTab';
import UsageTab from './components/UsageTab/UsageTab';
import { useSingleContractData } from 'pages/Contracts/pages/Details/providers/SingleContractDataProvider';
import { ContractsDataId } from 'utils/intercomEnums';
import { ContractPaymentsDataProvider } from '../../providers/ContractPaymentsDataProvider';
import { ContractUsageDataProvider } from '../../providers/ContractUsageDataProvider';

const ContractTabs: React.FC = () => {
  const classes = useStyles();
  const [contractDetailTabIndex, setContractDetailTabValue] = useState(0);
  const { contract } = useSingleContractData();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setContractDetailTabValue(newValue);
  };

  const displayPaymentScheduleTab =
    (contract?.paymentSchedule?.schedule || []).length > 0;
  const displayUsageTab = contract?.pricingModel
    ?.toLowerCase()
    .includes('usage');

  const tabs = [
    { label: 'Details', component: <DetailsTab contract={contract} /> },
    displayPaymentScheduleTab && {
      label: 'Payment schedule',
      component: <PaymentScheduleTab contract={contract} />,
    },
    {
      label: 'Payments',
      component: (
        <ContractPaymentsDataProvider>
          <PaymentsTab contract={contract} />
        </ContractPaymentsDataProvider>
      ),
    },
    displayUsageTab && {
      label: 'Usage',
      component: (
        <ContractUsageDataProvider>
          <UsageTab contract={contract} />
        </ContractUsageDataProvider>
      ),
    },
  ].filter(Boolean);

  return (
    <Box className={classes.tabsContainer}>
      <Box data-id={ContractsDataId.CONTRACT_DETAIL_TABS}>
        <Tabs
          value={contractDetailTabIndex}
          TabIndicatorProps={{ className: classes.tabIndicator }}
          onChange={handleChange}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} className={classes.tabLabel} />
          ))}
        </Tabs>
      </Box>

      {tabs.map(
        (tab, index) =>
          contractDetailTabIndex === index && (
            <div key={index}>{tab.component}</div>
          ),
      )}
    </Box>
  );
};

export default ContractTabs;
