import React, { useState } from 'react';
import { Box, Popover, Typography } from 'vendor/material';
import { get } from 'lodash-es';
import { Calendar, TextField } from '@tackle-io/platform-ui';
import { useFormikContext } from 'formik';
import { Calendar as CalendarIcon } from 'mdi-material-ui';
import { Label } from 'pages/CoSell/components/Label/Label';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import useStyles from './DateFormField.styles';

interface DateFormFieldProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  dataId?: string;
  helperText?: string;
}

export const DateFormField = ({
  name,
  label,
  required = false,
  disabled = false,
  dataId,
  helperText,
}: DateFormFieldProps): JSX.Element => {
  const classes = useStyles();

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setFieldValue, handleBlur, values, errors, touched } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const onCalendarChange = (...value: Array<any>): void => {
    const selectedDate = value[0];
    setFieldValue(name, selectedDate);
    setCalendarOpen(false);
    setAnchorEl(null);
  };

  const handleOpenCalendar = (event: MouseEvent): void => {
    setAnchorEl(event.currentTarget);
    setCalendarOpen(true);
  };

  const getValue = (): string => {
    const value = get(values, name, null);

    if (value) {
      const date = new Date(value);
      return date.toDateString().slice(4);
    }

    return '';
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setCalendarOpen(false);
  };

  return (
    <div className={classes.container} data-id={dataId}>
      <TextField
        id={name}
        name={name}
        label={<Label label={label} required={required} />}
        value={getValue()}
        onClick={handleOpenCalendar}
        onBlur={handleBlur}
        error={!calendarOpen && touched[name] && errors[name]}
        iconLeft={<CalendarIcon />}
        disabled={disabled}
      />
      <Popover
        onClose={handleClosePopover}
        id={`${name}Popover`}
        open={calendarOpen}
        anchorEl={anchorEl}
      >
        <Box p={4}>
          <Calendar onChange={onCalendarChange} />
        </Box>
      </Popover>

      {!!helperText && (
        <Typography className={classes.helperText}>{helperText}</Typography>
      )}
    </div>
  );
};
