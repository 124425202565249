import { PrivateOfferV2 } from 'stores/privateOffers/PrivateOfferV2';

const AWSOfferListViewItem = ({ awsOffer }: { awsOffer: PrivateOfferV2 }) => {
  return (
    <div>
      <div>AWSOfferListViewItem not yet implemented</div>
      <pre>{JSON.stringify(awsOffer, null, 2)}</pre>
    </div>
  );
};

export default AWSOfferListViewItem;
