import { Box, Grid, Skeleton, Typography } from 'vendor/material';
import useStyles from './PaymentsTab.style';
import { useEffect, useState } from 'react';
import { ampli } from 'utils/analytics/ampli';
import { format } from 'date-fns';
import { useContractPaymentsData } from 'pages/Contracts/pages/Details/providers/ContractPaymentsDataProvider';
import { Tag } from '@tackle-io/platform-ui';
import { formatCurrency } from 'pages/Contracts/helpers/currencyFormat';

interface PaymentsTabProps {
  contract: any;
}

const formatStatus = (status?: string): string => {
  switch (status) {
    case 'PAID':
      return 'Paid';
    case 'UNPAID':
      return 'Unpaid';
    case 'PENDING':
      return 'Pending';
    default:
      return '--';
  }
};

const PaymentsTab: React.FC<PaymentsTabProps> = ({ contract }) => {
  const classes = useStyles();
  const [eventSent, setEventSent] = useState(false);

  const { payments, loading: loadingPayments } = useContractPaymentsData();

  useEffect(() => {
    if (contract && !eventSent) {
      ampli.contractDetailTabViewed({
        marketplace: contract?.cloudMarketplace,
        contract_status: contract?.contractStatus,
        offer_origination: contract?.offerType,
        pricing_model: contract?.pricingModel,
        tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
        contract_detail_tab: 'Payments',
      });
      setEventSent(true);
    }
  }, [contract, eventSent]);

  if (!contract || loadingPayments) {
    return <Skeleton height={300} width="100%"></Skeleton>;
  }

  return (
    <Box className={classes.container}>
      <Grid container className={classes.header} alignItems="center">
        <Grid item xs={2}>
          <Typography className={classes.headerItem}>Invoice date</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem}>
            Payment due date
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem}>Date disbursed</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem}>Status</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem} align="right">
            Gross
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem} align="right">
            Net
          </Typography>
        </Grid>
      </Grid>

      {payments?.map((payment, index) => {
        return (
          <Grid
            container
            className={classes.row}
            alignItems="flex-start"
            key={index}
          >
            <Grid item xs={2}>
              <Typography className={classes.rowItem}>
                {payment.INVOICE_DATE
                  ? format(new Date(payment.INVOICE_DATE), 'MM/DD/YYYY')
                  : '--'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" flexDirection="column" gridGap={4}>
                <Typography className={classes.rowItem}>
                  {payment.PAYMENT_DUE_DATE
                    ? format(new Date(payment.PAYMENT_DUE_DATE), 'MM/DD/YYYY')
                    : '--'}
                </Typography>
                {payment.PAYMENT_TERMS && (
                  <span className={classes.rowItemFooter}>
                    {payment.PAYMENT_TERMS}d net term
                  </span>
                )}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.rowItem}>
                {payment.DISBURSEMENT_DATE
                  ? format(new Date(payment.DISBURSEMENT_DATE), 'MM/DD/YYYY')
                  : '--'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Tag className={classes.statusTag}>
                {formatStatus(payment.STATUS)}
              </Tag>
            </Grid>
            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gridGap={4}
                alignItems="flex-end"
              >
                <Typography className={classes.rowItem}>
                  {formatCurrency({
                    amount: payment.ORIGIN_CURRENCY_GROSS_AMOUNT,
                    currencyCode: payment.CURRENCY,
                  })}
                </Typography>
                <span className={classes.rowItemFooter}>
                  {formatCurrency({
                    amount: payment.ORIGIN_CURRENCY_MARKETPLACE_FEE,
                    currencyCode: payment.CURRENCY,
                  })}{' '}
                  fee
                </span>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gridGap={4}
                alignItems="flex-end"
              >
                <Typography className={classes.rowItem} align="right">
                  {formatCurrency({
                    amount: payment.ORIGIN_CURRENCY_NET_AMOUNT,
                    currencyCode: payment.CURRENCY,
                  })}
                </Typography>
                <span className={classes.rowItemFooter}>
                  {payment.CURRENCY}
                </span>
              </Box>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default PaymentsTab;
