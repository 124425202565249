import React from 'react';
import { Box, Grid, Typography } from 'vendor/material';
import { ProviderIcon } from '@tackle-io/platform-ui';
import useStyles from './InvitationDetailsHeader.styles';

import {
  DisplayCloudType,
  TagColorEnum,
  AceOpportunityStageEnum,
  AceInvitationCloudStatusEnum,
} from 'packages/cosell/src/types/enums';
import Tag from 'packages/cosell/src/components/Tag/Tag';
import { SOURCE_TAG } from 'packages/cosell/src/utilities/constants';
import { AwsInvitationStatusTag } from '../../../CoSellLandingPage/components/CoSellInvitationsTable/components';

interface InvitationDetailsHeaderProps {
  title?: string;
  status?: AceInvitationCloudStatusEnum;
}

const InvitationDetailsHeader: React.FC<InvitationDetailsHeaderProps> = ({
  title,
  status,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <Box
          className={classes.nameContainer}
          sx={{ display: 'flex' }}
          mb={1}
          mt={2}
        >
          <Box sx={{ display: 'flex' }} mr={1}>
            <ProviderIcon fontSize={'large'} provider={DisplayCloudType.AWS} />
          </Box>

          {title && (
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
            </Box>
          )}
        </Box>
        <Grid container spacing={1}>
          <Grid item>
            <Tag color={TagColorEnum.GREEN}>v14</Tag>
          </Grid>
          <Grid item>
            <Tag>{SOURCE_TAG.INBOUND}</Tag>
          </Grid>
          <Grid item>
            {status && <AwsInvitationStatusTag status={status} />}
          </Grid>
          <Grid item>
            <Tag color={TagColorEnum.STANDARD}>
              {AceOpportunityStageEnum.PROSPECT}
            </Tag>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InvitationDetailsHeader;
