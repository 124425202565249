import { ContractMonetaryValue } from 'generated/graphql';
import { CurrencyCode, formatValueWithCurrencyPrefix } from 'utils/currency';

const getCurrencyCode = (currencyCode?: string): CurrencyCode => {
  switch (currencyCode) {
    case 'USD':
      return CurrencyCode.UnitedStatesDollar;
    case 'EUR':
      return CurrencyCode.Euro;
    case 'AUD':
      return CurrencyCode.AustralianDollar;
    case 'GBP':
      return CurrencyCode.BritishPound;
    case 'JPY':
      return CurrencyCode.JapaneseYen;
    default:
      return currencyCode as CurrencyCode;
  }
};

export const formatCurrency = (
  monetaryValue?:
    | ContractMonetaryValue
    | null
    | { amount?: string; currencyCode: string },
): string => {
  if (
    !monetaryValue ||
    monetaryValue.amount == null ||
    monetaryValue.amount === undefined
  ) {
    return '--';
  }
  const currencyCode = getCurrencyCode(monetaryValue.currencyCode);
  return formatValueWithCurrencyPrefix(monetaryValue.amount, currencyCode);
};
