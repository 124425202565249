import {
  // Banner,
  Loader,
} from '@tackle-io/platform-ui';
import { Box } from 'vendor/material';
import { toDateTime } from 'utils/dates';
import { AwsCoSellSyncStatus } from 'packages/cosell/src/components/AwsCoSellSyncStatus/AwsCoSellSyncStatus';
import CollapsibleListItem from 'packages/cosell/src/components/CollapsibleListItem/CollapsbleListitem';
import { useTackleOpportunitiesForCrmId } from 'packages/salesforce-canvas/cosell/src/hooks/useTackleOpportunitiesForCrmId';
import AwsOpportunitySummary from './AwsOpportunitySummary';
import useOpportunityListStyles from './AwsCoSells.styles';
import CoSellCalltoAction from '../CoSellCallToAction';

/** not sure if this is going to be removed from the design */
// const PendingBanner = ({ message }: { message: string }) => {
//   return <Banner title={message} type="info" />;
// };

/** View for Salesforce Tackle widget AWS co-sells */
export default function AwsCoSells({ crmId }: { crmId: string }) {
  const { data, isLoading: isLoadingOpportunities } =
    useTackleOpportunitiesForCrmId({ crmId });

  const classes = useOpportunityListStyles();

  if (isLoadingOpportunities) return <Loader />;

  if (!data?.opportunities?.length) {
    return <CoSellCalltoAction />;
  }
  const { opportunities } = data;

  return (
    <div className={classes.content}>
      {/* {opportunities.length > 1 && (
        <PendingBanner
          message={`${opportunities.length} Co-sells in progress`}
        />
      )} */}
      <AwsCoSellSyncStatus
        // WIP: placeholder date - need to replace with actual last sync date
        lastSyncDate={'2024-12-10T17:51:00+00:00'}
        opportunityId={crmId}
      />
      <Box className={classes.listItem}>
        {opportunities.map((opportunity, i) => {
          const createdDate = opportunity.created
            ? toDateTime(opportunity.created).toFormat('MM/dd/yy')
            : '-';
          return (
            <CollapsibleListItem
              key={opportunity.id}
              title={`Created ${createdDate}`}
              defaultOpen={i === 0}
              isCollapsible={!(i === 0 && opportunities.length === 1)}
            >
              <AwsOpportunitySummary opportunityId={opportunity.id} />
            </CollapsibleListItem>
          );
        })}
      </Box>
    </div>
  );
}
