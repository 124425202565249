import { Banner, FormSection, Link, Spacer } from '@tackle-io/platform-ui';
import { TextButton } from 'components';
import { isEmpty } from 'lodash/fp';
import { DateTime } from 'luxon';
import FieldsPricingConfig from 'pages/PrivateOffers/components/FieldsPricingConfig/FieldsPricingConfig';
import React, { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  BillingTermValue,
  MetadataPricing,
  OfferType,
  PaymentModelValue,
  PrivateOffer,
} from 'stores/privateOffers/typings';
import { useSelectedProduct } from 'stores/products/hooks';
import { Product } from 'stores/products/typings';
import {
  AzurePricingEnum,
  AzureProduct,
} from 'stores/products/typings/AzureProduct';
import { Cloud } from 'utils/cloudTypes';
import { ListingType } from 'utils/listingTypes';
import { Box, Grid, Typography } from 'vendor/material';
import {
  AwsDimensionField,
  AzureFlatRateDimensionField,
  AzurePerUnitDimensionField,
  GcpDimensionField,
} from './DimensionFields';
import BaseDimensionField from './DimensionFields/BaseDimensionField';
import DurationField from './DurationField';
import FieldsAwsAmiHourlyAnnualPricing from './FieldsAwsAmiHourlyAnnualPricing/FieldsAwsAmiHourlyAnnualPricing';
import useStyles from './FieldsPricing.styles';
import FieldsRedHatPricing from './FieldsRedHatPricing';
import MarketplaceFeeField from './MarketplaceFeeField';
import AwsScheduleField from './ScheduleFields/AwsScheduleField';
import GcpScheduleField from './ScheduleFields/GcpScheduleField';
import { AwsUsageDimensionField } from './UsageDimensionFields';
import { pendoIdGenerator } from '../../utils/idGenerator';
import { privateOffersUIElements } from '../../utils/pendoEnums';
import GcpPlanField from './DimensionFields/GcpPlanField';
import { Pricing } from '../../utils/pricingTypes';
import FieldsCustomerBillingAccountNumber from '../../pages/PrivateOffers/components/FieldsCustomerBillingAccountNumber/FieldsCustomerBillingAccountNumber';
import { convertISODateStringToLocalJSDate } from 'pages/PrivateOffers/utils/formatFormData';
import FieldsPreviousServiceEndDate from './FieldsPreviousServiceEndDate';
import FieldsAmendmentServiceStartDate from './FieldsAmendmentServiceStartDate';
import FieldsAmendmentServiceEndDate from './FieldsAmendmentServiceEndDate';
import classnames from 'classnames';
import {
  FormMetadataUsageDimension,
  paymentModelFormPath,
  usageDimensionsFormPath,
} from './utils';
import AwsDimensionsUsedCount from './AwsDimensionsUsedCount';
import AwsMarketplacePricingLoading from './AwsMarketplacePricingLoading';
import PricingSchedule from './PricingSchedule';
import FieldCurrencyCode from './FieldCurrencyCode';
import { EditMode } from '../../pages/PrivateOffers/utils/editMode';
import { CurrencyCode } from '../../utils/currency';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import {
  isFeatureFlagEnabled,
  PrivateOfferFeatureFlags,
} from '../../pages/PrivateOffers/utils/featureFlags';
import GcpUsageDimensions from './UsageDimensionFields/gcp/GcpUsageDimensions';

export interface FieldsPricingProps {
  cloud?: Product['cloud'];
  productId?: Product['productid'];
  privateOfferId?: string;
  listingType?: string;
  offer?: PrivateOffer;
  showFDAPricing?: boolean;
  offerType?: OfferType;
  previousServiceEndAt?: DateTime;
  awsMarketplacePricing?: MetadataPricing;
  isLoadingAwsMarketplacePricing?: boolean;
  onLoadAwsMarketplacePricing?: () => void;
  isEnabledForAWSMultiCurrency?: boolean;
  marketplaceCurrencies?: CurrencyCode[];
  onGcpPlanChanged?: () => void;
}

const FieldsPricing: React.FC<FieldsPricingProps & EditMode> = ({
  cloud = null,
  productId = null,
  privateOfferId = '',
  listingType = '',
  offer = null,
  offerType = OfferType.Direct,
  showFDAPricing = false,
  previousServiceEndAt,
  awsMarketplacePricing,
  isLoadingAwsMarketplacePricing,
  onLoadAwsMarketplacePricing,
  editMode,
  marketplaceCurrencies = [],
  onGcpPlanChanged,
}) => {
  if (!cloud) {
    return null;
  }

  const props = {
    cloud,
    listingType,
    productId,
    privateOfferId,
    offer,
    offerType,
    showFDAPricing,
    previousServiceEndAt,
    awsMarketplacePricing,
    isLoadingAwsMarketplacePricing,
    onLoadAwsMarketplacePricing,
    editMode,
    marketplaceCurrencies,
    onGcpPlanChanged,
  };

  switch (cloud) {
    case Cloud.Aws:
      if (listingType === ListingType.AmiHourlyAnnual)
        return <FieldsAwsAmiHourlyAnnualPricing {...props} />;

      return <FieldsMetadataPricing {...props} />;
    case Cloud.Redhat:
      return <FieldsRedHatPricing {...props} cloud={Cloud.Redhat} />;
    default:
      return <FieldsMetadataPricing {...props} />;
  }
};

const FieldsMetadataPricing: React.FC<FieldsPricingProps & EditMode> = ({
  cloud = null,
  productId = null,
  privateOfferId = '',
  offer = null,
  offerType = OfferType.Direct,
  showFDAPricing = false,
  previousServiceEndAt,
  awsMarketplacePricing,
  isLoadingAwsMarketplacePricing,
  onLoadAwsMarketplacePricing,
  editMode,
  marketplaceCurrencies,
  onGcpPlanChanged,
}) => {
  const classes = useStyles();
  const { content: product } = useSelectedProduct(productId, cloud);
  const { control } = useFormContext();
  const { flags } = useAmpliFeatureFlags();

  const paymentModel = useWatch({
    control,
    name: paymentModelFormPath,
  });

  const version = useWatch({ name: 'pricing.version', control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pricing.dimensions',
  });

  const { fields: usageDimensionsFields } = useFieldArray({
    control,
    name: usageDimensionsFormPath,
  });

  const handleAddition = useCallback(
    (): void => append({ name: '', price: '0', quantity: '0' }),
    [append],
  );
  const handleRemoval = useCallback((index): void => remove(index), [remove]);

  const selectedBillingTerm = useWatch({
    control,
    name: 'pricing.billingTerm',
  });
  const formAboLinkedOfferRef = useWatch({
    control,
    name: 'aboLinkedOfferRef',
  });

  if (!cloud) return null;

  let UsageDimensionField = null;
  let DimensionField = null;
  let ScheduleField = null;

  switch (cloud) {
    case Cloud.Aws:
      UsageDimensionField = AwsUsageDimensionField;
      DimensionField = AwsDimensionField;
      ScheduleField = AwsScheduleField;
      break;
    case Cloud.Gcp:
      DimensionField =
        version === Pricing.GcpMarketplacePricingV1
          ? GcpPlanField
          : GcpDimensionField;
      ScheduleField = GcpScheduleField;
      break;
    case Cloud.Azure:
      DimensionField =
        (product as AzureProduct)?.pricing?.pricingType ===
        AzurePricingEnum.FlatRate
          ? AzureFlatRateDimensionField
          : AzurePerUnitDimensionField;
      break;
    default:
      DimensionField = BaseDimensionField;
      break;
  }

  const isAmendmentOffer =
    (offer && !isEmpty(offer.aboLinkedOfferRef)) ||
    (!offer && !isEmpty(formAboLinkedOfferRef));
  const isFDAOffer = selectedBillingTerm === BillingTermValue.FutureDated;
  const showPaymentScheduleTool =
    [Cloud.Aws, Cloud.Gcp].includes(cloud as Cloud) &&
    paymentModel === PaymentModelValue.PaymentSchedule;

  const showPricingConfig =
    // aws ami listings are using a custom pricing component
    cloud === Cloud.Aws ||
    (cloud === Cloud.Gcp && version === Pricing.GcpMarketplacePricingV1);

  const defaultServiceStartAt = convertISODateStringToLocalJSDate(
    offer?.metadata?.pricing?.serviceStartAt,
  );
  const defaultPreviousServiceEndAt = convertISODateStringToLocalJSDate(
    previousServiceEndAt?.toISO(),
  );
  const defaultServiceEndAt =
    convertISODateStringToLocalJSDate(offer?.metadata?.pricing?.serviceEndAt) ??
    defaultPreviousServiceEndAt ??
    DateTime.now().toUTC().toJSDate();

  const dimensionFields =
    DimensionField &&
    fields.map((field, index) => (
      <Box mb={2} key={field.id}>
        <DimensionField
          field={field}
          index={index}
          productId={productId}
          privateOfferId={privateOfferId}
          onRemove={handleRemoval}
          paymentModel={paymentModel}
          cloud={cloud}
          awsMarketplacePricing={awsMarketplacePricing}
          onGcpPlanChanged={onGcpPlanChanged}
        />
      </Box>
    ));

  const gcpFlatFeeIsEnabled = isFeatureFlagEnabled(
    flags,
    PrivateOfferFeatureFlags.GCP_FLAT_FEE_USAGE_ENABLED,
  );
  const isGcpProduct = cloud === Cloud.Gcp;

  return (
    <>
      {showPricingConfig && (
        <>
          <FieldsPricingConfig
            cloud={cloud as Cloud}
            offer={offer}
            selectedProductId={productId}
            offerType={offerType}
            showFDAPricing={showFDAPricing}
            isAmendmentOffer={isAmendmentOffer}
          />
          <Spacer small />
        </>
      )}
      {cloud === Cloud.Aws ? (
        <>
          <Box mb={3}>
            <FormSection
              title={
                <>
                  <Typography variant="subtitle1">Currency</Typography>
                  <Typography variant="caption">
                    Select the currency negotiated with the buyer. You will
                    receive disbursements in the selected currency.
                  </Typography>
                </>
              }
              mb={1.5}
            >
              <>
                <Box mb={2}>
                  <Banner
                    type="info"
                    borderPosition="top"
                    isCollapsible
                    defaultOpen={false}
                    title={
                      <>
                        AWS supports foreign currency only for contract-based
                        pricing models.
                      </>
                    }
                    body={
                      <>
                        To enable,{' '}
                        <Link
                          to="https://docs.tackle.io/articles/#!tackle-docs/aws-private-offers-in-foreign-currency"
                          external
                        >
                          set up bank disbursement preferences in the AWS
                          marketplace.
                        </Link>
                      </>
                    }
                  />
                </Box>
                <Grid container>
                  <Grid item md={6}>
                    <FieldCurrencyCode
                      marketplaceCurrencies={marketplaceCurrencies}
                      hasUsageDimensions={usageDimensionsFields.length > 0}
                      editMode={editMode}
                    />
                  </Grid>
                </Grid>
              </>
            </FormSection>
          </Box>
          <FormSection title="Dimensions" mb={1.5}>
            {dimensionFields}
            <>
              {(isLoadingAwsMarketplacePricing || !awsMarketplacePricing) && (
                <AwsMarketplacePricingLoading
                  isLoadingPricing={isLoadingAwsMarketplacePricing}
                  onLoadPricing={onLoadAwsMarketplacePricing}
                />
              )}
              {awsMarketplacePricing && (
                <AwsDimensionsUsedCount
                  awsMarketplacePricing={awsMarketplacePricing}
                />
              )}
            </>
            <TextButton
              appearance="subtle"
              color="primary"
              onClick={handleAddition}
              classes={{
                root: classes.buttonPrimary,
                textPrimary: classes.buttonTextPrimary,
              }}
              aria-label="Add dimension"
              data-id={pendoIdGenerator(
                privateOffersUIElements.PRIVATE_OFFERS_ADD_DIMENSION_BUTTON,
              )}
            >
              Add dimension
            </TextButton>
          </FormSection>
        </>
      ) : (
        dimensionFields
      )}
      {isGcpProduct && gcpFlatFeeIsEnabled && usageDimensionsFields.length > 0 && (
        <FormSection
          title="Usage dimensions"
          mb={1.5}
          data-id={pendoIdGenerator(
            privateOffersUIElements.PRIVATE_OFFERS_GCP_USAGE_DIMENSIONS_HEADER,
          )}
        >
          <GcpUsageDimensions
            usageDimensions={
              usageDimensionsFields as FormMetadataUsageDimension[]
            }
          />
        </FormSection>
      )}
      {cloud === Cloud.Aws && !isEmpty(usageDimensionsFields) && (
        <FormSection title="Usage dimensions" mb={1.5}>
          {UsageDimensionField &&
            usageDimensionsFields.map((field, i) => (
              <Box mb={2} key={field.id}>
                <UsageDimensionField field={field} index={i} />
              </Box>
            ))}
        </FormSection>
      )}
      <FormSection title="Contract details" mb={1.5}>
        {version === Pricing.GcpMarketplacePricingV1 && (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FieldsCustomerBillingAccountNumber offerType={offerType} />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={2}
          className={classnames({
            [classes.bottomMargin12px]: showPricingConfig,
          })}
        >
          <Grid item md={6} sm={11} xs={10}>
            <MarketplaceFeeField cloud={cloud} />
          </Grid>
          {!showPricingConfig && (
            <Grid item md={6} sm={11} xs={10}>
              <DurationField cloud={cloud} />
            </Grid>
          )}
        </Grid>
      </FormSection>
      {isAmendmentOffer && offerType === OfferType.Direct && (
        <FormSection title="Service dates" mb={1.5}>
          <Grid
            container
            spacing={2}
            className={classnames({
              [classes.bottomMargin12px]: showPricingConfig,
            })}
          >
            <Grid item md={isFDAOffer ? 4 : 6} sm={12} xs={12}>
              <FieldsPreviousServiceEndDate
                defaultValue={defaultPreviousServiceEndAt}
              />
            </Grid>
            {isFDAOffer && (
              <Grid item md={4} sm={12} xs={12}>
                <FieldsAmendmentServiceStartDate
                  defaultValue={defaultServiceStartAt}
                />
              </Grid>
            )}
            <Grid item md={isFDAOffer ? 4 : 6} sm={12} xs={12}>
              <FieldsAmendmentServiceEndDate
                defaultValue={defaultServiceEndAt}
              />
            </Grid>
          </Grid>
        </FormSection>
      )}
      {paymentModel === PaymentModelValue.PaymentSchedule && (
        <PricingSchedule
          ScheduleField={ScheduleField}
          cloud={cloud}
          isAmendmentOffer={isAmendmentOffer}
          showPaymentScheduleTool={showPaymentScheduleTool}
        />
      )}
    </>
  );
};

export default FieldsPricing;
