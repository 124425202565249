import type {
  AceCloudResponseErrorType,
  AceOpportunityEventResponse,
  FailedCloudResponse,
  AceOpportunityEventOperation,
} from 'packages/cosell/src/types/responses/AceOpportunityEventResponse';

type EventError = {
  errorType: AceCloudResponseErrorType;
  eventID: string;
  operation: AceOpportunityEventOperation;
  error:
    | NonNullable<FailedCloudResponse['errorResponses']>[number]
    | { errorMessage: NonNullable<FailedCloudResponse['errorResponse']> };
  timestamp: string;
};

/**
 * Returns a list of errors for the latest Ace opportunity event which has errors.
 * Note: The api only provides us with this events list, so if an opportunity has errors, we'll to look for them.
 */
export const extractLatestAceOpportunityEventErrors = ({
  events,
}: {
  events: AceOpportunityEventResponse[];
}) => {
  const eventErrors = new Set<EventError>();

  /** check for the first event with failed cloudResponses
   *  store the event and failed resposes
   */
  for (let event of events) {
    const failedResponses =
      event.cloudResponses?.filter((x) => x.success === false) ?? [];

    if (failedResponses.length > 0) {
      /** iterate over failed responses to create a list of errors */
      failedResponses.forEach((res) => {
        if (res.success === true) return;

        /** handle array of error responses */
        res.errorResponses?.forEach((eventError) => {
          eventErrors.add({
            errorType: res.errorType,
            eventID: event.eventID,
            operation: event.operation,
            error: eventError,
            timestamp: event.timestamp,
          });
        });

        /** handle single errorResponse */
        if (!!res.errorResponse) {
          eventErrors.add({
            errorType: res.errorType,
            eventID: event.eventID,
            operation: event.operation,
            error: { errorMessage: res.errorResponse },
            timestamp: event.timestamp,
          });
        }
      });
      // exit loop once we've found the first event with errors
      break;
    }
  }

  return Array.from(eventErrors);
};
