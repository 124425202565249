import { ComponentType, lazy } from 'react';
import { reportError } from 'utils/monitor';

const pageLoader = (
  loadComponent: () => Promise<{ default: ComponentType<any> }>,
) => {
  return (): Promise<{ default: ComponentType<any> }> =>
    new Promise((resolve, reject) => {
      loadComponent()
        .then((module) => resolve(module))
        .catch((error) => {
          reportError(error);
          window.location.reload();
        });
    });
};

export const Stub = ({ location }) => (
  <h1>
    Route:
    {location.pathname}
  </h1>
);

export const Insights = lazy(pageLoader(() => import('./Insights')));
export const Overview = lazy(pageLoader(() => import('./Overview/Overview')));
export const CoSellDashboard = lazy(
  pageLoader(() => import('./CoSell/pages/Dashboard')),
);
export const CoSellOpportunity = lazy(
  pageLoader(() => import('./CoSell/pages/OpportunityDetail')),
);
export const CoSellOpportunityForm = lazy(
  pageLoader(() => import('./CoSell/pages/OpportunityForm')),
);

export const MeteringNewRecord = lazy(
  pageLoader(() => import('./Metering/pages/New/New')),
);
export const MeteringUpload = lazy(
  pageLoader(
    () => import('./Metering/components/MeteringUpload/MeteringUpload'),
  ),
);
export const Metering = lazy(
  pageLoader(() => import('./Metering/pages/List/List')),
);
export const PrivateOffers = lazy(
  pageLoader(() => import('./PrivateOffers/pages/List/List')),
);
export const PrivateOffersNew = lazy(
  pageLoader(() => import('./PrivateOffers/pages/New/New')),
);
export const NewAwsPrivateOffer = lazy(
  pageLoader(
    () => import('./PrivateOffers/pages/Next/aws/edit/NewAwsPrivateOfferPage'),
  ),
);
export const ViewAwsPrivateOffer = lazy(
  pageLoader(
    () => import('./PrivateOffers/pages/Next/aws/view/ViewAwsPrivateOfferPage'),
  ),
);
export const EditAwsPrivateOffer = lazy(
  pageLoader(
    () => import('./PrivateOffers/pages/Next/aws/edit/EditAwsPrivateOfferPage'),
  ),
);
export const CloneAwsPrivateOffer = lazy(
  pageLoader(
    () =>
      import('./PrivateOffers/pages/Next/aws/edit/CloneAwsPrivateOfferPage'),
  ),
);
export const AmendAwsPrivateOffer = lazy(
  pageLoader(
    () =>
      import('./PrivateOffers/pages/Next/aws/edit/AmendAwsPrivateOfferPage'),
  ),
);
export const PrivateOffersExisting = lazy(
  pageLoader(
    () => import('./PrivateOffers/pages/SinglePrivateOffer/SinglePrivateOffer'),
  ),
);
export const Settings = lazy(pageLoader(() => import('./Settings')));
export const Contract = lazy(pageLoader(() => import('./Contract')));
export const IntegrationTools = lazy(
  pageLoader(
    () => import('./Integrations/pages/IntegrationTools/IntegrationTools'),
  ),
);
export const IntegrationConfig = lazy(
  pageLoader(
    () => import('./Integrations/pages/IntegrationConfig/IntegrationConfig'),
  ),
);
export const EventStream = lazy(
  pageLoader(() => import('./EventStream/EventStream')),
);
export const Reporting = lazy(pageLoader(() => import('./Reporting')));
export const ProspectScoring = lazy(
  pageLoader(() => import('./ProspectScoring/pages/ProspectDashboard')),
);
export const Listings = lazy(pageLoader(() => import('./Listings')));
export const SignUp = lazy(pageLoader(() => import('./SignUp/SignUp')));
export const Payments = lazy(pageLoader(() => import('./Payments/Payments')));
export const PaymentsLegacyReports = lazy(
  pageLoader(() => import('./Payments/LegacyReports')),
);
export const CloudContracts = lazy(
  pageLoader(() => import('./Contracts/Contracts')),
);
export const ContractDetails = lazy(
  pageLoader(() => import('./Contracts/pages/Details/ContractDetails')),
);
