import { EditModalType } from '../src/components';
import { AceOpportunityReviewStatusEnum } from '../src/types/enums';

export enum TackleOperationId {
  CREATE_DRAFT_OPPORTUNITY = 'createDraftOpportunity',
  CREATE_OPPORTUNITY = 'createOpportunity',
  UPDATE_OPPORTUNITY = 'updateOpportunity',
  /** Begin engagement on the current draft opportunity with AWS. */
  START_ENGAGEMENT = 'startEngagement',
  /** Update the existing draft opportunity and initiate engagement. */
  UPDATE_START_ENGAGEMENT = 'updateStartEngagementOpportunity',
  CLOSE_LOST_OPPORTUNITY = 'closeLostOpportunity',
  LAUNCH_OPPORTUNITY = 'launchOpportunity',
  /** Accepting or rejecting opportunities is for the mock service
   * to simulate AWS's approval or rejection process. */
  ACCEPT_OPPORTUNITY = 'acceptOpportunity',
  REJECT_OPPORTUNITY = 'rejectOpportunity',
}

export const getEditModalTackleOperationId = (
  editModalType: EditModalType,
): TackleOperationId => {
  switch (editModalType) {
    case EditModalType.EDIT_LAUNCHED:
      return TackleOperationId.UPDATE_OPPORTUNITY;
    case EditModalType.LAUNCH:
      return TackleOperationId.LAUNCH_OPPORTUNITY;
    case EditModalType.CLOSE_LOST:
      return TackleOperationId.CLOSE_LOST_OPPORTUNITY;
  }
};

export const getEditPageSubmitTackleOperationId = (
  currentStatus: AceOpportunityReviewStatusEnum,
): TackleOperationId => {
  // the START_ENGAGEMENT operation will only submit the existing draft opportunity to ACE,
  // if we want the user to be able to submit the draft with updates, the operation should be updateStartEngagementOpportunity
  if (currentStatus === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION) {
    return TackleOperationId.UPDATE_START_ENGAGEMENT;
  }
  return TackleOperationId.UPDATE_OPPORTUNITY;
};

export const getOpportunitiesListUrl = ({
  q,
  pageSize,
  from,
}: {
  q?: string;
  pageSize?: string;
  from?: string;
}) => {
  // Default sort by cloudCreated date in descending order
  let url = `api/opportunities?sortField=cloudCreated&sortOrder=desc`;
  const hasQueryParams = true;

  if (q) {
    url += `?q=${q}`;
  }
  if (pageSize) {
    url += `${hasQueryParams ? '&' : '?'}pageSize=${pageSize}`;
  }
  if (from) {
    url += `${hasQueryParams ? '&' : '?'}from=${from}`;
  }

  return url;
};

export const getInvitationsListUrl = () => {
  // Default sort by cloudCreated date in descending order
  return `api/invitations?sortField=cloudCreated&sortOrder=desc`;
};

export const getRadioButtonDataId = ({
  dataId,
  fieldValue,
}: {
  dataId: string;
  fieldValue: string;
}) => `${dataId}_${fieldValue}`;
