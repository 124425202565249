// mspc related enum
export enum MsftNeedFromCloudEnumValues {
  GENERAL_OR_OTHER = 'GeneralOrOther',
  WORKLOAD = 'WorkloadSpecificValueProposition',
  CUSTOMER_TECHNICAL_ARCHITECTURE = 'CustomerTechnicalArchitecture',
  PROOF_OF_CONCEPT = 'ProofOfConceptOrDemo',
  QUOTES = 'QuotesOrLicensing',
  POST_SALES_CUSTOMER_SUCCESS = 'PostSalesCustomerSuccess',
  NO_HELP_REQUIRED = 'Unknown',
}

export enum MsftNeedFromCloudEnumLabels {
  GENERAL_OR_OTHER = 'General or other',
  WORKLOAD = 'Workload - specific value proposition',
  CUSTOMER_TECHNICAL_ARCHITECTURE = 'Customer technical architecture',
  PROOF_OF_CONCEPT = 'Proof of concept / Demo',
  QUOTES = 'Quotes / Licensing',
  POST_SALES_CUSTOMER_SUCCESS = 'Post - sales customer success',
  NO_HELP_REQUIRED = 'No help required at this time',
}

export const MsftNeedFromCloudValuesToLabels = {
  [MsftNeedFromCloudEnumValues.GENERAL_OR_OTHER]:
    MsftNeedFromCloudEnumLabels.GENERAL_OR_OTHER,
  [MsftNeedFromCloudEnumValues.WORKLOAD]: MsftNeedFromCloudEnumLabels.WORKLOAD,
  [MsftNeedFromCloudEnumValues.CUSTOMER_TECHNICAL_ARCHITECTURE]:
    MsftNeedFromCloudEnumLabels.CUSTOMER_TECHNICAL_ARCHITECTURE,
  [MsftNeedFromCloudEnumValues.PROOF_OF_CONCEPT]:
    MsftNeedFromCloudEnumLabels.PROOF_OF_CONCEPT,
  [MsftNeedFromCloudEnumValues.QUOTES]: MsftNeedFromCloudEnumLabels.QUOTES,
  [MsftNeedFromCloudEnumValues.POST_SALES_CUSTOMER_SUCCESS]:
    MsftNeedFromCloudEnumLabels.POST_SALES_CUSTOMER_SUCCESS,
  [MsftNeedFromCloudEnumValues.NO_HELP_REQUIRED]:
    MsftNeedFromCloudEnumLabels.NO_HELP_REQUIRED,
};
