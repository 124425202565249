import { Box, Grid, Skeleton, Typography } from 'vendor/material';
import useStyles from './UsageTab.style';
import { useEffect, useState } from 'react';
import { ampli } from 'utils/analytics/ampli';
import { format } from 'date-fns';
import { useContractUsageData } from 'pages/Contracts/pages/Details/providers/ContractUsageDataProvider';
import { UsageStatusTag } from 'pages/Contracts/components/StatusTag/UsageStatusTag';
import { ContractsQuery } from 'generated/graphql';

type ContractType = ContractsQuery['contracts']['hits']['hits'][0]['_source'];

interface UsageTabProps {
  contract: ContractType;
}

const UsageTab: React.FC<UsageTabProps> = ({ contract }) => {
  const classes = useStyles();
  const [eventSent, setEventSent] = useState(false);

  const { usage, loading: loadingUsage } = useContractUsageData();

  useEffect(() => {
    if (contract && !eventSent) {
      ampli.contractDetailTabViewed({
        marketplace: contract?.cloudMarketplace as 'aws' | 'azure' | 'gcp',
        contract_status: contract?.contractStatus,
        offer_origination: contract?.offerType,
        pricing_model: contract?.pricingModel,
        tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
        contract_detail_tab: 'Usage',
      });
      setEventSent(true);
    }
  }, [contract, eventSent]);

  if (!contract || loadingUsage) {
    return <Skeleton height={300} width="100%"></Skeleton>;
  }

  return (
    <Box className={classes.container}>
      <Grid container className={classes.header} alignItems="center">
        <Grid item xs={2}>
          <Typography className={classes.headerItem}>Date</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem}>Status</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.headerItem}>SKU</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem} align="right">
            QTY
          </Typography>
        </Grid>
      </Grid>

      {usage?.map((item, index) => {
        return (
          <Grid
            container
            className={classes.row}
            alignItems="center"
            key={index}
          >
            <Grid item xs={2}>
              <Typography className={classes.rowItem}>
                {item.created_at
                  ? format(new Date(item.created_at), 'MM/DD/YYYY')
                  : '--'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <UsageStatusTag label={item.status || '--'} />
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.rowItem}>
                {item.dimension?.name || '--'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.rowItem} align="right">
                {item.dimension?.quantity || '--'}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default UsageTab;
