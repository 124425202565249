import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTackleSessionFromOtk } from './lib/salesforceCanvasSession';
import Sfdc from './lib/salesforceCanvasSdk';

const ONE_TIME_KEY_PARAM = 'otk';

/** WIP: Manages Salesforce Canvas session data and sets access_token in window.Sfdc */
export function useCanvasSession() {
  const urlParams = new URLSearchParams(window.location.search);
  const otk = urlParams.get(ONE_TIME_KEY_PARAM);

  const isAuthorized = !!otk;

  const {
    data: canvasSession,
    isLoading: queryLoading,
    isError,
  } = useQuery({
    queryKey: [otk],
    queryFn: () => getTackleSessionFromOtk(otk),
    enabled: isAuthorized,
    staleTime: Infinity,
    retry: false,
  });

  // If user does not have OTK, they are not authorized session, so there is no loading state
  const isLoading = isAuthorized ? queryLoading : false;

  const accessToken = canvasSession?.access_token;

  useEffect(() => {
    if (accessToken && Sfdc) {
      Sfdc.canvas?.oauth?.token(accessToken);
    }
  }, [accessToken]);

  const parameters = canvasSession?.context?.parameters;

  return {
    isAuthorized,
    canvasSession,
    isError,
    isLoadingCanvasSession: isLoading,
    namespace: parameters?.namespace,
    /** Salesforce object record: if not provided from SF, try to get it from payload */
    record: parameters?.record ?? parameters?.payload?.record,
    vendorId: parameters?.vendorId,
    canvasClient: canvasSession?.context?.client,
    /** context passed into canvas from other canvas component event */
    payload: parameters?.payload,
  };
}
