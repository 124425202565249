import { Box, Grid, makeStyles, Tabs, Tab } from 'vendor/material';
import { PrivateOfferV2 } from 'stores/privateOffers/PrivateOfferV2';
import { useState } from 'react';
import StartAWSOffer from './StartAWSOffer';
import AWSOffersForOpportunity from './AWSOffersForOpportunity';
import AzureOffersForOpportunity from './AzureOffersForOpportunity';
import GCPOffersForOpportunity from './GCPOffersForOpportunity';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  contents: {
    overflow: 'auto',
  },
}));

const ListOfOffersForOpportunity = ({
  opportunityId,
  offers,
}: {
  opportunityId: string;
  offers: PrivateOfferV2[];
}) => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState<
    PrivateOfferV2['marketplace'] | 'json'
  >('aws');

  const [startingNewAWSOffer, setStartingNewAWSOffer] = useState(false);
  if (startingNewAWSOffer) {
    return <StartAWSOffer onCancel={() => setStartingNewAWSOffer(false)} />;
  }

  const offersByMarketplace = offers.reduce<
    Record<PrivateOfferV2['marketplace'], PrivateOfferV2[]>
  >(
    (acc, offer) => {
      acc[offer.marketplace].push(offer);
      return acc;
    },
    { aws: [], azure: [], gcp: [] },
  );

  return (
    <Box px={2} height="100%">
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Tabs
            value={selectedTab}
            onChange={(e, val) => setSelectedTab(val)}
            centered
            indicatorColor="primary"
          >
            <Tab
              value="aws"
              label={`AWS (${offersByMarketplace.aws.length})`}
            />
            <Tab
              value="azure"
              label={`Microsoft (${offersByMarketplace.azure.length})`}
            />
            <Tab
              value="gcp"
              label={`Google (${offersByMarketplace.gcp.length})`}
            />
            <Tab value="json" label={`json (${offers.length})`} />
          </Tabs>
        </Grid>
        <Grid item xs className={classes.contents}>
          {selectedTab === 'aws' && (
            <AWSOffersForOpportunity awsOffers={offersByMarketplace.aws} />
          )}
          {selectedTab === 'azure' && (
            <AzureOffersForOpportunity
              azureOffers={offersByMarketplace.azure}
            />
          )}
          {selectedTab === 'gcp' && (
            <GCPOffersForOpportunity gcpOffers={offersByMarketplace.gcp} />
          )}
          {selectedTab === 'json' && (
            <pre>{JSON.stringify(offers, null, 2)}</pre>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListOfOffersForOpportunity;
