import { Loader } from '@tackle-io/platform-ui';
import { useCanvasSession } from './useCanvasSession';
import { useCanvasAppStyles } from './CanvasApp.styles';
import { AwsOpportunityActionsProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import CanvasTackleIntegrationStatus from './components/CanvasTackleIntegrationStatus';
import TackleSidebarWidgetForSalesforce from './components/TackleSidebarWidgetForSalesforce';
import CoSellCanvasApp from 'packages/salesforce-canvas/cosell/src/components/CoSellCanvasApp';
import OffersCanvasApp from 'packages/salesforce-canvas/offers/OffersCanvasApp';
const CanvasApp = () => {
  const { canvasSession, isAuthorized, isLoadingCanvasSession, payload } =
    useCanvasSession();
  const classes = useCanvasAppStyles();

  if (isLoadingCanvasSession) {
    return <Loader />;
  }

  if (!isAuthorized) {
    return <CanvasTackleIntegrationStatus />;
  }

  const componentId = canvasSession?.context?.parameters.componentId;

  if (!componentId) {
    return <div>No context provided.</div>;
  }

  const getComponentToRender = () => {
    switch (componentId) {
      case 'TackleWidget':
        return <TackleSidebarWidgetForSalesforce />;
      case 'CanvasApp':
        return <CanvasAppComponent domain={payload.domain} />;
      case 'Setup':
        return <CanvasTackleIntegrationStatus />;
      default:
        return <div>Unknown componentId: {componentId} provided.</div>;
    }
  };

  return (
    <>
      <AwsOpportunityActionsProvider token={canvasSession.access_token}>
        <div className={classes.canvasAppContainer}>
          {getComponentToRender()}
        </div>
      </AwsOpportunityActionsProvider>
    </>
  );
};

const CanvasAppComponent = ({ domain }: { domain: 'cosell' | 'offers' }) => {
  const Component = {
    cosell: CoSellCanvasApp,
    offers: OffersCanvasApp,
  }[domain];
  return <Component />;
};

const CanvasAppWithSessionProvider = () => {
  return (
    <ReactQueryClientProvider>
      <CanvasApp />
    </ReactQueryClientProvider>
  );
};

export default CanvasAppWithSessionProvider;
