import { useState } from 'react';
import { Grid, makeStyles, Tab, Tabs } from 'vendor/material';
import TackleLogo from 'images/tackle-logo';
import { Radar, BrightnessPercent } from 'mdi-material-ui';
import { ReactComponent as Handshake } from '@material-symbols/svg-400/rounded/handshake-fill.svg';
import OffersTabContent from 'packages/salesforce-canvas/offers/OffersTabContent';
import TackleWidgetCoSellView from 'packages/salesforce-canvas/cosell/src/components/TackleWidgetCoSellView';

const useTackleSidebarWidgetForSalesforceStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  tackleAppBar: {
    background: theme.palette.TEAL900,
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    paddingInline: '1rem',
  },
  cosellIcon: {
    width: '16px',
    height: '16px',
  },
}));

const TackleSidebarWidgetForSalesforce = () => {
  const classes = useTackleSidebarWidgetForSalesforceStyles();
  const [selectedTab, setSelectedTab] = useState<
    'prospect' | 'cosell' | 'offers'
  >('cosell');
  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <div className={classes.tackleAppBar}>
          <TackleLogo theme="light" width="104px" />
        </div>
      </Grid>
      <Grid item>
        <Tabs
          // TODO: these still need their custom styles
          value={selectedTab}
          onChange={(e, val) => setSelectedTab(val)}
          centered
        >
          <Tab
            value="prospect"
            label={
              <Grid container spacing={1}>
                <Grid item>
                  <Radar fontSize="inherit" />
                </Grid>
                <Grid item xs>
                  Prospect
                </Grid>
              </Grid>
            }
          />
          <Tab
            value="cosell"
            label={
              <Grid container spacing={1}>
                <Grid item>
                  <Handshake
                    fontSize="inherit"
                    className={classes.cosellIcon}
                  />
                </Grid>
                <Grid item xs>
                  Co-Sell
                </Grid>
              </Grid>
            }
          />
          <Tab
            value="offers"
            label={
              <Grid container spacing={1}>
                <Grid item>
                  <BrightnessPercent fontSize="inherit" />
                </Grid>
                <Grid item xs>
                  Offers
                </Grid>
              </Grid>
            }
          />
        </Tabs>
      </Grid>
      <Grid item xs style={{ overflow: 'hidden', position: 'relative' }}>
        {selectedTab === 'prospect' && <div>Prospect placeholder</div>}
        {selectedTab === 'cosell' && <TackleWidgetCoSellView />}
        {selectedTab === 'offers' && <OffersTabContent />}
      </Grid>
    </Grid>
  );
};

export default TackleSidebarWidgetForSalesforce;
