import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
import {
  CreateAceOpportunityFormValues,
  UpdateAceOpportunityFormValues,
} from './AceOpportunityFormValues';
import { convertOpportunityResponseToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { isEmpty } from 'lodash-es';
import {
  convertAceFormValuesToUpdateRequest,
  getSoftwareRevenue,
} from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import {
  getEditPageSubmitTackleOperationId,
  TackleOperationId,
} from 'packages/cosell/api/utils';
// import { getMockInitialValues } from 'packages/cosell/api/mockHelpers';
import { Loader } from '@tackle-io/platform-ui';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { COSELL_CREATE_DEFAULT_VALUES } from 'packages/cosell/src/utilities/constants/CoSellConstants';
import { removeUndefinedOrEmptyObjectProperties } from 'packages/cosell/src/utilities/typeConverters/utils';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { useAcePickListQuery } from 'packages/cosell/api/hooks/useAcePickList';
import { CreationPending } from './CreationPending';
import { useState } from 'react';
import { AceCoSellErrorMessagesBanner } from 'packages/cosell/src/pages/AceCoSellErrorMessagesBanner';

interface AceOpportunityFormProps {
  /** salesforce opportunity id
   * only added if creating an opportunity from an existing salesforce opportunity
   */
  crmId?: string;
  opportunityId?: string;
  onCancel: () => void;
  onCreated?: (opportunityId: string) => void;
}

/**
 *
 * TODO:
 * - if creation failed, the tackle-id is still created and should be "updated" (edit) with instead of creating a new one.
 *   The edit form can then be prefilled with the original payload values
 */
const AceOpportunityForm = ({
  crmId,
  opportunityId,
  onCancel,
  onCreated,
}: AceOpportunityFormProps) => {
  const [creationPendingOpportunity, setCreationPendingOpportunity] = useState<{
    tackleId: string | null;
    hasAceError: boolean;
  }>({
    tackleId: null,
    hasAceError: false,
  });

  const acePickListQuery = useAcePickListQuery();
  const {
    isSaasDocumentationRequiredToLaunch,
    isError: isVendorConfigError,
    isLoading: isVendorConfigLoading,
  } = useAceVendorConfig();
  const { aceOpportunityQuery, updateAceOpportunity, createAceOpportunity } =
    useAceOpportunity({ opportunityId });
  const opportunity = aceOpportunityQuery?.data;
  const isEdit = !!opportunityId;

  // const mockInitialValues = getMockInitialValues();

  const handleCreateOrUpdateOpportunity = async (
    values: CreateAceOpportunityFormValues | UpdateAceOpportunityFormValues,
  ) => {
    if (isEdit) {
      const updateValues = values as UpdateAceOpportunityFormValues;
      const requiredSaasDocumentationToLaunchParams =
        isSaasDocumentationRequiredToLaunch({
          deliveryModels: opportunity.project?.deliveryModels ?? [],
        })
          ? { softwareRevenue: getSoftwareRevenue(updateValues) }
          : null;

      const body = {
        ...convertAceFormValuesToUpdateRequest(updateValues),
        ...requiredSaasDocumentationToLaunchParams,
      };
      const trimmedBody = removeUndefinedOrEmptyObjectProperties(body);
      const tackleOperationId =
        'lifeCycle' in opportunity
          ? getEditPageSubmitTackleOperationId(
              opportunity?.lifeCycle?.reviewStatus,
            )
          : null;
      return updateAceOpportunity.mutateAsync({
        requestBody: trimmedBody,
        tackleOperationId,
      });
    } else {
      const createAndSubmitReqBody =
        convertAceFormValuesToCreateRequest(values);
      if (crmId) {
        createAndSubmitReqBody['crmId'] = crmId;
      }

      const response = await createAceOpportunity.mutateAsync({
        requestBody: createAndSubmitReqBody,
        tackleOperationId: TackleOperationId.CREATE_OPPORTUNITY,
      });

      setCreationPendingOpportunity({
        tackleId: response.id,
        hasAceError: false,
      });
      return response;
    }
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);

    if (isEdit) {
      return updateAceOpportunity.mutateAsync({
        requestBody,
        tackleOperationId: TackleOperationId.UPDATE_OPPORTUNITY,
      });
    } else {
      if (crmId) {
        requestBody['crmId'] = crmId;
      }

      const response = await createAceOpportunity.mutateAsync({
        requestBody,
        tackleOperationId: TackleOperationId.CREATE_DRAFT_OPPORTUNITY,
      });
      setCreationPendingOpportunity({
        tackleId: response.id,
        hasAceError: false,
      });
      return response;
    }
  };

  const flattenedAceFormValues =
    !isEmpty(opportunity) &&
    'id' in opportunity &&
    convertOpportunityResponseToAceFormValues(opportunity);

  const handleCreationError = ({
    aceError,
    error,
  }: {
    aceError: boolean;
    error: unknown;
  }) => {
    if (aceError) {
      setCreationPendingOpportunity((prev) => ({
        ...prev,
        hasAceError: true,
      }));
      return;
    }

    // TODO: remove when complete with SF integration
    console.error(JSON.stringify(error));
  };

  if (
    updateAceOpportunity.isLoading ||
    isVendorConfigLoading ||
    acePickListQuery.isLoading
  ) {
    return <Loader />;
  }

  const emptyStateInitialValues = {
    /** mockInitialValues should only be used in development.
     * enable if you want to pre-fill a form without having to fill all the fields. */
    // ...mockInitialValues,
    ...COSELL_CREATE_DEFAULT_VALUES,
  };

  if (isVendorConfigError || acePickListQuery.isError) {
    return <div>Required data failed to load, please refresh</div>;
  }

  /** an opportunity that has been created in the cloud.
   *  we do not want to pass in an incomplete (pendingCreation) opportunity
   * TODO: update the opportunity type in UnifiedOpportunityFormShell to accept a null opportunity
   * since should not set this to undefined.
   * undefined is used to satisfy typescript
   */
  const cloudCreatedOpportunity =
    !!opportunity && 'id' in opportunity ? opportunity : undefined;

  if (
    creationPendingOpportunity.tackleId &&
    creationPendingOpportunity.hasAceError === false
  ) {
    if (typeof onCreated !== 'function') {
      throw new Error('onCreated callback is required in AceOpportunityForm');
    }
    return (
      <CreationPending
        onError={handleCreationError}
        onSuccess={onCreated}
        pendingAceTackleId={creationPendingOpportunity.tackleId}
      />
    );
  }

  return (
    <>
      <UnifiedOpportunityFormShell
        onCancel={onCancel}
        opportunityId={opportunityId}
        opportunity={cloudCreatedOpportunity}
        onSubmit={handleCreateOrUpdateOpportunity}
        onSaveAsDraft={handleSaveAceOpportunityAsDraft}
        initialValues={
          flattenedAceFormValues
            ? flattenedAceFormValues
            : emptyStateInitialValues
        }
        provider={DisplayCloudType.AWS}
        validationSchema={aceOpportunityFormValidationSchema}
      >
        {creationPendingOpportunity.hasAceError ? (
          <div style={{ paddingTop: '1rem' }}>
            <AceCoSellErrorMessagesBanner
              opportunityId={creationPendingOpportunity.tackleId}
              title="Co-sell creation failed"
            />
          </div>
        ) : null}
        <AceOpportunityFormFields />
      </UnifiedOpportunityFormShell>
    </>
  );
};

export default AceOpportunityForm;
