import { useQuery } from '@tanstack/react-query';
import coSellClient from '../coSellClient';

/** query to fetch the generic Pick list options for AWS Ace
 *  sometimes referred to as awsEnumListMap
 */
export function useAcePickListQuery() {
  return useQuery({
    queryKey: ['aws-cosell-enum-options'],
    queryFn: coSellClient.getAwsCoSellEnumOptions,
    // this should never change, so no need to refetch
    staleTime: Infinity,
  });
}
