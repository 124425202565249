import { gql } from '@apollo/client';
import {
  useUsageRecordsLazyQuery,
  UsageRecordsQuery,
  UsageRecordsQueryResult,
} from 'generated/graphql';
import { createContext, useContext, useEffect } from 'react';
import { useSingleContractData } from './SingleContractDataProvider';

// Remove ContractUsage interface as we'll use generated types
export const USAGE_RECORDS = gql`
  query UsageRecords($productId: String!, $customerId: String!) {
    usageRecords(productId: $productId, customerId: $customerId) {
      data {
        created_at
        status
        dimension {
          name
          quantity
        }
      }
    }
  }
`;

interface ContractUsageContextProps {
  usage?: UsageRecordsQuery['usageRecords']['data'];
  loading?: boolean;
  error?: UsageRecordsQueryResult['error'];
  fetchUsageData?: () => void;
}

const ContractUsageContext = createContext<ContractUsageContextProps | null>(
  null,
);

interface ContractUsageDataProviderProps {
  children?: React.ReactNode;
}

export const ContractUsageDataProvider: React.FC<
  ContractUsageDataProviderProps
> = ({ children }) => {
  const { contract } = useSingleContractData();

  const [fetchUsageRecords, { data, loading, error }] =
    useUsageRecordsLazyQuery({
      variables: {
        productId: contract?.usageMapping?.productId,
        customerId: contract?.usageMapping?.customerId,
      },
    });

  useEffect(() => {
    if (contract?.productId && contract?.buyerId) {
      fetchUsageRecords();
    }
  }, [contract?.productId, contract?.buyerId, fetchUsageRecords]);

  return (
    <ContractUsageContext.Provider
      value={{
        usage: data?.usageRecords?.data ?? [],
        loading,
        error,
        fetchUsageData: fetchUsageRecords,
      }}
    >
      {children}
    </ContractUsageContext.Provider>
  );
};

export const useContractUsageData = (): ContractUsageContextProps => {
  const context = useContext(ContractUsageContext);
  if (!context) {
    throw new Error(
      'useContractUsageData must be used within a ContractUsageDataProvider',
    );
  }
  return context;
};
