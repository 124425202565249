import { useState, useEffect } from 'react';
import { ampli } from 'utils/analytics/ampli';
import { gql } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { Box, Typography } from 'vendor/material';
import { string as yupString, object as yupObject } from 'yup';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  Modal,
  TextField,
  Select,
  UserPicker,
  Spinner,
} from '@tackle-io/platform-ui';

import useStyles from './UserModal.styles';
import { useToast } from 'hooks';
import { ToastSuccess } from 'hooks/useToast/toast.templates';

import {
  useGetRolesForUserModalQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
} from 'generated/graphql';

export const modalTypes = { invite: 'INVITE', edit: 'EDIT', delete: 'DELETE' };

const schema = yupObject().shape({
  email: yupString().email().required(),
  role: yupString().required(),
});

export const GET_ROLES_FOR_USER_MODAL_QUERY = gql`
  query GetRolesForUserModal {
    roleWithVendorNames {
      id
      name
      organization_id
      allowed_vendors
      allowed_vendors_names
      global
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($email: String!, $role: Auth0UserCreateInputRole!) {
    createUser(email: $email, role: $role) {
      id
      email
      picture
      name
      nickname
      logins_count
      email_verified
      app_metadata {
        role
        vendorid
        organization_id
        vendor_type
        allowed_vendors
        password_set
        superadmin
        supportapi
        sso_user
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $userId: String!
    $email: String!
    $role: Auth0UserCreateInputRole!
  ) {
    updateUser(userId: $userId, email: $email, role: $role) {
      id
      email
      app_metadata {
        role
        vendorid
        organization_id
        vendor_type
        allowed_vendors
        password_set
        superadmin
        supportapi
        sso_user
      }
      picture
      name
      nickname
      logins_count
      email_verified
    }
  }
`;

const UserModal = ({
  active,
  type,
  userData,
  onClose,
  usersList,
  organizationVendors = [],
  onRefetch = () => {},
}) => {
  const classes = useStyles();

  const {
    data,
    loading: loadingRoles,
    refetch,
  } = useGetRolesForUserModalQuery();
  const [createUser, { error: createUserError }] = useCreateUserMutation();
  const [updateUser, { error: updateUserError }] = useUpdateUserMutation();

  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const isEdit = type === modalTypes.edit;
  const defaultEmail = active && userData && isEdit ? userData.email : '';
  const defaultRole = active && userData && isEdit ? userData.role_id : '';

  const { toaster } = useToast();
  const showToast = (message) => {
    toaster({
      message: <ToastSuccess message={message} />,
      options: {
        position: 'top-center',
        type: 'success',
        closeButton: true,
        autoClose: 5000,
        draggable: false,
      },
    });
  };

  const { register, formState, errors, handleSubmit, reset, setValue } =
    useForm({
      mode: 'onChange',
      defaultValues: { email: defaultEmail, role: defaultRole },
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (isEdit && userData) {
      setValue('role', userData.role_id);
      setSelectedRole(userData.role_id);
    }
  }, [isEdit, userData, setValue]);

  useEffect(() => {
    if (active) {
      refetch();
    }
  }, [active, refetch]);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  function handleClose() {
    setLoading(false);
    reset({ email: '', role: '' });
    setSelectedRole('');
    onClose();
  }

  const onSubmit = async ({ email, role }) => {
    setLoading(true);
    try {
      const roleInput = { id: role };
      if (type === modalTypes.invite) {
        await createUser({ variables: { email, role: roleInput } });
        showToast('User created successfully');
      } else if (type === modalTypes.edit) {
        await updateUser({
          variables: { userId: userData.user_id, email, role: roleInput },
        });
        showToast('User updated successfully');
      }
      ampli.customRoleAssigned({
        organization_id: role.organization_id,
        custom_role_id: role.id,
        custom_role_name: role.name,
      });
      setTimeout(() => {
        onRefetch();
      }, 1500);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const modalTitle = isEdit ? 'Edit user' : 'Invite user';
  const modalSubmitText = isEdit ? 'Update' : 'Send invite';
  const fieldEmailLabel = isEdit ? 'User email' : 'Email address';
  const displayUserName = isEdit && userData;
  const submitDisabled =
    formState.isSubmitting || !formState.isValid || !formState.isDirty;

  const selectedRoleData = data?.roleWithVendorNames?.find(
    (role) => role.id === selectedRole,
  );
  // If the role is global, set accounts to all of the organization's vendors
  // Otherwise, set accounts to the allowed vendors for the role
  const allowedVendors = selectedRoleData?.global
    ? organizationVendors?.map((vendor) => vendor.name) || []
    : selectedRoleData?.allowed_vendors_names || [];

  // STYLES
  const cssPaperClasses = classnames({
    [classes.modalContainer]: usersList,
  });

  const cssForm = classnames({
    [classes.formContainer]: usersList,
  });

  return (
    <Modal
      title={<div className={classes.title}>{modalTitle}</div>}
      width="medium"
      open={active}
      onClose={handleClose}
      paperClasses={cssPaperClasses}
      footerActions={[
        <Box key="close" mr={1}>
          <Button
            appearance="primary"
            variant="outlined"
            style={{ border: '1px solid #C1C7D0' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>,
        <Button
          appearance="primary"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          disabled={submitDisabled}
          data-testid="userSubmit"
          key="submit"
        >
          {modalSubmitText}
        </Button>,
      ]}
    >
      <div className={cssForm}>
        {createUserError && (
          <Box my={2}>
            <Alert
              appearance="danger"
              title={`Failed to create a new user. ${
                createUserError.message.includes('409: Conflict')
                  ? 'User already invited'
                  : createUserError.message
              }`}
            />
          </Box>
        )}
        {updateUserError && (
          <Box my={2}>
            <Alert
              appearance="danger"
              title={`Failed to update the user. ${updateUserError.message}`}
            />
          </Box>
        )}
        <form noValidate>
          <TextField
            id="userEmail"
            type="email"
            label={fieldEmailLabel}
            name="email"
            readOnly={isEdit}
            error={errors.email?.message}
            data-testid="userEmail"
            inputRef={register}
          />
          {displayUserName && (
            <Box mt={2}>
              <TextField
                label="Name"
                defaultValue={userData.displayName}
                disabled
              />
            </Box>
          )}
          <Box mt={2}>
            {loadingRoles ? (
              <Spinner type="ellipsis" />
            ) : (
              <Select
                id="userRole"
                data-id="tooltip-userModalRoleSelection"
                label="Select the user role"
                name="role"
                SelectProps={{ 'aria-label': 'Field_Type_Select' }}
                data-testid="userRole"
                inputRef={register}
                onChange={handleRoleChange}
              >
                {data?.roleWithVendorNames?.map((role) => (
                  <option key={role.name} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Select>
            )}
          </Box>
        </form>
        {selectedRole && (
          <Box mt={2}>
            <Typography variant="caption" style={{ color: '#5E6C84' }}>
              Account access based on role:{' '}
              <strong>{allowedVendors?.join(', ')}</strong>
            </Typography>
          </Box>
        )}
      </div>
      {usersList && (
        <div className={classes.userListContainer}>
          <UserPicker
            disabled={false}
            onClick={() => {}}
            users={usersList}
            selectedUserIds={[]}
            mode="status"
            listItemClasses={classes.userListItem}
          />
        </div>
      )}
    </Modal>
  );
};

export default observer(UserModal);
