import { Toaster } from '@tackle-io/platform-ui';
import { CoSellContextProvider } from 'packages/cosell/src/CoSellContextProvider';
import CoSellSfTackleWidget from '../components/CoSellSfTackleWidget/CoSellSfTackleWidget';
import AceOpportunityForm from '../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityForm';

type ComponentConfig =
  | {
      id: 'create';
      /** salesforce opportunity id */
      crmId: string;
      onCreated: (opportunityId: string) => void;
      onCancel: () => void;
    }
  | { id: 'edit'; opportunityId: string }
  | {
      id: 'details';
      opportunityId: string;
    }
  | {
      id: 'tackleWidget';
      crmId: string;
      onAction: ({
        action,
        data,
      }: {
        action: string;
        data: Record<string, string>;
      }) => void;
    };

/**
 * Entry point for Salesforce Canvas app
 * - contains providers and data
 *
 * should be used in the root entry point of CoSell from Salesforce Canvas
 */
export default function SalesforceCanvasEntry({
  component,
  vendorId,
}: {
  vendorId: string;
  component: ComponentConfig;
}) {
  return (
    <CoSellContextProvider renderEnv="sf_canvas" vendorId={vendorId}>
      <Toaster />
      {component.id === 'create' && <AceOpportunityForm {...component} />}
      {component.id === 'tackleWidget' && (
        <CoSellSfTackleWidget {...component} />
      )}
    </CoSellContextProvider>
  );
}
