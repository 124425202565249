import { makeStyles } from 'vendor/material';
import { fontWeightMedium } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: fontWeightMedium,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    color: '#5E6C84',
  },
}));

export default useStyles;
