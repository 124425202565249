import { SolutionsOfferedToggleEnum } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { AcePartnerNeedType } from '../../types/enums';

// TODO: Remove /v3/ route when done testing & v3 is Live & use FeatureFlag
export const COSELL_PATH = '/co-sell';

export enum UnifiedOpportunityView {
  CO_SELL_DETAILS = 'CoSellDetails',
  CREATE_NEW_CO_SELL_FORM = 'CreateNewCoSellForm',
  EDIT_CO_SELL_FORM = 'EditCoSellForm',
  CO_SELL_LANDING_PAGE = 'CoSellLandingPage',
}

/** values that should be pre-set in the create opportunity form.
 *  Some values might be REQUIRED by the API, check this const for notes
 */
export const COSELL_CREATE_DEFAULT_VALUES = {
  /** For now the only valid value is Monthy, and this cannot be changed by the vendor */
  expectedCustomerSpendFrequency: 'Monthly',
  /** For the moment the only acceptable value is AWS, and this cannot be changed by the vendor */
  expectedCustomerSpendTargetCompany: 'AWS',
  /** The SolutionsOfferedToggle is a UI-only property with a default value of existingSolutions.
   *  As a result, the solutions dropdown is displayed upon initial rendering.*/
  solutionsOfferedToggle: SolutionsOfferedToggleEnum.EXISTING_SOLUTIONS,
  /** Default to 'Co-sell with AWS' to ensure the primaryNeedsFromAws field renders initially */
  partnerNeedType: AcePartnerNeedType.CO_SELL_WITH_AWS,
} as const;

export const SOURCE_TAG = {
  /** Tackle outbound */
  OUTBOUND: 'Partner originated',
  /** Tackle inbound, for invitations*/
  INBOUND: 'Cloud originated',
} as const;
