import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import SalesforceCanvasEntry from 'packages/cosell/src/pages/SalesForceCanvasEntry';
import useCoSellSfCanvasActions from '../hooks/useCoSellSfCanvasActions';
import invariant from 'ts-invariant';
/**
 * Canvas component which renders the Create Co-Sell form
 */
export default function CanvasCreateCoSell() {
  const { vendorId, record } = useCanvasSession();
  const { handleCoSellAction } = useCoSellSfCanvasActions();

  const handleClose = () => {
    handleCoSellAction({ id: 'go-back-to-opportunity-root' });
  };

  invariant(
    record?.Id,
    'The recordId is required to create a Co-sell opportunity, but not defined',
  );

  return (
    <SalesforceCanvasEntry
      component={{
        id: 'create',
        crmId: record.Id,
        onCreated: handleClose,
        onCancel: handleClose,
      }}
      vendorId={vendorId}
    />
  );
}
