import {
  AceInvolvementTypeEnum,
  AceInvolvementVisibilityEnum,
  AceOpportunityReviewStatusEnum,
  AceOpportunityStageEnum,
  AcePartnerNeedType,
  OpportunityIndustryEnum,
} from 'packages/cosell/src/types/enums';
import {
  CreateAceOpportunityFormValues,
  SolutionsOfferedToggleEnum,
} from './AceOpportunityFormValues';
import { AwsEnumOption } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { MarketingSourceEnum } from 'packages/cosell/src/types/enums/MarketingSourceEnum';
import { OpportunityTypeEnum } from 'packages/cosell/src/types/enums/OpportunityTypeEnum';

interface AwsEnumOptionWithHelperText extends AwsEnumOption {
  helperText: string;
}

export const UNITED_STATES_COUNTRY_CODE = 'US';
export const AllowedCustomerEditableFieldsText =
  'Customer website, country, postal code, state/province, city, and address.';
export const AllowedProjectEditableFieldsText =
  'Partner specific need from AWS, customer business problem, estimated monthly recurring revenue, and target close date.';

// TODO: Implement with isFieldEditable
// Think about origination (outbound vs inbound) later. Current implementation assumes outbound

const ActionRequiredAllowedEditableFields = [
  'customerWebsite',
  'countryCode',
  'postalCode',
  'address',
  'city',
  'state',
  'primaryNeedsFromAws',
  'customerBusinessProblem',
  'expectedCustomerSpend',
  'targetCloseDate',
];
// The fields in this banner should map directly to ActionRequiredAllowedEditableFields
export const ActionRequiredBannerBodyContent = [
  {
    sectionTitle: 'Customer fields:',
    fields:
      'Customer website, country, postal code, state/province, city, and address.',
  },
  {
    sectionTitle: 'Project fields:',
    fields:
      'Partner specific need from AWS, customer business problem, estimated monthly recurring revenue, and target close date.',
  },
];

// AWS locks specific fields in Approved status
const ApprovedLockedEditableFields = [
  'customerCompanyName',
  'industry',
  'industryOther',
  'customerWebsite',
  'countryCode',
  'state',
  'postalCode',
  'projectTitle',
  'customerBusinessProblem',
];
// The fields in this banner should map directly to ApprovedLockedEditableFields
export const ApprovedBannerBodyContent = [
  {
    sectionTitle: 'Locked customer fields:',
    fields:
      'Customer company name, industry vertical, industry other, customer website, country, postal code, and state/province.',
  },
  {
    sectionTitle: 'Locked project fields:',
    fields: 'Partner project title and customer business problem.',
  },
];

export const isFieldEditable = ({
  name,
  status,
}: {
  name: string;
  status: AceOpportunityReviewStatusEnum | undefined;
}) => {
  if (!status || status === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION) {
    return true;
  } else if (
    status === AceOpportunityReviewStatusEnum.ACTION_REQUIRED &&
    ActionRequiredAllowedEditableFields.includes(name)
  ) {
    return true;
  } else if (
    status === AceOpportunityReviewStatusEnum.APPROVED &&
    !ApprovedLockedEditableFields.includes(name)
  ) {
    return true;
  }
  return false;
};

export const getSingleSelectFormOptions = (displayValueOption) => ({
  title: displayValueOption.display,
  value: displayValueOption.value,
});

export const handleOpportunitySourceChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: Function,
) => {
  if (e.target.value === MarketingSourceEnum.NONE) {
    setFieldValue('marketingActivityUseCases', null);
    setFieldValue('marketingActivityChannel', null);
    setFieldValue('isMarketingDevelopmentFunded', null);
  }
};

export const handlePartnerNeedTypeChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: Function,
) => {
  if (
    e.target.value === AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
  ) {
    setFieldValue('salesActivities', null);
    setFieldValue('primaryNeedsFromAws', null);
  }
};

export const handleOpportunityTypeChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: Function,
) => {
  if (e.target.value === OpportunityTypeEnum.NET_NEW_BUSINESS) {
    setFieldValue('parentOppId', null);
  }
};

export const handleIndustryChange = ({
  value,
  values,
  setFieldValue,
}: {
  value?: string;
  values: CreateAceOpportunityFormValues;
  setFieldValue: Function;
}) => {
  if (
    (!value || value !== OpportunityIndustryEnum.OTHER) &&
    values.industryOther
  ) {
    setFieldValue('industryOther', null);
  }
  if (
    (!value || value !== OpportunityIndustryEnum.GOVERNMENT) &&
    values.nationalSecurity
  ) {
    setFieldValue('nationalSecurity', null);
  }
};

export const handleSolutionRelatedFieldsChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: Function,
) => {
  if (e.target.value === SolutionsOfferedToggleEnum.EXISTING_SOLUTIONS) {
    setFieldValue('otherSolutionDescription', null);
  }
  if (e.target.value === SolutionsOfferedToggleEnum.OTHER_SOLUTION) {
    setFieldValue('solutions', null);
  }
};

enum OpportunityTypeHelperText {
  'Net New Business' = 'This opportunity is based on a new contract or agreement with this new customer/ logo for your company.',
  'Flat Renewal' = 'This opportunity is based on an existing contract with this end customer where no expansion will take place.',
  'Expansion' = 'This opportunity is based on an existing contract with this end customer which may include new line of business, partner product, or additional customer instances.',
}

export const getOpportunityTypeOptions = (
  options: AwsEnumOption[] = [],
): AwsEnumOptionWithHelperText[] =>
  options.map((option) => ({
    ...option,
    helperText: OpportunityTypeHelperText[option.value],
  }));

enum OpportunitySourceLabel {
  'Marketing Activity' = 'Yes',
  'None' = 'No',
}

enum OpportunitySourceHelperText {
  'Marketing Activity' = 'Sourced from marketing activity.',
  'None' = 'Not sourced from marketing activity.',
}

export const getOpportunitySourceOptions = (
  options: AwsEnumOption[] = [],
): AwsEnumOptionWithHelperText[] =>
  options.map(({ value }) => ({
    display: OpportunitySourceLabel[value],
    value,
    helperText: OpportunitySourceHelperText[value],
  }));

enum NationalSecurityLabel {
  'Yes' = 'Yes: This opportunity contains classified national security information.',
  'No' = 'No: This opportunity does not contain classified national security information.',
}

export const getNationalSecurityOptions = (
  options: AwsEnumOption[] = [],
): AwsEnumOption[] =>
  options.map(({ value }) => ({
    display: NationalSecurityLabel[value],
    value,
  }));

enum AwsFundingUsedHelperText {
  'Yes' = 'Marketing development funds were used for this opportunity.',
  'No' = 'Marketing development funds were not used for this opportunity.',
}

export const getAwsFundingUsedOptions = (
  options: AwsEnumOption[] = [],
): AwsEnumOptionWithHelperText[] =>
  options.map(({ display, value }) => ({
    display,
    value,
    helperText: AwsFundingUsedHelperText[value],
  }));

export const getCoSellStageOptions = (
  options: AwsEnumOption[] = [],
): AwsEnumOption[] =>
  options.filter(
    ({ value }) =>
      value !== AceOpportunityStageEnum.CLOSED_LOST &&
      value !== AceOpportunityStageEnum.LAUNCHED,
  );

export const solutionsOfferedToggleOptions: AwsEnumOptionWithHelperText[] = [
  {
    display: 'Existing solutions',
    value: SolutionsOfferedToggleEnum.EXISTING_SOLUTIONS,
    helperText: 'Select from a list of existing solutions',
  },
  {
    display: 'Other solution',
    value: SolutionsOfferedToggleEnum.OTHER_SOLUTION,
    helperText:
      'Describe a solution that is not in the list of existing solutions',
  },
];

export const involvementTypeOptions: AwsEnumOptionWithHelperText[] = [
  {
    display: 'Co-sell',
    value: AceInvolvementTypeEnum.CO_SELL,
    helperText: 'AWS will help with co-selling, deal support and consultation.',
  },
  {
    display: 'For visibility only',
    value: AceInvolvementTypeEnum.FOR_VISIBILITY_ONLY,
    helperText:
      'Share this opportunity with AWS for visibility only, you will not receive deal assistance and support.',
  },
];

export const visibilityOptions: AwsEnumOptionWithHelperText[] = [
  {
    display: AceInvolvementVisibilityEnum.FULL,
    value: AceInvolvementVisibilityEnum.FULL,
    helperText: 'All AWS sellers and partners will be able to see this deal.',
  },
  {
    display: AceInvolvementVisibilityEnum.LIMITED,
    value: AceInvolvementVisibilityEnum.LIMITED,
    helperText:
      'Restricted based on special program requirements or confidentiality needs.',
  },
];

export const partnerTypeNeedOptions: AwsEnumOptionWithHelperText[] = [
  {
    display: 'Co-sell with AWS',
    value: AcePartnerNeedType.CO_SELL_WITH_AWS,
    helperText:
      'Share the opportunity with AWS to receive deal assistance and support.',
  },
  {
    display: 'Do not need support from AWS sales rep',
    value: AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP,
    helperText:
      'Share the opportunity with AWS for visibility only, you will not receive deal assistance or support.',
  },
];

export const primaryContactTitleOptions: AwsEnumOption[] = [
  {
    display: 'Partner Account Manager',
    value: 'PartnerAccountManager',
  },
];
