import { Duration } from 'luxon';

export const formatDate = (date?: string): string => {
  return date
    ? new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : '--';
};

export const convertISODuration = (isoString?: string): string => {
  if (!isoString) return '--';

  const duration = Duration.fromISO(isoString);

  const months = duration.months;
  const years = duration.years;
  const days = duration.days;

  const yearText = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthText = months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';
  const daysText = days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '';

  const parts = [yearText, monthText, daysText].filter(Boolean);
  if (parts.length === 1) return parts[0];
  if (parts.length === 2) return parts.join(' and ');
  if (parts.length === 3) return `${parts[0]}, ${parts[1]} and ${parts[2]}`;
  return '--';
};
