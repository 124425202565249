import { Link } from '@tackle-io/platform-ui';
import { OpenInNew } from 'mdi-material-ui';
import { Box } from 'vendor/material';

const LinkItem = ({ label, to }) => {
  const color = '#0052CC';
  return (
    <Link to={to} underline="none" external disableStyles>
      <Box display="flex" gridGap={8} alignItems={'center'}>
        <span style={{ color }}>{label}</span>
        <OpenInNew fontSize="small" style={{ color }} />
      </Box>
    </Link>
  );
};

export default LinkItem;
