import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem, {
  FULL_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { SoftwareRevenue } from 'packages/cosell/src/types/AceOpportunity.type';

// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';

interface AceAdditionalDetailsProps {
  isSRRPAndLaunched?: boolean;
  awsAccountId?: string;
  additionalComments?: string;
  partnerOpportunityIdentifier?: string;
  competitiveTracking?: string;
  softwareRevenue?: SoftwareRevenue;
}

const AceAdditionalDetails: React.FC<AceAdditionalDetailsProps> = ({
  isSRRPAndLaunched,
  awsAccountId,
  additionalComments,
  partnerOpportunityIdentifier,
  competitiveTracking,
  softwareRevenue,
}) => {
  return (
    <DetailsSectionCard title="Additional details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner CRM unique identifier"
          value={partnerOpportunityIdentifier}
          gridSize={FULL_GRID_SIZE}
          dataId={
            coSellAceOpportunityFormFieldsDataId.PARTNER_CRM_UNIQUE_IDENTIFIER
          }
        />

        <InfoItem
          itemTitle="Competitive tracking"
          value={competitiveTracking}
          dataId={coSellAceOpportunityFormFieldsDataId.COMPETITIVE_TRACKING}
        />

        <InfoItem
          itemTitle="Additional comments"
          value={additionalComments}
          dataId={coSellAceOpportunityFormFieldsDataId.ADDITIONAL_COMMENTS}
        />
        <InfoItem
          itemTitle="AWS account ID"
          value={awsAccountId}
          dataId={coSellAceOpportunityFormFieldsDataId.AWS_ACCOUNT_ID}
          gridSize={FULL_GRID_SIZE}
        />

        {isSRRPAndLaunched && (
          <>
            <InfoItem
              itemTitle="Marketplace offer ID"
              value={'Marketplace offer id?'} // TODO: add the correct value
              dataId={coSellAceOpportunityFormFieldsDataId.MARKETPLACE_OFFER_ID}
            />
            <InfoItem
              itemTitle="Procurement type"
              value={'No Procurement type data'} // TODO: add the correct value
              dataId={coSellAceOpportunityFormFieldsDataId.PROCUREMENT_TYPE}
            />

            <InfoItem
              itemTitle="Customer software value"
              value={softwareRevenue?.value?.amount}
              dataId={
                coSellAceOpportunityFormFieldsDataId.CUSTOMER_SOFTWARE_VALUE
              }
            />
            <InfoItem
              itemTitle="Currency"
              value={softwareRevenue?.value?.currencyCode}
              dataId={coSellAceOpportunityFormFieldsDataId.CURRENCY_CODE}
            />

            <InfoItem
              itemTitle="Contract start date"
              value={softwareRevenue?.effectiveDate}
              dataId={coSellAceOpportunityFormFieldsDataId.CONTRACT_START_DATE}
            />
            <InfoItem
              itemTitle="Contract end date"
              value={softwareRevenue?.expirationDate}
              dataId={coSellAceOpportunityFormFieldsDataId.CONTRACT_END_DATE}
            />
          </>
        )}
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceAdditionalDetails;
