import { AceSolutionResponse } from '../types/responses/AceSolutionResponse';
import { AwsEnumOption } from '../types/responses/AwsEnumResponse';

export const solutionsApiToFormFormatter = (
  solutions: AceSolutionResponse[],
): AwsEnumOption[] => {
  let solutionsToDisplay: AwsEnumOption[] = [];
  if (!!solutions) {
    solutionsToDisplay = solutions?.map((s) => ({
      display: `${s.name}: ${s.cloudProviderId}`,
      value: s.cloudProviderId,
    }));
  }
  return solutionsToDisplay;
};
