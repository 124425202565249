import { Button } from '@tackle-io/platform-ui';
import { useCanvasSession } from '../src/useCanvasSession';
import useCoSellSfCanvasActions from '../cosell/src/hooks/useCoSellSfCanvasActions';
const OffersCanvasApp = () => {
  const { payload } = useCanvasSession();

  /** only added as example, you should create an offers actions hook
   */
  const { handleCoSellAction } = useCoSellSfCanvasActions();

  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'start',
        height: '100%',
      }}
    >
      <h1>{`${payload.action} ${payload.cloud} ${payload.domain}`}</h1>
      <pre>{JSON.stringify(payload, null, 2)}</pre>
      <Button
        onClick={() =>
          handleCoSellAction({ id: 'go-back-to-opportunity-root' })
        }
      >
        Back
      </Button>
    </div>
  );
};

export default OffersCanvasApp;
