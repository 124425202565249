import { Tag } from '@tackle-io/platform-ui';
import React from 'react';
import { useStatusTagV2Styles } from './StatusTag.styles';

interface UsageStatusTagProps {
  label?: string;
}

const getTextColor = (status?: string): string => {
  switch (status?.toLowerCase()) {
    case 'accepted':
      return 'standard';
    case 'rejected':
      return 'red';
    case 'pending':
      return 'standard';
    default:
      return 'standard';
  }
};

const formatLabel = (status?: string): string => {
  if (!status) return '';
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};

export const UsageStatusTag: React.FC<UsageStatusTagProps> = ({
  label: status,
}) => {
  const classes = useStatusTagV2Styles();
  const color = getTextColor(status);
  const tagClass =
    color === 'standard' ? classes.standardTag : classes.statusTag;

  return status ? (
    <Tag className={tagClass} color={color}>
      {formatLabel(status)}
    </Tag>
  ) : null;
};
