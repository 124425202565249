import classNames from 'classnames';
import useStyles from './AppLayout.styles';
import { useRouteMatch } from 'react-router-dom';
import { AlertBox } from 'mdi-material-ui';
import { Loader, Toaster } from '@tackle-io/platform-ui';
import { Suspense, useState } from 'react';
import { ApplicationShell, ErrorBoundary } from './components';
import OhNo from './pages/Error/Error';
import { useAuth } from 'vendor/auth0';
import { AppBar, useMediaQuery, useTheme, Toolbar } from './vendor/material';
import { AppFooter } from 'components/AppFooter/AppFooter';
import { GlobalHelp } from 'components/GlobalHelp/GlobalHelp';
import PageBannerDismissable from 'components/PageBannerDismissable/PageBannerDismissable';
import { useIsSandboxEnvironment } from 'hooks';

export const TEST_ENVIRONMENT_BANNER_TEXT =
  'This is a test environment. You are not in production, and nothing will be shared with the clouds. Feel free to run fast and test away!';

export const AppLayout = ({ children }) => {
  const hideAppShellForRoute = useRouteMatch([
    '/login',
    '/logout',
    '/auth/callback',
    '/listings/:marketplace/:listingId/preview',
    '/signup',
    '/salesforce-callback',
  ]);

  const hideToolbarForRoute = useRouteMatch([
    '/login',
    '/logout',
    '/auth/callback',
    '/listings/:marketplace/:listingId/preview',
    '/signup',
    '/salesforce-callback',
  ]);
  const classes = useStyles();
  const { isLoading, isAuthenticated } = useAuth();
  const [isSandboxBannerOpen, setIsSandboxBannerOpen] = useState(true);
  const isSandboxEnvironment = useIsSandboxEnvironment();
  const showBanner = isSandboxEnvironment && isSandboxBannerOpen;
  const theme = useTheme();
  const mediaDownXS = useMediaQuery(theme.breakpoints.down('xs'));
  const hideAppShell =
    !isAuthenticated || isLoading || Boolean(hideAppShellForRoute);
  const hideToolbar =
    mediaDownXS ||
    !isAuthenticated ||
    isLoading ||
    Boolean(hideToolbarForRoute);

  return (
    <ErrorBoundary renderError={OhNo}>
      <div className={classes.root}>
        <Toaster />
        {!hideAppShell && <ApplicationShell />}
        <main
          className={classNames(classes.content, {
            [classes.contentWithAppShell]: !hideAppShell,
          })}
        >
          {!hideToolbar && (
            <AppBar position="fixed" elevation={1} className={classes.header}>
              <Toolbar className={classes.headerBar}>
                <GlobalHelp />
              </Toolbar>
            </AppBar>
          )}
          <div style={{ flexGrow: 1 }}>
            {showBanner && (
              <PageBannerDismissable
                text={TEST_ENVIRONMENT_BANNER_TEXT}
                icon={
                  <AlertBox
                    role={'img'}
                    aria-hidden={false}
                    htmlColor={theme.palette.NEUTRAL700}
                  />
                }
                className={classes.pageBanner}
                bannerColor={theme.palette.YELLOW300}
                textColor={theme.palette.NEUTRAL700}
                onClose={() => setIsSandboxBannerOpen(false)}
              />
            )}
            <Suspense fallback={<Loader />}>{children}</Suspense>
          </div>
          <AppFooter />
        </main>
      </div>
    </ErrorBoundary>
  );
};
