import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem, {
  FULL_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { convertArrayToString } from '../helper';
import { OpportunityProject } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { ExpectedCustomerSpendDetails } from './ExpectedCustomerSpendDetails';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';

interface AceProjectDetailsProps {
  project: OpportunityProject;
  targetCloseDate: string;
  awsProducts: string[];
  solutions: string[];
}

const AceProjectDetails: React.FC<AceProjectDetailsProps> = ({
  project,
  targetCloseDate,
  awsProducts,
  solutions,
}) => {
  return (
    <DetailsSectionCard title="Project details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner project title"
          value={project?.title}
          gridSize={FULL_GRID_SIZE}
          dataId={coSellAceOpportunityFormFieldsDataId.PROJECT_TITLE}
        />
        <InfoItem
          gridSize={FULL_GRID_SIZE}
          itemTitle="Customer business problem"
          value={project?.customerBusinessProblem}
          dataId={
            coSellAceOpportunityFormFieldsDataId.CUSTOMER_BUSINESS_PROBLEM
          }
        />
        <InfoItem
          itemTitle="Solution offered"
          value={convertArrayToString(solutions)}
          dataId={coSellAceOpportunityFormFieldsDataId.SOLUTIONS_OFFERED}
        />
        {/* comment out AWS products until available in AceOpportunityFormFields.tsx */}
        {/* <InfoItem
          itemTitle="AWS products"
          value={convertArrayToString(awsProducts)}
          dataId={coSellAceOpportunityFormFieldsDataId.CITY}
        /> */}
        <InfoItem
          itemTitle="Use case"
          value={project?.customerUseCase}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_USE_CASE}
        />
        <InfoItem
          gridSize={FULL_GRID_SIZE} // TODO: Remove when we add AWS products
          itemTitle="Delivery model"
          value={convertArrayToString(project?.deliveryModels)}
          dataId={coSellAceOpportunityFormFieldsDataId.DELIVERY_MODEL}
        />
        <InfoItem
          itemTitle="APN programs"
          value={convertArrayToString(project?.apnPrograms)}
          dataId={coSellAceOpportunityFormFieldsDataId.APN_PROGRAMS}
          gridSize={FULL_GRID_SIZE}
        />
        <InfoItem
          gridSize={FULL_GRID_SIZE}
          itemTitle="Target close date"
          value={targetCloseDate}
          dataId={coSellAceOpportunityFormFieldsDataId.TARGET_CLOSE_DATE}
        />
        {!!project?.expectedCustomerSpend && (
          <ExpectedCustomerSpendDetails
            expectedCustomerSpend={project.expectedCustomerSpend}
          />
        )}
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceProjectDetails;
