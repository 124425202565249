import CanvasCreateCoSell from 'packages/salesforce-canvas/cosell/src/components/CanvasCreateCoSell';
import CanvasCoSellDetails from 'packages/salesforce-canvas/cosell/src/components/CanvasCoSellDetails';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';

const CoSellCanvasApp = () => {
  const { payload } = useCanvasSession();

  return (
    <>
      {payload.action === 'create' && <CanvasCreateCoSell />}
      {payload.action === 'edit' && <CanvasCoSellDetails />}
      {payload.action === 'view' && <CanvasCoSellDetails />}
    </>
  );
};

export default CoSellCanvasApp;
