import { Loader } from '@tackle-io/platform-ui';
import { Box } from 'vendor/material';
import { SalesforceCoSellWidgetButtonGroup } from 'packages/cosell/src/components/SalesforceCoSellWidget/SalesforceCoSellWidgetButtonGroup/SalesforceCoSellWidgetButtonGroup';
import { SalesforceCoSellWidgetDetailTable } from 'packages/cosell/src/components/SalesforceCoSellWidget/SalesforceCoSellWidgetDetailTable/SalesforceCoSellWidgetDetailTable';
import { AceCoSellErrorMessagesBanner } from 'packages/cosell/src/pages/AceCoSellErrorMessagesBanner';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';

/** displays a summary of the AWS ACE co-sell opportunity */
export default function AwsOpportunitySummary({
  opportunityId,
}: {
  opportunityId: string;
}) {
  const { aceOpportunityQuery } = useAceOpportunity({
    opportunityId,
  });
  if (aceOpportunityQuery.isLoading) return <Loader />;
  if (aceOpportunityQuery.isError) return <div>Error loading opportunity</div>;
  if (
    !aceOpportunityQuery.data ||
    !Object.keys(aceOpportunityQuery.data).length
  )
    return <div>No opportunity found</div>;

  const opportunity = aceOpportunityQuery.data;

  const tackleCoSellId = opportunity.metadata?.id;

  return (
    <Box style={{ display: 'grid', gap: '1rem', minHeight: '313px' }}>
      {!opportunity.metadata?.hasErrors && !!tackleCoSellId && (
        <AceCoSellErrorMessagesBanner
          defaultCollapsed
          opportunityId={tackleCoSellId}
        />
      )}
      <SalesforceCoSellWidgetDetailTable
        opportunity={{
          id: tackleCoSellId,
          cloudProviderId: opportunity.id,
          created: opportunity.createdDate,
          title: opportunity.project?.title,
          source: opportunity.source,
          crmId: opportunity.crmId,
          lastModified: opportunity.lastModifiedDate,
          targetCloseDate: opportunity.lifeCycle?.targetCloseDate,
          companyName: opportunity.customer.account.companyName,
          expectedCustomerSpend: opportunity.project.expectedCustomerSpend,
          opportunityType: opportunity.opportunityType,
          hasErrors: opportunity.metadata?.hasErrors,
          stage: opportunity.lifeCycle?.stage,
          status: opportunity.lifeCycle?.reviewStatus,
        }}
      />
      <SalesforceCoSellWidgetButtonGroup tackleCoSellId={tackleCoSellId} />
    </Box>
  );
}
