import {
  AceInvitationCloudStatusEnum,
  TagColorEnum,
} from 'packages/cosell/src/types/enums';
import { Tag } from 'pages/CoSell/components/Tag';

const statusToColorMapping: {
  [key in AceInvitationCloudStatusEnum]: TagColorEnum;
} = {
  [AceInvitationCloudStatusEnum.ACCEPTED]: TagColorEnum.GREEN,
  [AceInvitationCloudStatusEnum.REJECTED]: TagColorEnum.RED,
  [AceInvitationCloudStatusEnum.PENDING]: TagColorEnum.STANDARD,
};

interface AwsInvitationStatusTagProps {
  status: AceInvitationCloudStatusEnum;
}

export const aceInvitationLabelsOverrideMap: {
  [key in AceInvitationCloudStatusEnum]: string;
} = {
  [AceInvitationCloudStatusEnum.PENDING]: 'Pending',
  [AceInvitationCloudStatusEnum.ACCEPTED]: 'Accepted',
  [AceInvitationCloudStatusEnum.REJECTED]: 'Rejected',
};
export const AwsInvitationStatusTag = (props: AwsInvitationStatusTagProps) => {
  return (
    <Tag color={statusToColorMapping[props.status]}>
      {aceInvitationLabelsOverrideMap[props.status] || props.status}
    </Tag>
  );
};
