import { Check } from 'mdi-material-ui';
import { Box } from 'vendor/material';

const TackleManaged = () => {
  return (
    <Box display="flex" gridGap="4px" alignItems="center">
      <Check fontSize="inherit" />
      <span>Tackle managed</span>
    </Box>
  );
};

export default TackleManaged;
