// Format should be <domain>_<product>_<optional-component-descriptor>_<component-name>
// Each component should have a unique data-id and not be re-used so that we can track properly
export enum DashboardDataId {
  DASHBOARD_PROSPECT_ZERO_STATE_CARD = 'dashboard_prospect_zero_state_card',
  DASHBOARD_PROSPECT_CARD = 'dashboard_prospect_card',
  DASHBOARD_MARKETPLACE_REVENUE_GRID = 'dashboard_marketplace_revenue_grid',
  DASHBOARD_BOOKED_REVENUE_CARD = 'dashboard_booked_revenue_card',
  DASHBOARD_BOOKED_REVENUE_ZERO_STATE_CARD = 'dashboard_booked_revenue_zero_state_card',
  DASHBOARD_DISBURSED_REVENUE_CARD = 'dashboard_disbursed_revenue_card',
  DASHBOARD_DISBURSED_REVENUE_ZERO_STATE_CARD = 'dashboard_disbursed_revenue_zero_state_card',
  DASHBOARD_COSELL_ZERO_STATE_CARD = 'dashboard_cosell_zero_state_card',
  DASHBOARD_COSELL_CARD = 'dashboard_cosell_card',
  DASHBOARD_COSELL_PIPELINE_CARD = 'dashboard_cosell_pipeline_card',
  DASHBOARD_COSELL_PIPELINE_ZERO_STATE_CARD = 'dashboard_cosell_pipeline_zero_state_card',
  DASHBOARD_COSELL_WIN_RATE_CARD = 'dashboard_cosell_win_rate_card',
  DASHBOARD_COSELL_WIN_RATE_ZERO_STATE_CARD = 'dashboard_cosell_win_rate_zero_state_card',
  DASHBOARD_COSELL_MARKETPLACE_REVENUE_CARD = 'dashboard_cosell_marketplace_revenue_card',
  DASHBOARD_COSELL_MARKETPLACE_REVENUE_ZERO_STATE_CARD = 'dashboard_cosell_marketplace_revenue_zero_state_card',
  DASHBOARD_COSELL_GET_STARTED_CARD = 'dashboard_cosell_get_started_card',
  DASHBOARD_COSELL_ZERO_STATE_CONTENT = 'dashboard_cosell_zero_state_content',
  DASHBOARD_OFFERS_IN_PROGRESS_CONTENT = 'dashboard_offers_in_progress_content',
  DASHBOARD_OFFERS_ZERO_STATE_CONTENT = 'dashboard_offers_zero_state_content',
  DASHBOARD_OFFERS_GET_STARTED_ERROR_CARD = 'dashboard_offers_get_started_error_card',
}

export enum OffersDataId {
  PRIVATE_OFFERS_NEW_OFFER_STEP_SETUP_OFFER = 'private_offers_new_offer_step_setup_offer',
  PRIVATE_OFFERS_NEW_OFFER_STEP_ASSOCIATE_OFFER = 'private_offers_new_offer_step_associate_offer',
  PRIVATE_OFFERS_NEW_OFFER_STEP_SEND_PURCHASE_INSTRUCTIONS = 'private_offers_new_offer_step_send_purchase_instructions',
  PRIVATE_OFFERS_NEW_OFFER_STEP_BUYER_OPENED_INSTRUCTIONS = 'private_offers_new_offer_step_buyer_opened_instructions',
  PRIVATE_OFFERS_NEW_OFFER_STEP_BUYER_WAITING_OPENED_INSTRUCTIONS = 'private_offers_new_offer_step_buyer_waiting_opened_instructions',
  PRIVATE_OFFERS_NEW_OFFER_STEP_BUYER_VIEWED_OFFER = 'private_offers_new_offer_step_buyer_viewed_offer',
  PRIVATE_OFFERS_NEW_OFFER_STEP_BUYER_WAITING_VIEWED_OFFER = 'private_offers_new_offer_step_buyer_waiting_viewed_offer',
  PRIVATE_OFFERS_NEW_OFFER_STEP_BUYER_ACCEPTED_OFFER = 'private_offers_new_offer_step_buyer_accepted_offer',
  PRIVATE_OFFERS_NEW_OFFER_STEP_BUYER_WAITING_ACCEPTED_OFFER = 'private_offers_new_offer_step_buyer_waiting_accepted_offer',
  PRIVATE_OFFERS_NEW_OFFER_AUTO_STEP_CREATED_IN_TACKLE = 'private_offers_new_offer_auto_step_offer_created_in_tackle',
  PRIVATE_OFFERS_NEW_OFFER_AUTO_STEP_CREATED_IN_MARKETPLACE = 'private_offers_new_offer_auto_step_created_in_marketplace',
  PRIVATE_OFFERS_NEW_OFFER_AUTO_STEP_SEND_PURCHASE_INSTRUCTIONS = 'private_offers_new_offer_auto_step_send_purchase_instructions',
  PRIVATE_OFFERS_NEW_OFFER_AUTO_STEP_BUYER_OPENED_INSTRUCTIONS = 'private_offers_new_offer_auto_step_buyer_opened_instructions',
  PRIVATE_OFFERS_NEW_OFFER_AUTO_STEP_BUYER_VIEWED_OFFER = 'private_offers_new_offer_auto_step_buyer_viewed_offer',
  PRIVATE_OFFERS_NEW_OFFER_AUTO_STEP_BUYER_ACCEPTED_OFFER = 'private_offers_new_offer_auto_step_buyer_accepted_offer',
  PRIVATE_OFFERS_NEW_OFFER_COMPANY_NAME_CARD = 'private_offers_new_offer_company_card',
  PRIVATE_OFFERS_NEW_OFFER_COMPANY_NAME_FIELD_ROW = 'private_offers_new_offer_company_name_field_row',
  PRIVATE_OFFERS_NEW_OFFER_BUYER_DETAILS_CARD = 'private_offers_new_offer_buyer_details_card',
  PRIVATE_OFFERS_NEW_OFFER_BUYER_DETAILS_FIELDS_ROW = 'private_offers_new_offer_buyer_details_fields_row',
  PRIVATE_OFFERS_NEW_OFFER_BUYER_DETAILS_ADD_BUYER = 'private_offers_new_offer_buyer_details_add_buyer',
  PRIVATE_OFFERS_NEW_OFFER_SELECT_A_LISTING_CARD = 'private_offers_new_offer_select_a_listing_card',
  PRIVATE_OFFERS_NEW_OFFER_SELECT_A_LISTING_SEARCH_AND_LIST = 'private_offers_new_offer_select_a_listing_search_and_list',
  PRIVATE_OFFERS_NEW_OFFER_LISTING_SEARCH_FIELD = 'private_offers_new_offer_listing_search_field',
  PRIVATE_OFFERS_NEW_OFFER_EULA_CARD = 'private_offers_new_offer_eula_card',
  PRIVATE_OFFERS_NEW_OFFER_RESELLER_AGREEMENT_CARD = 'private_offers_new_offer_reseller_agreement_card',
  PRIVATE_OFFERS_NEW_OFFER_CONTRACT_METADATA_CARD = 'private_offers_new_offer_contract_metadata_card',
  PRIVATE_OFFERS_NEW_OFFER_CONTRACT_METADATA_FIELDS_ROW = 'private_offers_new_offer_contract_metadata_fields_row',
  PRIVATE_OFFERS_NEW_OFFER_OFFER_ID_CARD = 'private_offers_new_offer_offer_id_card',
  PRIVATE_OFFERS_NEW_OFFER_OFFER_ID_FIELD = 'private_offers_new_offer_offer_id_field',
  PRIVATE_OFFERS_NEW_OFFER_NOTIFY_USERS_CARD = 'private_offers_new_offer_notify_users_card',
  PRIVATE_OFFERS_NEW_OFFER_NOTIFY_USERS_FILTER_FIELD_AND_LIST = 'private_offers_new_offer_notify_users_filter_field_and_list',
  PRIVATE_OFFERS_NEW_OFFER_NOTIFY_USERS_ADD_EMAIL = 'private_offers_new_offer_notify_users_add_email',
  PRIVATE_OFFERS_NEW_OFFER_PRICING_PAYMENT_SCHEDULE_DATE = 'private_offers_new_offer_pricing_payment_schedule_date',
  PRIVATE_OFFERS_NEW_OFFER_EULA_RADIO_GROUP = 'private_offers_new_offer_eula_radio_group',
  PRIVATE_OFFERS_NEW_OFFER_REGISTRATION_DETAILS_CARD = 'private_offers_new_offer_registration_details_card',
  PRIVATE_OFFERS_PAYMENT_SCHEDULE_FORM_SECTION = 'private_offers_payment_schedule_form_section',
  PRIVATE_OFFERS_SELECTED_LISTING_DISPLAY = 'private_offers_selected_listing_display',
  PRIVATE_OFFERS_AZURE_PLAN_SELECT = 'private_offers_azure_plan_select',
  PRIVATE_OFFERS_AZURE_PLAN_NAME_FIELD = 'private_offers_azure_plan_name_field',
  PRIVATE_OFFERS_AZURE_PLAN_DESCRIPTION_FIELD = 'private_offers_azure_plan_description_field',
  PRIVATE_OFFERS_AZURE_PLAN_BILLING_TERM_SELECT = 'private_offers_azure_plan_billing_term_select',
  PRIVATE_OFFERS_AZURE_PLAN_PAYMENT_MODEL_SELECT = 'private_offers_azure_plan_payment_model_select',
  PRIVATE_OFFERS_AZURE_PRICE_PER_PAYMENT_FIELD = 'private_offers_azure_plan_price_per_payment_field',
  PRIVATE_OFFERS_MARKETPLACE_FEE_FIELD = 'private_offers_marketplace_fee_field',
  PRIVATE_OFFERS_CURRENCY_FIELD = 'private_offers_currency_field',
}

export enum ProspectDataId {
  PROSPECT_SCORES_ACCOUNT_TABLE_ACCOUNT_ROW = 'prospect_scores_account_table_account_row',
  PROSPECT_SCORES_ACCOUNT_DETAILS = 'prospect_scores_account_details',
  PROSPECT_SCORES_FILTER_BUTTON = 'prospect_scores_filter_button',
  PROSPECT_SCORES_FILTER_PANEL = 'prospect_scores_filter_panel',
  PROSPECT_SCORES_FILTER_TAGS = 'prospect_scores_filter_tags',
}

export enum ListingDataId {
  LISTING_MANAGEMENT_FILTER_BUTTON = 'listing_management_filter_button',
  LISTING_MANAGEMENT_FILTER_PANEL = 'listing_management_filter_panel',
  LISTING_MANAGEMENT_FILTER_TAGS = 'listing_management_filter_tags',
}

export enum AccountSettingsDataId {
  SUBSCRIPTION_TAB = 'account_settings_subscription_tab',
  PREFERENCES_TAB = 'account_settings_preferences_tab',
  MARKETPLACE_TAB = 'account_settings_marketplace_tab',
  COSELL_TAB = 'account_settings_cosell_tab',
  M2MAPPLICATION_TAB = 'account_settings_m2mapplication_tab',
  COSELL_AWS_LIST_TITLE = 'account_settings_cosell_aws_list_title',
  COSELL_AWS_LIST_CARD_TITLE = 'account_settings_cosell_aws_card_title',
  COSELL_AWS_DETAIL_TITLE = 'account_settings_cosell_aws_detail_title',
  COSELL_MICROSOFT_DETAIL_TITLE = 'account_settings_cosell_microsoft_detail_title',
  COSELL_MICROSOFT_LIST_TITLE = 'account_settings_cosell_microsoft_list_title',
  COSELL_MICROSOFT_LIST_CARD_TITLE = 'account_settings_cosell_microsoft_list_card_title',
  COSELL_GCP_DETAIL_TITLE = 'account_settings_cosell_gcp_detail_title',
  MARKETPLACE_AWS_LIST_TITLE = 'account_settings_marketplace_aws_list_title',
  MARKETPLACE_MICROSOFT_LIST_TITLE = 'account_settings_marketplace_microsoft_list_title',
  MARKETPLACE_GCP_LIST_TITLE = 'account_settings_marketplace_gcp_list_title',
  MARKETPLACE_AWS_DETAIL_TITLE = 'account_settings_marketplace_aws_detail_title',
  MARKETPLACE_MICROSOFT_DETAIL_TITLE = 'account_settings_marketplace_microsoft_detail_title',
  MARKETPLACE_GCP_DETAIL_TITLE = 'account_settings_marketplace_gcp_detail_title',
}

export enum PaymentsDataId {
  PAYMENTS_DASHBOARD_FILTER_BUTTON = 'payments_dashboard_filter_button',
  PAYMENTS_DASHBOARD_FILTER_TAGS = 'payments_dashboard_filter_tags',
  PAYMENTS_DASHBOARD_FILTER_PANEL = 'payments_dashboard_filter_panel',
  PAYMENTS_DASHBOARD_FISCAL_YEAR = 'payments_dashboard_fiscal_year',
  PAYMENTS_TABLE_TAB_PAGINATION = 'payments_table_tab_pagination',
  PAYMENTS_TABLE_HEADER_ROW = 'payments_table_header_row',
  PAYMENTS_SEARCH = 'payments_search',
  PAYMENTS_DETAILS_PANEL = 'payments_details_panel',
  PAYMENTS_HEADER_TOOLS = 'payments_header_tools',
  PAYMENTS_LEGACY_BANNER = 'payments_legacy_banner',
  PAYMENTS_METRICS = 'payments_metrics',
  PAYMENTS_GRID = 'payments_grid',
  PAYMENTS_GROUP_HEADER_TURNER = 'payments_group_header_turner',
  PAYMENTS_GROUP_BY_DISBURSEMENT_DATE = 'payments_groupby_disbursementdate',
  PAYMENTS_GROUP_BY_EXPAND_ALL = 'payments_groupby_expandall',
}

export enum ContractsDataId {
  CONTRACTS_DASHBOARD_FILTER_BUTTON = 'contracts_dashboard_filter_button',
  CONTRACTS_DASHBOARD_FILTER_TAGS = 'contracts_dashboard_filter_tags',
  CONTRACTS_DASHBOARD_FILTER_PANEL = 'contracts_dashboard_filter_panel',
  CONTRACTS_DASHBOARD_FISCAL_YEAR = 'contracts_dashboard_fiscal_year',
  CONTRACTS_TABLE_TAB_PAGINATION = 'contracts_table_tab_pagination',
  CONTRACTS_TABLE_HEADER_ROW = 'contracts_table_header_row',
  CONTRACTS_SEARCH = 'contracts_search',
  CONTRACTS_DETAILS_PANEL = 'contracts_details_panel',
  CONTRACTS_HEADER_TOOLS = 'contracts_header_tools',
  CONTRACTS_LEGACY_BANNER = 'contracts_legacy_banner',
  CONTRACTS_METRICS = 'contracts_metrics',
  CONTRACTS_GRID = 'contracts_grid',
  CONTRACTS_GROUP_HEADER_TURNER = 'contracts_group_header_turner',
  CONTRACTS_GROUP_BY_DISBURSEMENT_DATE = 'contracts_groupby_disbursementdate',
  CONTRACTS_GROUP_BY_EXPAND_ALL = 'contracts_groupby_expandall',
  CONTRACTS_FILTER_BUTTON = 'contracts_filter_button',
  CONTRACT_DETAIL_ACTION_BUTTONS = 'contract_detail_action_buttons',
  CONTRACT_DETAIL_TABS = 'contract_detail_tabs',
}
