import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import { DisplayCloudType } from 'pages/CoSell/types/enums';
import { useCallback } from 'react';

/** @deprecated will be removing this */
export const COSELL_ACTION_MAP = {
  'go-back-to-opportunity': {
    label: 'cancel',
  },
  'edit-cosell': {
    label: 'Edit co-sell',
  },
  // Co-Sell details will be rendered in a canvas component
  'view-cosell': {
    label: 'View co-sell',
  },
  'submit-cosell-to-cloud': {
    label: 'Submit to cloud',
  },
  'create-cosell-aws': {
    label: 'Add AWS co-sell',
  },
  'create-cosell-microsoft': {
    label: 'Add Microsoft co-sell',
  },
} as const;

/** available actions and their payloads */
type CoSellAction =
  | {
      id: 'go-back-to-opportunity-root';
    }
  | {
      id: 'create-cosell';
      cloud: DisplayCloudType;
    }
  | {
      id: 'view-cosell';
      cloud: DisplayCloudType;
      tackleOpportunityId: string;
    }
  | {
      id: 'edit-cosell';
      cloud: DisplayCloudType;
      tackleOpportunityId: string;
    }
  | {
      id: 'submit-cosell-to-cloud';
      cloud: DisplayCloudType;
      tackleOpportunityId: string;
    };

const useCoSellSfCanvasActions = () => {
  const { canvasClient, namespace, record } = useCanvasSession();

  /** just pass a payload to publish a message */
  const publishCanvasMessage = useCallback(
    (data: Record<string, any>) => {
      /** TODO: remove before merge
       *  - add dynamic domain
       */
      console.info('publishing canvas message 2.0', {
        name: `${namespace}.CoSell`,
        payload: data,
      });
      Sfdc.canvas.client.publish(canvasClient, {
        name: `${namespace}.cosell`,
        payload: data,
      });
    },
    [canvasClient, namespace],
  );

  /**
   * note: will be adding payload options to view and edit opportunity
   */
  const handleCoSellAction = (action: CoSellAction) => {
    const domain = 'cosell';
    switch (action.id) {
      case 'go-back-to-opportunity-root':
        publishCanvasMessage({
          navigate: 'TackleWidget',
          record,
          domain,
        });
        break;
      case 'create-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          record,
          action: 'create',
          cloud: action.cloud,
          domain,
        });
        break;
      case 'view-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          record,
          action: 'view',
          cloud: action.cloud,
          domain,
          tackleOpportunityId: action.tackleOpportunityId,
        });
        break;
      case 'edit-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          record,
          action: 'edit',
          cloud: action.cloud,
          domain,
          tackleOpportunityId: action.tackleOpportunityId,
        });
        break;
      default:
        alert(`TODO: handle action: ${action.id}`);
        break;
    }
  };

  return {
    handleCoSellAction,
  };
};

export default useCoSellSfCanvasActions;
