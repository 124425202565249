import { PrivateOfferV2 } from 'stores/privateOffers/PrivateOfferV2';
import { Box, Divider, Grid, makeStyles } from 'vendor/material';
import AWSOfferListViewItem from './AWSOfferListViewItem';
import StartAWSOffer from './StartAWSOffer';
import { useState } from 'react';
import { Button } from '@tackle-io/platform-ui';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  contents: {
    overflow: 'auto',
  },
}));

const AWSOffersForOpportunity = ({
  awsOffers,
}: {
  awsOffers: PrivateOfferV2[];
}) => {
  const classes = useStyles();

  const [startingNewAWSOffer, setStartingNewAWSOffer] = useState(false);
  if (startingNewAWSOffer) {
    return <StartAWSOffer onCancel={() => setStartingNewAWSOffer(false)} />;
  }

  return (
    <Box height="100%">
      <Grid
        container
        direction="column"
        className={classes.container}
        spacing={2}
      >
        <Grid item xs className={classes.contents}>
          <Grid container direction="column" wrap="nowrap" spacing={2}>
            {awsOffers.map((awsOffer) => {
              return (
                <Grid item key={awsOffer.po_id}>
                  <AWSOfferListViewItem awsOffer={awsOffer} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            appearance="primary"
            onClick={() => setStartingNewAWSOffer(true)}
          >
            Add AWS offer
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AWSOffersForOpportunity;
