import React, { SetStateAction, Dispatch } from 'react';
import { Box } from 'vendor/material';
import { EditModal, ModalHeader } from 'packages/cosell/src/components';
import AceOpportunityFormFields from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormFields';
import { Formik } from 'formik';
import { convertOpportunityResponseToAceFormValues } from '../../utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { aceOpportunityFormValidationSchema } from '../UnifiedOpportunityForm/AceOpportunityForm/aceOpportunityFormValidationSchema';
import { UpdateAceOpportunityFormValues } from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  getAwsMarketplaceOffers,
  getSoftwareRevenue,
} from '../../utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import { ampli } from 'utils/analytics/ampli';
import { getEditModalTackleOperationId } from 'packages/cosell/api/utils';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { EditModalType } from './RightRailButtonSection';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import AceOpportunityDetailsHeader from '../../pages/UnifiedOpportunityDetails/AceOpportunityDetails/detailSections/AceOpportunityDetailsHeader';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';
import { LaunchAceOpportunityRequest } from '../../types/requests/AceOpportunityRequest';
import { removeUndefinedOrEmptyObjectProperties } from '../../utilities/typeConverters/utils';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';

interface RightRailEditModalProps {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
}

const renderModal = ({
  editModalType,
  setEditModalOpen,
  opportunity,
  onSubmit,
}: {
  editModalType: EditModalType;
  opportunity: AceOpportunityResponse;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
  onSubmit: (values: UpdateAceOpportunityFormValues) => Promise<void>;
}) => {
  /**
   Use a type assertion to convert UnifiedOpportunityFormValues 
   to UpdateAceOpportunityFormValues
   */
  const handleSubmit = (
    values: UnifiedOpportunityFormValues,
  ): Promise<void> => {
    return onSubmit(values as UpdateAceOpportunityFormValues);
  };

  switch (editModalType) {
    case EditModalType.EDIT_LAUNCHED:
      return (
        <EditModal
          open={true}
          onClose={() => setEditModalOpen(null)}
          onSubmit={handleSubmit}
          title="Edit launched co-sell opportunity"
        >
          <div>
            <Box mt={2} mb={3}>
              <AceOpportunityDetailsHeader
                title={opportunity.customer?.account?.companyName}
                source={opportunity.source}
                lifeCycle={opportunity.lifeCycle}
              />
            </Box>
            <AceOpportunityFormFields editModalType={editModalType} />
          </div>
        </EditModal>
      );
    case EditModalType.LAUNCH:
      return (
        <EditModal
          open={true}
          onClose={() => setEditModalOpen(null)}
          onSubmit={handleSubmit}
          submitLabel="Launch"
          title={
            <ModalHeader
              title="Launch Co-Sell"
              cloud={DisplayCloudType.AWS}
              subtitle="Confirm the following information before you launch."
            />
          }
        >
          <AceOpportunityFormFields editModalType={editModalType} />
        </EditModal>
      );
    case EditModalType.CLOSE_LOST:
      return (
        <EditModal
          open={true}
          onClose={() => setEditModalOpen(null)}
          onSubmit={handleSubmit}
          submitLabel="Close lost"
          title={
            <ModalHeader
              title="Close lost Co-Sell"
              cloud={DisplayCloudType.AWS}
            />
          }
        >
          <AceOpportunityFormFields editModalType={editModalType} />
        </EditModal>
      );
    default:
      return null;
  }
};

const RightRailEditModal: React.FC<RightRailEditModalProps> = ({
  editModalType,
  setEditModalOpen,
}: {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
}) => {
  const { opportunityId } = useCoSellContext();
  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });

  const opportunity = aceOpportunityQuery?.data;

  const handleEditOpportunity = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    const launchRequest: LaunchAceOpportunityRequest = {
      lifeCycle: {
        targetCloseDate: values.targetCloseDate,
      },
      softwareRevenue: getSoftwareRevenue(values),
      solutions: values.solutions,
      awsMarketplaceOffers: getAwsMarketplaceOffers(values),
    };

    const trimmedLaunchRequest =
      removeUndefinedOrEmptyObjectProperties(launchRequest);

    const tackleOperationId = getEditModalTackleOperationId(editModalType);

    updateAceOpportunity.mutateAsync({
      requestBody: trimmedLaunchRequest,
      tackleOperationId,
    });
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    try {
      await handleEditOpportunity(values);
      ampli.outboundSubmitted({ cloud: 'aws' });
      setEditModalOpen(null);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={convertOpportunityResponseToAceFormValues(opportunity)}
      validationSchema={aceOpportunityFormValidationSchema}
      onSubmit={() => {
        // noOp because we use the button outside of the form to submit
        // noop
      }}
    >
      {renderModal({
        editModalType,
        setEditModalOpen,
        opportunity,
        onSubmit: handleSubmitAceOpportunityToCloud,
      })}
    </Formik>
  );
};

export default RightRailEditModal;
