import useStyles from './CollapsibleListItem.styles';
import { useCallback, useState } from 'react';
import { ChevronDown } from 'mdi-material-ui';
import { Box, Collapse, Typography } from 'vendor/material';

export default function CollapsibleListItem({
  children,
  defaultOpen,
  title,
  isCollapsible,
}: {
  children: React.ReactNode;
  defaultOpen?: boolean;
  isCollapsible?: boolean;
  title: React.ReactNode | string;
}) {
  const { chevronOpen, container, toggleButton } = useStyles();
  const [isOpen, setIsOpen] = useState(defaultOpen || !isCollapsible);
  const toggleOpenStatus = useCallback(() => {
    setIsOpen((current) => !current);
  }, []);

  return (
    <div className={container}>
      <Box
        className={toggleButton}
        onClick={isCollapsible ? toggleOpenStatus : null}
        role="button"
      >
        {isCollapsible && (
          <ChevronDown
            className={!isOpen ? chevronOpen : null}
            fontSize="small"
          />
        )}
        <Typography
          style={{ marginLeft: isCollapsible ? 0 : '0.5rem' }}
          variant="inherit"
          component="span"
        >
          {title}
        </Typography>
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
}
