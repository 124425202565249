export const FEATURE_VENDOR_GCP_CO_SELL_ENABLED =
  'feature-vendor-gcp-co-sell-enabled';

export const FEATURE_CO_SELL_V3_UI_ENABLED = 'feature-co-sell-v3-ui-enabled';
export const FEATURE_FLAGS_CO_SELL_V3_UI = {
  TOGGLE_CORE_ENABLED: FEATURE_CO_SELL_V3_UI_ENABLED,
  /** a feature flag which manages multiple domains and features allowing us to incrementally enable features for vendors:
   *  - Opportunities: read / create / edit / submit / close
   *  - Invitations: read / close
   */
  AWS_FEATURES_ENABLED: 'co-sell-v3-aws-ui-enabled',
} as const;
