import React from 'react';
import { Grid } from 'vendor/material';
import InfoItem, {
  FULL_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';
import { PersonIcon } from 'packages/cosell/assets';
import { Contact } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
interface AceContactDetailsProps {
  contact: Contact;
}

const AceContactDetails: React.FC<AceContactDetailsProps> = ({ contact }) => {
  return (
    <DetailsSectionCard
      title="Contact details"
      icon={<PersonIcon />}
      subTitle="Provide the details of the end customer associated with the opportunity."
    >
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Customer first name"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_FIRST_NAME}
          value={contact?.firstName}
        />

        <InfoItem
          itemTitle="Customer last name"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_LAST_NAME}
          value={contact?.lastName}
        />

        <InfoItem
          itemTitle="Customer title"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_TITLE}
          value={contact?.businessTitle}
        />

        <InfoItem
          itemTitle="Customer email"
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_EMAIL}
          value={contact?.email}
        />
        <InfoItem
          itemTitle="Customer phone"
          value={contact?.phone}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_PHONE}
          gridSize={FULL_GRID_SIZE}
        />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceContactDetails;
