import { Loader } from '@tackle-io/platform-ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import isOpportunityBeingCreatedCheck from 'packages/cosell/api/utilities/IsOpportunityBeingCreatedCheck/isOpportunityBeingCreatedCheck';
import {
  AceOpportunityResponse,
  IncompleteAceOpportunityResponse,
} from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { useEffect } from 'react';
import { ACE_OPPORTUNITY_QUERY_KEY } from 'packages/cosell/api/hooks/useAceOpportunity';

/** Handles the loading state for an Ace co-sell opportunity that is pending in the creation process.
 *  upon successful creation:
 *   - the opportunity is preloaded into the ACE_OPPORTUNITY_QUERY_KEY query
 *   - the onSuccess callback is called with the opportunity id
 *
 *  upon error, the onError callback is called.
 */
export function CreationPending<T extends string>({
  onSuccess,
  onError,
  pendingAceTackleId,
}: {
  pendingAceTackleId: T;
  onSuccess: (id: T) => void;
  onError: (errorData: { error: unknown; aceError: boolean }) => void;
}) {
  const queryClient = useQueryClient();

  /**
   *  This query is specifically for checking the creation status of an opportunity.
   *  It will refetch every 5 seconds if the opportunity is being created.
   */
  const { data: pendingAceOpportunityCreation, error } = useQuery({
    queryKey: [pendingAceTackleId, 'creation-pending-ace-cosell-opportunity'],
    queryFn: () => coSellClient.getOpportunityByIdV3(pendingAceTackleId!),
    select: (data) => {
      if (!data) return;

      if (isOpportunityBeingCreatedCheck({ data })) {
        return {
          status: 'pendingCreation',
        };
      }

      if (data.metadata?.hasErrors === true) {
        return {
          status: 'hasAceErrors',
          opportunity: data as IncompleteAceOpportunityResponse,
        };
      }

      /** if an id (cloudId) is returned on the opportunity, the opportunity has been created */
      if ('id' in data) {
        return {
          status: 'success',
          opportunity: data as AceOpportunityResponse,
        };
      }
    },
    refetchInterval: (data) => {
      return data?.status === 'pendingCreation' ? 5000 : false;
    },
  });

  useEffect(() => {
    if (
      !pendingAceOpportunityCreation ||
      pendingAceOpportunityCreation.status === 'pendingCreation'
    ) {
      return;
    }

    if (pendingAceOpportunityCreation.status === 'hasAceErrors') {
      onError({
        error: {},
        aceError: true,
      });
      return;
    }
    if (pendingAceOpportunityCreation.status === 'success') {
      queryClient.setQueryData(
        [ACE_OPPORTUNITY_QUERY_KEY, pendingAceTackleId],
        pendingAceOpportunityCreation.opportunity,
      );
      onSuccess(pendingAceTackleId);
    }
  }, [
    pendingAceOpportunityCreation,
    queryClient,
    pendingAceTackleId,
    onError,
    onSuccess,
  ]);

  useEffect(() => {
    if (error) {
      onError({ error, aceError: false });
    }
  }, [error, onError]);

  return <Loader />;
}
