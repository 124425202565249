import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from 'vendor/material';
import { InvitationsTableColumnNames } from '../../utils';
import useStyles from './AceInvitationRow.styles';
import { TableRowCell } from '../TableRowCell';
import classNames from 'classnames';
import type { ColumnWidths, AceInvitation } from '../../types';
import { SyncStatus } from '../SyncStatus/SyncStatus';
import { getSyncStatus } from 'packages/cosell/src/components/CoSellSyncStatusTag/CoSellSyncStatusTag';
import ToolTipCopy from 'packages/cosell/src/components/ToolTipCopy/ToolTipCopy';
import { formatDate } from 'packages/cosell/src/utilities/formatDate';
import { COSELL_PATH } from 'packages/cosell/src/utilities/constants';
import { AwsInvitationStatusTag } from '../AwsInvitationStatusTag';

interface AceInvitationRowProps {
  invitation: AceInvitation;
  columnWidths: ColumnWidths;
  dataId?: string;
  style?: React.CSSProperties;
}

export const AceInvitationRow: React.FC<AceInvitationRowProps> = ({
  invitation,
  columnWidths,
  dataId,
  style,
}) => {
  const invitationId = invitation.id;
  const classes = useStyles();
  const history = useHistory();

  const handleRowClick = () => {
    history.push(`${COSELL_PATH}/invitation/aws/${invitation.id}`);
  };

  if (columnWidths === undefined) return null;
  return (
    <div
      className={classNames(classes.tableRow)}
      role="row"
      aria-label={`Invitation ID ${invitationId}`}
      data-id={dataId}
      onClick={handleRowClick}
      style={style}
    >
      <TableRowCell
        key={`${invitationId}--disbursement_date`}
        columnName={InvitationsTableColumnNames.PROJECT_NAME}
        columnWidths={columnWidths}
      >
        <>
          <Grid item>
            <Typography variant="body1">
              <ToolTipCopy
                content={invitation.title}
                name="Project name"
                hideNameInTooltip={true}
                hideName={true}
                maxWidth={310}
              />
            </Typography>
          </Grid>
          <Grid className={classNames(classes['mt-0.5'], classes.flex)}>
            <span
              className={classNames(classes.flex, classes.smallLabel)}
              style={{ marginRight: '8px' }}
            >
              <ToolTipCopy
                content={invitation.cloudProviderId}
                name="Cloud ID"
                hideNameInTooltip={true}
              />
            </span>
          </Grid>
        </>
      </TableRowCell>
      <TableRowCell
        key={`${invitationId}--customer`}
        columnName={InvitationsTableColumnNames.CUSTOMER}
        columnWidths={columnWidths}
      >
        <>
          <ToolTipCopy
            content={invitation.companyName}
            name="Customer name"
            hideNameInTooltip={true}
            hideName={true}
          />
        </>
      </TableRowCell>
      <TableRowCell
        key={`${invitationId}--cosell_status`}
        columnName={InvitationsTableColumnNames.INVITATION_STATUS}
        columnWidths={columnWidths}
      >
        <AwsInvitationStatusTag status={invitation.cloudStatus} />
      </TableRowCell>

      <TableRowCell
        key={`${invitationId}--cosell_stage`}
        columnName={InvitationsTableColumnNames.DATE_RECEIVED}
        columnWidths={columnWidths}
      >
        {formatDate(invitation.cloudCreated)}
      </TableRowCell>
      <TableRowCell
        key={`${invitationId}--expiration_date`}
        columnName={InvitationsTableColumnNames.EXPIRATION_DATE}
        columnWidths={columnWidths}
      >
        <Typography variant="body1">
          {formatDate(invitation.expirationDate)}
        </Typography>
      </TableRowCell>
      <TableRowCell
        key={`${invitationId}--sync_status`}
        columnName={InvitationsTableColumnNames.SYNC_STATUS}
        columnWidths={columnWidths}
      >
        <SyncStatus
          syncStatus={getSyncStatus({
            pendingRequestIds: invitation.pendingRequestIds,
            hasError: invitation.hasError,
          })}
        />
      </TableRowCell>
    </div>
  );
};

export default AceInvitationRow;
