import React from 'react';
import { Sync } from 'mdi-material-ui';
import { Button } from '@tackle-io/platform-ui';
// import { useAwsOpportunityActions } from '../../pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import { useAwsOpportunitySync } from '../../pages/UnifiedOpportunityForm/providers/useAwsOpportunitySync';
import { toDateTime } from 'utils/dates';
import { makeStyles } from 'vendor/material';
import { fontWeightRegular } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),
  },
  buttonText: {
    color: theme.palette.BLUE400,
    fontSize: 'inherit',
    fontWeight: fontWeightRegular,
    textTransform: 'none',
  },
  icon: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

interface AwsCoSellSyncStatusProps {
  lastSyncDate: string;
  opportunityId: string;
}

/** WIP: this is simulated right now. */
export const AwsCoSellSyncStatus = ({
  lastSyncDate,
  opportunityId,
}: AwsCoSellSyncStatusProps) => {
  const classes = useStyles();
  // const { triggerSyncOpportunityById } = useAwsOpportunityActions();
  const { syncAwsOpportunity, isLoading } = useAwsOpportunitySync({
    opportunityId,
    token: 'session token', // figure out better way to initialize this on load
  });

  const handleSync = () => {
    syncAwsOpportunity();
  };

  /** TODO:
   *  create a utility function for this date format.
   */
  const lastSyncDateFormatted = `${toDateTime(lastSyncDate).toFormat(
    'MM/dd/yyyy',
  )} at ${toDateTime(lastSyncDate).toFormat('HH:MM:ss')}`;
  return (
    <div>
      Last synced from Salesforce opportunity was at {lastSyncDateFormatted}.
      The next sync will be in 60 minutes <br />
      <Button onClick={handleSync} size="small" variant="text">
        <span className={classes.buttonContent}>
          <Sync className={classes.icon} />
          <span className={classes.buttonText}>
            {`${isLoading ? 'Syncing' : 'Sync'} from Salesforce opportunity`}
          </span>
        </span>
      </Button>
    </div>
  );
};

export const formatDate = (date: string) =>
  toDateTime(date).toFormat('MM/dd/yyyy');
