import { Button } from '@tackle-io/platform-ui';
import { useFormikContext, setNestedObjectValues } from 'formik';
import { isEmpty } from 'lodash';
import { useOpportunityFormStyles } from './UnifiedOpportunityForm.styles';
import { ActiveOpportunityFormActions } from './UnifiedOpportunityFormShell';
import { UnifiedOpportunityFormValues } from './UnifiedOpportunityFormValues';
import { AceOpportunityReviewStatusEnum } from '../../types/enums';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { coSellCreateEditPageDataId } from '../../utilities/intercomEnums';
import { useCanUseAwsFeatures } from '../../hooks/useCanUseAwsFeatures/useCanUseAwsFeatures';

interface UnifiedOpportunityFormButtonSectionProps {
  onSubmit: (values: UnifiedOpportunityFormValues) => Promise<void>;
  onSaveAsDraft?: (values: UnifiedOpportunityFormValues) => Promise<void>;
  onCancel: (isDirty?: boolean) => void;
  onDelete: () => void;
  opportunity: AceOpportunityResponse;
  activeOpportunityFormAction: ActiveOpportunityFormActions;
}

export const UnifiedOpportunityFormButtonSection = ({
  onSubmit,
  onSaveAsDraft,
  onCancel,
  onDelete,
  opportunity,
  activeOpportunityFormAction,
}: UnifiedOpportunityFormButtonSectionProps) => {
  const { canUse } = useCanUseAwsFeatures();
  const classes = useOpportunityFormStyles();
  const { dirty, values, validateForm, setTouched, submitForm } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const isDraft =
    opportunity?.lifeCycle?.reviewStatus ===
    AceOpportunityReviewStatusEnum.PENDING_SUBMISSION;
  const isSaveForDraftEnabled = isDraft || !opportunity?.identifier;

  const validateFormAndScrollTo = async (handleSave) => {
    const errors = await validateForm();
    // submitForm() is only meant to update submitCount so that we know to scroll to errors
    submitForm();
    if (isEmpty(errors)) {
      handleSave(values);
    } else {
      setTouched(setNestedObjectValues(errors, true));
    }
  };

  const handleSubmit = () => {
    validateFormAndScrollTo(onSubmit);
  };

  const handleSaveDraft = () => {
    validateFormAndScrollTo(onSaveAsDraft);
  };

  return (
    <>
      <div className={classes.cosellFormButtonsContainer}>
        <div className={classes.cosellFormCancelButton}>
          {/* TODO: Use updated button colors */}
          <Button
            id={coSellCreateEditPageDataId.CANCEL}
            type="button"
            size="medium"
            name="cancel"
            variant="outlined"
            appearance="secondary"
            onClick={() => onCancel(dirty)}
            disabled={!!activeOpportunityFormAction}
            data-id={coSellCreateEditPageDataId.CANCEL}
          >
            Cancel
          </Button>
        </div>
        <div className={classes.cosellFormButtonContainer}>
          {isSaveForDraftEnabled ? (
            <Button
              appearance="secondary"
              id={coSellCreateEditPageDataId.SAVE_DRAFT}
              name="draft"
              data-id={coSellCreateEditPageDataId.SAVE_DRAFT}
              onClick={handleSaveDraft}
              size="medium"
              type="button"
              variant="outlined"
              loading={
                activeOpportunityFormAction ===
                ActiveOpportunityFormActions.SAVING_DRAFT
              }
              disabled={!!activeOpportunityFormAction}
            >
              Save draft
            </Button>
          ) : null}
        </div>
        <div className={classes.cosellFormButtonContainer}>
          <Button
            id={
              opportunity?.partnerOpportunityIdentifier && !isDraft
                ? coSellCreateEditPageDataId.UPDATE_SUBMIT_TO_CLOUD
                : coSellCreateEditPageDataId.SUBMIT_TO_CLOUD
            }
            type="button"
            size="medium"
            name="submit"
            data-id={
              opportunity?.partnerOpportunityIdentifier && !isDraft
                ? coSellCreateEditPageDataId.UPDATE_SUBMIT_TO_CLOUD
                : coSellCreateEditPageDataId.SUBMIT_TO_CLOUD
            }
            variant="contained"
            appearance="primary"
            onClick={handleSubmit}
            loading={
              activeOpportunityFormAction ===
              ActiveOpportunityFormActions.SUBMITTING
            }
            disabled={
              !!activeOpportunityFormAction || !canUse.opportunities.submit
            }
          >
            {opportunity?.partnerOpportunityIdentifier && !isDraft
              ? 'Update'
              : 'Submit to cloud'}
          </Button>
        </div>
      </div>
    </>
  );
};
