import { useSalesforceCoSellWidgetButtonGroupStyles } from './SalesforceCoSellWidgetButtonGroup.styles';
import { Button } from 'vendor/material';
import useCoSellSfCanvasActions, {
  COSELL_ACTION_MAP,
} from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';

/** WIP
 *
 * TODO:
 *  - style buttons to match design (color, spacing, layout)
 *  - implement button actions
 *  - add memoized func to generate button actions (will be driven by many factors)
 *  - cleanup code
 */
export const SalesforceCoSellWidgetButtonGroup = ({
  tackleCoSellId,
}: {
  tackleCoSellId?: string;
}) => {
  const classes = useSalesforceCoSellWidgetButtonGroupStyles();
  const { handleCoSellAction } = useCoSellSfCanvasActions();

  const cloud = DisplayCloudType.AWS;

  const tackleOpportunityId = tackleCoSellId;

  return (
    <div className={classes.buttonGroupContainer}>
      <div className={classes.editLockup}>
        {tackleCoSellId && (
          <Button
            className={classes.buttonElement}
            variant="outlined"
            color="primary"
            onClick={() =>
              handleCoSellAction({
                id: 'view-cosell',
                cloud,
                tackleOpportunityId,
              })
            }
          >
            {COSELL_ACTION_MAP['view-cosell'].label}
          </Button>
        )}
        {tackleCoSellId && (
          <Button
            className={classes.buttonElement}
            variant="outlined"
            color="primary"
            onClick={() =>
              handleCoSellAction({
                id: 'edit-cosell',
                cloud,
                tackleOpportunityId,
              })
            }
          >
            {COSELL_ACTION_MAP['edit-cosell'].label}
          </Button>
        )}

        <Button
          disabled
          className={classes.dashboardButtonElement}
          onClick={() =>
            handleCoSellAction({
              id: 'submit-cosell-to-cloud',
              cloud,
              tackleOpportunityId: tackleCoSellId,
            })
          }
        >
          {COSELL_ACTION_MAP['submit-cosell-to-cloud'].label}
        </Button>
      </div>
      <Button
        className={classes.buttonElement}
        variant="outlined"
        onClick={() =>
          handleCoSellAction({
            id: 'create-cosell',
            cloud,
          })
        }
      >
        {COSELL_ACTION_MAP['create-cosell-aws'].label}
      </Button>

      {/* {tackleCoSellId && (
        
        <Button
          className={classes.dashboardButtonElement}
          variant="contained"
          color="primary"
          size="large"
          href={`https://localhost:3000/${COSELL_PATH}/opportunity/aws/${tackleCoSellId}`}
          target="_blank"
        >
          View Co-Sell Opportunity
        </Button>
      )} */}
    </div>
  );
};
