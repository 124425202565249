import {
  AceOpportunityReviewStatusEnum,
  AceOpportunityStageEnum,
} from 'packages/cosell/src/types/enums';
import {
  DraftIcon,
  SyncedIcon,
  SyncingIcon,
  WarningIcon,
} from 'packages/cosell/assets';
import React from 'react';

export interface RightRailContentConfig {
  title: string;
  icon?: JSX.Element;
  guidanceText: string | React.ReactNode;
  subTitle?: string;
  subIcon?: JSX.Element;
}

// create this in react node instead of string to allow for custom styling (bold Refresh text)
const OperationText = () => {
  return (
    <div>
      This co-sell opportunity is being updated. Please wait a few moments and
      then click <b>Refresh</b>.
    </div>
  );
};

export default OperationText;

// TITLES CONSTANTS
export const CLOUD_CONTACTS_TITLE = 'Cloud contacts';
export const LAUNCHED_TITLE = 'Co-Sell launched';
export const CLOSED_LOST_TITLE = 'Co-Sell closed lost';
export const DRAFT_TITLE = 'Draft partner opportunity';
export const SUBMITTED_TITLE = 'Submitted to cloud';
export const SUBMITTED_SANDBOX_TITLE = 'Simulated cloud submission';
export const IN_CLOUD_REVIEW_TITLE = 'In cloud review';
export const ACTION_REQUIRED_TITLE = 'Action required';
export const REJECTED_TITLE = 'Rejected by cloud partner';
export const APPROVED_TITLE = 'Approved by cloud partner';
export const CO_SELL_OPERATION_IN_PROGRESS = 'Co-Sell operation in progress';

// GUIDANCE TEXTS CONSTANTS
export const DRAFT_GUIDANCE_TEXT =
  'This draft co-sell opportunity has not been submitted to the cloud. You can continue editing, and when you are ready submit for cloud review.';
export const SUBMITTED_GUIDANCE_TEXT =
  'This co-sell opportunity has been submitted to the cloud for review, pending cloud response. You cannot edit the opportunity while it is pending cloud review.';
export const SUBMITTED_SANDBOX_GUIDANCE_SPAN = (
  <span>
    <b>This is a test environment.</b> It is not connected to AWS Partner
    Central. Use the buttons below to simulate AWS Partner Central accepting or
    rejecting this co-sell opportunity. If accepted, contact details will
    appear.
  </span>
);
export const IN_CLOUD_REVIEW_GUIDANCE_TEXT =
  'This co-sell opportunity has been submitted to the cloud and is currently in review. You cannot edit the opportunity while it is in cloud review.';
export const ACTION_REQUIRED_GUIDANCE_TEXT =
  'This co-sell opportunity has been reviewed by the cloud and requires an action on your part to proceed. Please read the reviewer comments, address any issues, and resubmit to the cloud.';
export const REJECTED_GUIDANCE_TEXT =
  'This co-sell opportunity has been rejected by the cloud for the reason above.';
export const APPROVED_GUIDANCE_TEXT =
  'This co-sell opportunity has been approved by your cloud partner. You can edit the opportunity and contact your cloud partner to follow up. When you are ready, you can either launch the opportunity or close it as lost.';

export const PROSPECT_REMINDER_GUIDANCE_TEXT =
  'Move this opportunity out of the Prospect stage within 60 days of receipt to avoid automatic close lost by the cloud.';
export const LAUNCHED_GUIDANCE_TEXT =
  'Congratulations! This co-sell opportunity has been launched, and billing for the solution has begun. You can still edit some information and add comments. As always, contact your cloud partner to follow up.';
export const CLOSED_LOST_GUIDANCE_TEXT =
  'This co-sell opportunity referred to you by your cloud partner has been closed lost for the reason above. You can contact your cloud partner to follow up.';
export const NO_CLOUD_CONTACT_GUIDANCE_TEXT =
  'You don’t have any cloud contacts yet. Your cloud partner will share them after this co-sell opportunity has been submitted and approved for co-selling.';

export const getRightRailContentConfig = (
  status?: AceOpportunityReviewStatusEnum,
  stage?: AceOpportunityStageEnum,
  reviewStatusReason?: string,
  isSandboxEnvironment?: boolean,
  hasPendingRequest?: boolean,
): RightRailContentConfig => {
  // Handle special cases first
  if (hasPendingRequest) {
    return {
      title: CO_SELL_OPERATION_IN_PROGRESS,
      icon: <SyncingIcon />,
      guidanceText: <OperationText />,
    };
  }
  if (
    status === AceOpportunityReviewStatusEnum.APPROVED &&
    stage === AceOpportunityStageEnum.LAUNCHED
  ) {
    return {
      title: LAUNCHED_TITLE,
      icon: <SyncedIcon />,
      guidanceText: LAUNCHED_GUIDANCE_TEXT,
    };
  }
  if (
    status === AceOpportunityReviewStatusEnum.APPROVED &&
    stage === AceOpportunityStageEnum.CLOSED_LOST
  ) {
    return {
      title: CLOSED_LOST_TITLE,
      icon: <SyncedIcon />,
      subIcon: <WarningIcon />,
      subTitle: reviewStatusReason,
      guidanceText: CLOSED_LOST_GUIDANCE_TEXT,
    };
  }

  if (
    isSandboxEnvironment &&
    status === AceOpportunityReviewStatusEnum.SUBMITTED
  ) {
    return {
      title: SUBMITTED_SANDBOX_TITLE,
      icon: <SyncingIcon />,
      guidanceText: SUBMITTED_SANDBOX_GUIDANCE_SPAN,
    };
  }

  // handle default status configuration
  const config: Record<AceOpportunityReviewStatusEnum, RightRailContentConfig> =
    {
      [AceOpportunityReviewStatusEnum.PENDING_SUBMISSION]: {
        title: DRAFT_TITLE,
        icon: <DraftIcon />,
        guidanceText: DRAFT_GUIDANCE_TEXT,
      },
      //TODO:currently in our api, we don't have a way to differentiate between draft and internal review status
      // [AceOpportunityReviewStatusEnum.IN_REVIEW]: {
      //   title: 'Submitted for review',
      //  icon: <DraftIcon />,
      //   guidanceText:
      //     'This draft co-sell opportunity has been submitted for internal review. You can still edit the opportunity while in internal review.',
      // },
      [AceOpportunityReviewStatusEnum.SUBMITTED]: {
        title: SUBMITTED_TITLE,
        icon: <SyncingIcon />,
        guidanceText: SUBMITTED_GUIDANCE_TEXT,
      },
      [AceOpportunityReviewStatusEnum.IN_REVIEW]: {
        title: IN_CLOUD_REVIEW_TITLE,
        icon: <SyncedIcon />,
        guidanceText: IN_CLOUD_REVIEW_GUIDANCE_TEXT,
      },
      [AceOpportunityReviewStatusEnum.ACTION_REQUIRED]: {
        title: ACTION_REQUIRED_TITLE,
        icon: <SyncedIcon />,
        guidanceText: ACTION_REQUIRED_GUIDANCE_TEXT,
      },
      [AceOpportunityReviewStatusEnum.REJECTED]: {
        title: REJECTED_TITLE,
        subTitle: reviewStatusReason,
        icon: <SyncedIcon />,
        guidanceText: REJECTED_GUIDANCE_TEXT,
        subIcon: <WarningIcon />,
      },
      [AceOpportunityReviewStatusEnum.APPROVED]: {
        title: APPROVED_TITLE,
        icon: <SyncedIcon />,
        guidanceText: APPROVED_GUIDANCE_TEXT,
      },
    };
  if (!status) {
    return {
      title: DRAFT_TITLE,
      icon: <DraftIcon />,
      guidanceText: DRAFT_GUIDANCE_TEXT,
    };
  }

  return config[status];
};

// conditional rendering functions:

// the user can take actions only when the opportunity is approved and the stage is not closed lost
// or the opportunity is NOT in submitted, in review, or rejected status
export const shouldRenderActionButtonSection = (
  status?: AceOpportunityReviewStatusEnum,
  stage?: AceOpportunityStageEnum,
  isSandboxEnvironment?: boolean,
): boolean => {
  if (
    status === AceOpportunityReviewStatusEnum.APPROVED &&
    stage === AceOpportunityStageEnum.CLOSED_LOST
  ) {
    return false;
  }
  if (
    isSandboxEnvironment &&
    status === AceOpportunityReviewStatusEnum.SUBMITTED
  ) {
    return true;
  }
  const lockedStatuses = [
    AceOpportunityReviewStatusEnum.SUBMITTED,
    AceOpportunityReviewStatusEnum.IN_REVIEW,
    AceOpportunityReviewStatusEnum.REJECTED,
  ];

  if (!status) {
    return false;
  } else {
    return !lockedStatuses.includes(status);
  }
};

// the cloud partner will share cloud contacts after approve or reject the opportunity
export const shouldRenderCloudContact = (
  status?: AceOpportunityReviewStatusEnum,
): boolean => {
  const approvedOrRejected = [
    AceOpportunityReviewStatusEnum.REJECTED,
    AceOpportunityReviewStatusEnum.APPROVED,
  ];
  return approvedOrRejected.includes(status as AceOpportunityReviewStatusEnum);
};
