import { useQuery } from '@tanstack/react-query';
import { getTackleOpportunityListByCrmId } from 'packages/salesforce-canvas/api/requests/getTackleOpportunityListByCrmId';

/** Returns a summary for all Tackle co-sell opportunities,
 * linked to the provided crmId (Salesforce Opportunity ID)
 */
export const useTackleOpportunitiesForCrmId = ({
  crmId,
}: {
  crmId?: string;
}) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getTackleOpportunityListByCrmId({ crmId }),
    queryKey: [crmId],
    enabled: !!crmId,
  });

  return {
    data,
    isLoading,
  };
};
