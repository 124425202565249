import React from 'react';
import { Box, Card, Divider } from 'vendor/material';
import useStyles from './RightRailCard.style';
import RightRailSubSection from 'packages/cosell/src/components/RightRailSubSection/RightRailSubSection';
import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import RightRailContactInfo from 'packages/cosell/src/components/RightRailSubSection/RightRailContactInfoSection';
import { AwsOpportunityTeamMember } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import Button from 'packages/cosell/src/components/Button/Button';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import {
  CLOUD_CONTACTS_TITLE,
  getRightRailContentConfig,
  NO_CLOUD_CONTACT_GUIDANCE_TEXT,
  RightRailContentConfig,
  shouldRenderCloudContact,
} from './helper';
import RightRailButtonSection from './RightRailButtonSection';

interface RightRailCardProps {
  status: AceInvitationCloudStatusEnum;
  origin: string;
  awsOpportunityTeam: AwsOpportunityTeamMember[];
}

const RightRailCard: React.FC<RightRailCardProps> = ({
  status,
  awsOpportunityTeam,
}) => {
  const classes = useStyles();

  const renderCloudContact = shouldRenderCloudContact(status);
  // const renderDaysReminder = true;

  const getCloudContactConfig = (
    awsOpportunityTeam: AwsOpportunityTeamMember[],
  ): RightRailContentConfig => {
    if (
      !awsOpportunityTeam ||
      awsOpportunityTeam.length === 0 ||
      !renderCloudContact
    ) {
      return {
        title: CLOUD_CONTACTS_TITLE,
        guidanceText: NO_CLOUD_CONTACT_GUIDANCE_TEXT,
      };
    }
    const contactInfo = awsOpportunityTeam.map((teamMember, i) => (
      <RightRailContactInfo
        businessTitle={teamMember.businessTitle}
        name={
          teamMember.firstName &&
          teamMember.lastName &&
          `${teamMember.firstName} ${teamMember.lastName}`
        }
        email={teamMember.email}
        key={`${teamMember.firstName}_${i}`}
      />
    ));
    return {
      title: CLOUD_CONTACTS_TITLE,
      guidanceText: contactInfo,
    };
  };

  const rightRailContents = getRightRailContentConfig(status);
  const cloudContactConfig = getCloudContactConfig(awsOpportunityTeam);

  return (
    <Card variant="outlined" className={classes.rightRailCard}>
      {rightRailContents ? (
        <RightRailSubSection
          title={rightRailContents?.title}
          subTitle={rightRailContents?.subTitle}
          guidanceText={rightRailContents?.guidanceText}
          icon={rightRailContents?.icon}
          subIcon={rightRailContents?.subIcon}
        />
      ) : null}
      {/* {renderDaysReminder && (
        <RightRailSubSection
          subTitle="28 days to avoid cloud auto close" //TODO: days should be dynamic
          subIcon={<CalendarClockIcon />}
          guidanceText={PROSPECT_REMINDER_GUIDANCE_TEXT}
        />
      )} */}

      <Box mt={2}>
        <RightRailButtonSection status={status} />
      </Box>

      <Box mt={2} mb={1}>
        <Divider />
      </Box>

      <RightRailSubSection
        title={cloudContactConfig.title}
        guidanceText={cloudContactConfig.guidanceText}
      />

      {renderCloudContact && awsOpportunityTeam?.length > 0 && (
        <Box mt={2}>
          <Button
            data-id={coSellDetailsPageDataId.CONTACT_CLOUD_PARTNER}
            variant="outlined"
            appearance="primary"
            fullWidth
          >
            Contact cloud partner
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default RightRailCard;
