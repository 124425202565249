import { useMemo } from 'react';
import AceOpportunityForm from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityForm';
import { DisplayCloudType } from '../../types/enums';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { useCurrentUserVendorQuery } from 'generated/graphql';
import { UnifiedOpportunityView } from '../../utilities/constants';
import UnifiedOpportunityDetails from '../UnifiedOpportunityDetails';
import { useCoSellHistory } from '../../hooks';
import { COSELL_PATH } from '../../utilities/constants';
import { CoSellContextProvider } from 'packages/cosell/src/CoSellContextProvider';
import { useQueryClient } from '@tanstack/react-query';
import { ACE_OPPORTUNITY_QUERY_KEY } from 'packages/cosell/api/hooks/useAceOpportunity';

const useRedirectToOpportunityDetails = () => {
  const provider = DisplayCloudType.AWS;
  const queryClient = useQueryClient();
  const history = useCoSellHistory();
  return (opportunityId: string) => {
    // Invalidate the query to force a refetch
    queryClient.invalidateQueries([ACE_OPPORTUNITY_QUERY_KEY, opportunityId]);
    history.push(
      `${COSELL_PATH}/opportunity/${provider.toLowerCase()}/${opportunityId}`,
    );
  };
};

interface RenderFormProps {
  cloud?: string;
  opportunityId?: string;
  view?: UnifiedOpportunityView;
  onCancel: () => void;
  onCreated?: (opportunityId: string) => void;
}

const renderForm = ({
  cloud,
  opportunityId,
  view,
  onCancel,
  onCreated,
}: RenderFormProps) => {
  if (cloud === DisplayCloudType.AWS) {
    switch (view) {
      case UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM:
        if (!onCreated) {
          console.error(
            'must provide onCreated callback for view: CreateNewCoSellForm ',
          );
          return null;
        }
        return <AceOpportunityForm onCancel={onCancel} onCreated={onCreated} />;
      case UnifiedOpportunityView.EDIT_CO_SELL_FORM:
        return (
          <AceOpportunityForm
            onCancel={onCancel!}
            opportunityId={opportunityId}
          />
        );
      case UnifiedOpportunityView.CO_SELL_DETAILS:
        return <UnifiedOpportunityDetails />;
      default:
        return <div>Not found page</div>;
    }
  }
};

const UnifiedOpportunityForm = ({
  view,
  match,
}: {
  view: UnifiedOpportunityView;
  match: { params: { [key: string]: string }; url: string };
  onCancel?: () => void;
}) => {
  const redirectToOpportunityDetails = useRedirectToOpportunityDetails();
  const history = useCoSellHistory();
  const cloud = useMemo(
    () => match.params.cloud.split('?')[0],
    [match.params.cloud],
  );
  const opportunityId = useMemo(
    () => match.params.opportunityId?.split('?')[0],
    [match.params.opportunityId],
  );

  const handleCancel = () => {
    if (!opportunityId) {
      /**  Redirect to Co-sell page */
      history.push({
        pathname: COSELL_PATH,
        search: history.location.state?.coSellSearchParams,
      });
      return;
    }
  };

  /**
   * TODO: Find a better way to render create/edit forms with their specific actions/side effects
   */
  const handleCreated = (opportunityId: string) => {
    redirectToOpportunityDetails(opportunityId);
  };

  return (
    <UnifiedOpportunityFormContainer opportunityId={opportunityId}>
      {renderForm({
        cloud,
        opportunityId,
        view,
        onCreated: handleCreated,
        onCancel: handleCancel,
      })}
    </UnifiedOpportunityFormContainer>
  );
};

interface UnifiedOpportunityFormContainerProps {
  opportunityId?: string;
  children?: React.ReactElement;
}

/** Wrapper for Tackle-UI only. */
export const UnifiedOpportunityFormContainer = ({
  opportunityId,
  children,
}: UnifiedOpportunityFormContainerProps) => {
  const { data: currentUserData } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const vendorId = currentUserData?.currentUser?.vendor?.id;

  if (!vendorId) {
    return <div>Not Authenticated</div>;
  }

  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Co-sell" />}>
      <CoSellContextProvider
        opportunityId={opportunityId}
        renderEnv="downstream"
        vendorId={vendorId}
      >
        {children}
      </CoSellContextProvider>
    </ErrorBoundary>
  );
};
export default UnifiedOpportunityForm;
