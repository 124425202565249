import React from 'react';
import { Grid, Typography } from 'vendor/material';
import { ListStatus } from 'mdi-material-ui';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';

const ReceiverResponsibilities = () => (
  <DetailsSectionCard
    title="Receiver responsibilities"
    icon={<ListStatus />}
    dataId={coSellDetailsPageDataId.RECEIVER_RESPONSIBILITIES}
  >
    <Grid container spacing={2}>
      <Typography variant="body2">
        Reseller, hardware partner, software partner, services partner
      </Typography>
    </Grid>
  </DetailsSectionCard>
);

export default ReceiverResponsibilities;
