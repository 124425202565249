import React, { useEffect, useRef } from 'react';
import useStyles from './Header.styles';
import { Box } from 'vendor/material';
import { Button, Link, ProviderIcon, Tooltip } from '@tackle-io/platform-ui';
import { StatusTag } from 'pages/Contracts/components/StatusTag';
import CaretLeft from './assets/DetailsIcon';
import { useSingleContractData } from '../../providers/SingleContractDataProvider';
import { LoadingMetrics } from 'pages/Contracts/components/ContractsMetrics/components/LoadingMetrics';
import { OfferTag } from 'pages/Contracts/components/OfferTag/OfferTag';
import { ampli } from 'utils/analytics/ampli';
import { ContractsDataId } from 'utils/intercomEnums';

const displayCloudContract = (contract): boolean => {
  if (contract?.cloudContractStatus) {
    if (contract?.contractStatus) {
      return (
        contract.cloudContractStatus.toUpperCase() !==
        contract?.contractStatus.toUpperCase()
      );
    }
  }
  return contract?.cloudContractStatus;
};

const displayAmendButton = (contract): boolean => {
  return (
    contract?.contractStatus === 'Active' &&
    contract?.productAndPricing?.isListingManagedByTackle === true &&
    contract?.listingType === 'SaaSProduct'
  );
};

const displayAddUsageButton = (contract): boolean => {
  return (
    contract?.contractStatus === 'Active' &&
    contract?.pricingModel?.toLowerCase().includes('usage')
  );
};

const Header: React.FC = () => {
  const classes = useStyles();
  const backTextRef = useRef<HTMLSpanElement>(null);

  const { contract, loading } = useSingleContractData();

  useEffect(() => {
    if (backTextRef.current) {
      backTextRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [backTextRef, loading]);

  const handleDownloadArtifactClick = async () => {
    await ampli.bookableArtifactDownloaded({
      marketplace: contract?.cloudMarketplace as 'aws' | 'azure' | 'gcp',
      contract_status: contract?.contractStatus,
      offer_origination: contract?.offerType,
      pricing_model: contract?.pricingModel,
      tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
    });
  };

  const handleAddUsageClick = async () => {
    await ampli.addUsageStarted({
      marketplace: contract?.cloudMarketplace as 'aws' | 'azure' | 'gcp',
      contract_status: contract?.contractStatus,
      offer_origination: contract?.offerType,
      pricing_model: contract?.pricingModel,
      tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
    });
  };

  const handleAmendClick = async () => {
    await ampli.amendContractStarted({
      marketplace: contract?.cloudMarketplace as 'aws' | 'azure' | 'gcp',
      contract_status: contract?.contractStatus,
      offer_origination: contract?.offerType,
      pricing_model: contract?.pricingModel,
      tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
    });
  };

  if (loading) {
    return <LoadingMetrics />;
  }

  const navigateToPreviousPage = () => {
    if (document.referrer.includes('/cloud-contracts')) {
      window.history.back();
    } else {
      window.location.href = '/cloud-contracts';
    }
  };

  return (
    <Box
      flexDirection={'column'}
      alignContent={'flex-start'}
      className={classes.headerContainer}
    >
      <Box display={'flex'} gridGap={8}>
        <Link
          to="#"
          underline="none"
          disableStyles
          onClick={navigateToPreviousPage}
        >
          <CaretLeft />
        </Link>
        <span ref={backTextRef} className={classes.backText}>
          Contracts
        </span>
      </Box>
      <Box display={'flex'} justifyContent="space-between" alignItems="center">
        <Box className={classes.leftSection}>
          <Box className={classes.contractNameAndLogo}>
            <Box mr={1} fontSize={32}>
              <ProviderIcon
                provider={
                  contract?.cloudMarketplace === 'azure'
                    ? 'microsoft'
                    : (contract?.cloudMarketplace as 'aws' | 'gcp')
                }
                fontSize="inherit"
              />
            </Box>
            {/* Adding temp h1 tag for preview icon to be injected via third party - remove on ENG-8750 */}
            <span className={classes.buyer}>
              <h1 className={classes.previewTag}>
                {contract?.companyName ? (
                  contract?.companyName
                ) : (
                  <Tooltip
                    content="Pending from the marketplace"
                    position="bottom"
                  >
                    <span>--</span>
                  </Tooltip>
                )}
              </h1>
            </span>
          </Box>
          {contract?.offerName !== "''" ? (
            <Box>
              <span className={classes.offerName}>
                {contract?.offerName ? (
                  contract?.offerName
                ) : (
                  <Tooltip
                    content="Pending from the marketplace"
                    position="bottom"
                  >
                    <span>--</span>
                  </Tooltip>
                )}
              </span>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box
          className={classes.rightSection}
          data-id={ContractsDataId.CONTRACT_DETAIL_ACTION_BUTTONS}
        >
          <Button
            variant="outlined"
            appearance="primary"
            onClick={handleDownloadArtifactClick}
            disabled={true} /* Reenable once this is implemented */
          >
            Download bookable artifact
          </Button>
          {displayAddUsageButton(contract) && (
            <Button
              variant="outlined"
              appearance="primary"
              onClick={handleAddUsageClick}
              disabled={true} /* Reenable once this is implemented */
            >
              Add usage
            </Button>
          )}
          {displayAmendButton(contract) && (
            <Button
              appearance="primary"
              onClick={handleAmendClick}
              disabled={true} /* Reenable once this is implemented */
            >
              Amend
            </Button>
          )}
        </Box>
      </Box>
      <Box className={classes.badges}>
        <OfferTag offerType={contract?.offerType} />
        <StatusTag label={contract?.contractStatus} />
        {displayCloudContract(contract) ? (
          <StatusTag label={contract?.cloudContractStatus} />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default Header;
