import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import AceOpportunityDetails from './AceOpportunityDetails/AceOpportunityDetails';
import { Loader } from '@tackle-io/platform-ui';
import useOpportunityEventsQuery from '../../hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import isOpportunityBeingCreatedCheck from 'packages/cosell/api/utilities/IsOpportunityBeingCreatedCheck/isOpportunityBeingCreatedCheck';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';

const UnifiedOpportunityDetails = () => {
  /** TODO: pass opportunityId in as a prop as it should always be available when loading this component */
  const { opportunityId } = useCoSellContext();

  const { isSaasDocumentationRequiredToLaunch } = useAceVendorConfig();
  const {
    opportunityEvents,
    isOpportunityEventsLoading,
    opportunityEventsError,
  } = useOpportunityEventsQuery(opportunityId);
  const events = opportunityEvents?.events || [];

  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });
  const opportunity = aceOpportunityQuery?.data;

  // this opportunityError property from useQuery
  if (aceOpportunityQuery.isError) {
    return <div>Error loading opportunity</div>;
  }
  // TODO: we may still be able to show the opportunity details even if the opportunity event has error
  if (opportunityEventsError) {
    return <div>Error loading opportunity events</div>;
  }

  const isOpportunityBeingCreated = isOpportunityBeingCreatedCheck({
    data: opportunity,
  });

  if (
    aceOpportunityQuery.isLoading ||
    updateAceOpportunity.isLoading ||
    isOpportunityEventsLoading ||
    isOpportunityBeingCreated
  ) {
    return <Loader />;
  }

  if (!opportunity) {
    return <div>No opportunity found</div>;
  }
  if (!('id' in opportunity)) {
    return <div>Opportunity has not been created</div>;
  }
  return (
    <AceOpportunityDetails
      opportunityId={opportunityId}
      opportunity={opportunity}
      requiresSaasDocumentationToLaunch={isSaasDocumentationRequiredToLaunch({
        deliveryModels: opportunity.project?.deliveryModels,
      })}
      opportunityEvents={events}
    />
  );
};

export default UnifiedOpportunityDetails;
