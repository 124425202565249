import { makeStyles } from 'vendor/material';

export const useSalesforceCoSellWidgetButtonGroupStyles = makeStyles(
  (theme) => ({
    editLockup: {
      display: 'flex',
      justifyContent: 'stretch',
      alignItems: 'stretch',
      gap: theme.spacing(1),
    },
    buttonGroupContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    bottomButtonContainer: {
      marginTop: '12px',
      '&>:nth-child(1)': {
        paddingRight: '6px',
      },
      '&>:nth-child(2)': {
        paddingLeft: '6px',
      },
    },
    dashboardButtonElement: {
      flexGrow: 1,
      flexShrink: 0,
      color: theme.palette.NEUTRAL010,
      backgroundColor: theme.palette.BLUE400,
      '&:hover': {
        backgroundColor: theme.palette.BLUE500,
      },
      '&:disabled': {
        backgroundColor: theme.palette.BLUE200,
        color: theme.palette.BLUE050,
        pointer: 'none',
      },
      textTransform: 'none',
      padding: `7px ${theme.spacing(2)}`,
      fontSize: theme.typography.pxToRem(12),
      height: theme.typography.pxToRem(34),
      fontWeight: 700,
    },
    buttonElement: {
      padding: `7px ${theme.spacing(2)}`,
      height: theme.typography.pxToRem(34),
      flexShrink: 0,
      color: theme.palette.BLUE400,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 700,
      textTransform: 'none',
      borderColor: '#C1C7D0',
    },
  }),
);
