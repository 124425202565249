import { makeStyles } from 'vendor/material';

const useTokenStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: 0,
    paddingBottom: theme.spacing(2),
    '& > h3': {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(0),
    },
    '& > div:last-child': {
      padding: theme.spacing(0, 3),
    },
  },
  formContainer: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  userListContainer: {
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
    borderBottom: `1px solid ${theme.palette.NEUTRAL040}`,
    marginBottom: theme.spacing(1.25),
  },
  userListItem: {
    paddingLeft: theme.spacing(3),
  },
  title: {
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.NEUTRAL500,
  },
}));

export default useTokenStyles;
