import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { solutionsApiToFormFormatter } from 'packages/cosell/src/utilities/formatSolutions';

/**
 * a query to fetch & cache the vendor solutions
 */
export function useAceVendorSolutionsQuery({ vendorId }: { vendorId: string }) {
  return useQuery({
    /** vendorId is only here to create a new query if it changes.
     *  getVendorAceSolutions endpoint grabs the vendorId from the token, so it is not passed in
     */
    queryKey: ['aceVendorSolutions', vendorId],
    queryFn: coSellClient.getVendorAceSolutions,
    select: (data) => solutionsApiToFormFormatter(data?.solutions),
    staleTime: Infinity,
  });
}
