import { Box } from 'vendor/material';
import DetailsCard from './components/DetailsCard/DetailsCard';
import useStyles from './DetailsTab.style';
import ClipboardIcon from '../../../../../../assets/ClipboardIcon';
import PricingIcon from '../../../../../../assets/CashIcon';
import { buildContractDetails } from 'pages/Contracts/pages/Details/ContractDetailsMapper';
import { useEffect, useState } from 'react';
import { LoadingMetrics } from 'pages/Payments/components/PaymentsMetrics/components/LoadingMetrics';
import { ampli } from 'utils/analytics/ampli';

interface DetailsTabProps {
  contract: any;
}

const DetailsTab: React.FC<DetailsTabProps> = ({ contract }) => {
  const classes = useStyles();
  const [eventSent, setEventSent] = useState(false);

  useEffect(() => {
    if (contract && !eventSent) {
      ampli.contractDetailTabViewed({
        marketplace: contract?.cloudMarketplace,
        contract_status: contract?.contractStatus,
        offer_origination: contract?.offerType,
        pricing_model: contract?.pricingModel,
        tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
        contract_detail_tab: 'Details',
      });
      setEventSent(true);
    }
  }, [contract, eventSent]);

  if (!contract) {
    return <LoadingMetrics />;
  }

  const { basicInformation, productAndPricing, registration } =
    buildContractDetails(contract);

  return (
    <Box className={classes.cardContainer}>
      <DetailsCard
        icon={<ClipboardIcon />}
        headerText="Basic information"
        cardBodySections={basicInformation}
      />
      <DetailsCard
        icon={<PricingIcon />}
        headerText="Product and pricing"
        cardBodySections={productAndPricing}
      />
      {registration && (
        <DetailsCard
          icon={<ClipboardIcon />}
          headerText="Registration and additional contract details"
          cardBodySections={registration}
        />
      )}
    </Box>
  );
};

export default DetailsTab;
