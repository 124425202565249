import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem, {
  FULL_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import {
  coSellAceInvitationDetailsDataId,
  coSellAceOpportunityFormFieldsDataId,
} from 'packages/cosell/src/utilities/intercomEnums';
import { InvitationProject } from 'packages/cosell/src/types/responses/AceInvitationResponse';
import { pick } from 'lodash-es';
import { ExpectedCustomerSpendDetails } from '../../../UnifiedOpportunityDetails/AceOpportunityDetails/detailSections/ExpectedCustomerSpendDetails';

interface InvitationDetails {
  project?: InvitationProject;
}

const getFlattenedProps = (detailProps: InvitationDetails) => ({
  ...pick(detailProps?.project, [
    'title',
    'targetCompletionDate',
    'expectedCustomerSpend',
    'businessProblem',
  ]),
});

const AceProjectDetails: React.FC<InvitationDetails> = (detailProps) => {
  const {
    title,
    businessProblem,
    expectedCustomerSpend,
    targetCompletionDate,
  } = getFlattenedProps(detailProps);

  return (
    <DetailsSectionCard title="Project details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner project title"
          value={title}
          gridSize={FULL_GRID_SIZE}
          dataId={coSellAceOpportunityFormFieldsDataId.PROJECT_TITLE}
        />
        <InfoItem
          gridSize={FULL_GRID_SIZE}
          itemTitle="Customer business problem"
          value={businessProblem}
          dataId={
            coSellAceOpportunityFormFieldsDataId.CUSTOMER_BUSINESS_PROBLEM
          }
        />
        <InfoItem
          gridSize={FULL_GRID_SIZE}
          itemTitle="Target completion date"
          value={targetCompletionDate}
          dataId={coSellAceInvitationDetailsDataId.TARGET_COMPLETION_DATE}
        />
        <ExpectedCustomerSpendDetails
          expectedCustomerSpend={expectedCustomerSpend}
        />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceProjectDetails;
