import { makeStyles } from 'vendor/material';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: '16px',
  },
  header: {
    backgroundColor: '#EBECF0',
    padding: '8px 16px',
  },
  headerItem: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#091E42',
  },
  row: {
    marginTop: '8px',
    padding: '8px 16px',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0px 0px 1px 0px #091E424F',
  },
  rowItem: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#091E42',
  },
  rowItemFooter: {
    color: '#5E6C84',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  statusTag: {
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(12),
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.3),
    '& span': {
      textAlign: 'center',
    },
    padding: `7px 8px`,
    borderRadius: '4px',
    color: '#0052CC',
    background: '#DEEBFF',
    width: 'fit-content',
  },
}));

export default useStyles;
