import { useMemo } from 'react';

function useFilteredOrgUsers(query, includeTackleUsers = false, orgUsers = []) {
  query = query.toLowerCase();

  const users = useMemo(() => {
    return orgUsers.filter((user) => {
      if (!user?.vendorid) {
        return false;
      }
      if (!includeTackleUsers && user?.email?.includes('@tackle.io')) {
        return false;
      }
      if (query) {
        const isAccepted = 'accepted'.includes(query) && user.logins_count > 0;
        const isPending =
          'pending'.includes(query) &&
          (user.logins_count === 0 || user.logins_count === null);
        return (
          user.email?.toLowerCase().includes(query) ||
          user.name?.toLowerCase().includes(query) ||
          user.role_name?.toLowerCase().includes(query) ||
          isAccepted ||
          isPending
        );
      }
      return true;
    });
  }, [query, includeTackleUsers, orgUsers]);

  return users;
}

export default useFilteredOrgUsers;
