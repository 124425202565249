import { useEffect, useState } from 'react';
import OffersCallToAction from './OffersCallToAction';
import { PrivateOfferV2 } from 'stores/privateOffers/PrivateOfferV2';
import { useCanvasSession } from '../src/useCanvasSession';
import { Box } from 'vendor/material';
import { Loader } from '@tackle-io/platform-ui';
import ListOfOffersForOpportunity from './ListOfOffersForOpportunity';

interface PrivateOffersForSFOpportunityResponse {
  private_offers: PrivateOfferV2[];
  offset: number;
  limit: number;
  total: number;
}

const OffersTabContent = () => {
  const session = useCanvasSession();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PrivateOfferV2[] | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!session?.canvasSession?.access_token) return;
    if (!session.record?.Id) return;
    setLoading(true);
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_OFFERS_API_URL}/public/v2/private-offers?salesforce_opportunity_id=${session.record?.Id}`,
          {
            headers: {
              Authorization: `Bearer ${session.canvasSession.access_token}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result =
          (await response.json()) as PrivateOffersForSFOpportunityResponse;
        setData(result.private_offers);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [session?.canvasSession?.access_token, session?.record?.Id]);

  return (
    <Box overflow="auto" height="100%" position="relative">
      {loading && <Loader />}
      {error && <div>Error: {error.message}</div>}
      {!loading && (!data || data?.length === 0) && <OffersCallToAction />}
      {data?.length > 0 && (
        <ListOfOffersForOpportunity
          opportunityId={session?.record?.Id}
          offers={data}
        />
      )}
    </Box>
  );
};

export default OffersTabContent;
