import { CurrencyCode, formatValueWithCurrencyPrefix } from 'utils/currency';
import { Grid, Typography } from 'vendor/material';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import { makeStyles } from 'vendor/material';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { ExpectedCustomerSpend } from 'packages/cosell/src/types/AceOpportunity.type';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '24px',
    width: '100%',
  },
  listItem: {
    paddingBlock: '1rem',
  },
  itemTitle: {
    color: '#253858',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightMediumLarge,
    marginBottom: '0.5rem',
  },
  sectionTitle: {
    color: '#253858',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: fontWeightMediumLarge,
    lineHeight: '24px',
  },
}));

/** displays all ExpectedCustomerSpend entries  */
/** for Opportunities and Invitation details  */
export const ExpectedCustomerSpendDetails = ({
  expectedCustomerSpend,
}: {
  expectedCustomerSpend: ExpectedCustomerSpend;
}) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography className={styles.sectionTitle}>
        Expected customer spend
      </Typography>
      {!expectedCustomerSpend?.length && (
        <Typography>No expected customer spend details available</Typography>
      )}
      {expectedCustomerSpend?.map(
        ({ amount, currencyCode, frequency, targetCompany }, i) => (
          <div
            data-testid={`expected-customer-spend-details-spend-${i + 1}`}
            key={`${amount}-${currencyCode}-${frequency}-${i}`}
            className={styles.listItem}
          >
            <Typography className={styles.itemTitle}> Spend {i + 1}</Typography>
            <Grid container>
              <InfoItem
                gridSize={3}
                itemTitle="Amount"
                dataId={
                  coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_AMOUNT
                }
                value={formatValueWithCurrencyPrefix(
                  amount,
                  currencyCode as CurrencyCode,
                  /**  currency format without prefix */
                  '0,0.00',
                )}
              />
              <InfoItem
                gridSize={3}
                itemTitle="Currency"
                dataId={
                  coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_CURRENCY_CODE
                }
                value={currencyCode}
              />
              <InfoItem
                gridSize={3}
                itemTitle="Frequency"
                value={frequency}
                dataId={
                  coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_FREQUENCY
                }
              />
              <InfoItem
                gridSize={3}
                dataId={
                  coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_TARGET_COMPANY
                }
                itemTitle="Target company"
                value={targetCompany}
              />
            </Grid>
          </div>
        ),
      )}
    </div>
  );
};
