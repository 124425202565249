import { TextField } from '@tackle-io/platform-ui';
import { useFormikContext } from 'formik';
import { Information } from 'mdi-material-ui';
import { Box, Tooltip } from 'vendor/material';
import useStyles from './CurrencyFormField.styles';
import { Label } from 'pages/CoSell/components/Label/Label';
import React from 'react';
import MoneyIcon from 'packages/cosell/assets/MoneyIcon';

interface CurrencyFormFieldProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  placeHolder?: any;
  dataId?: string;
}

export const CurrencyFormField: React.FC<CurrencyFormFieldProps> = ({
  name,
  label,
  required,
  disabled = false,
  tooltipText,
  placeHolder,
  dataId = null,
}) => {
  const classes = useStyles();
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext();

  const textFieldLabel = tooltipText ? (
    <Box
      className={classes.formLabel}
      data-id={dataId ? dataId : `${name}Label`}
    >
      <Label label={label} required={required} />
      <Tooltip
        className={classes.tooltip}
        title={tooltipText}
        placement="top-start"
      >
        <Information fontSize="small" />
      </Tooltip>
    </Box>
  ) : (
    <Box
      className={classes.formLabel}
      data-id={dataId ? dataId : `${name}Label`}
    >
      <Label label={label} required={required} />
    </Box>
  );

  return (
    <TextField
      iconLeft={<MoneyIcon />}
      className={classes.currencyFormField}
      id={name}
      data-id={dataId ? dataId : name}
      name={name}
      label={textFieldLabel}
      onChange={handleChange(name)}
      onBlur={handleBlur}
      value={values[name]}
      error={touched[name] && errors[name]}
      disabled={disabled}
      type="number"
      mode="numberformat" // TODO: this number format is breaking the validation error message showing up.
      decimalScale={0}
      numberPrefix=""
      placeholder={placeHolder}
    />
  );
};
