import { DisplayCloudType } from 'packages/cosell/src/types/enums/CloudTypesEnum';
import AwsCoSells from './AwsCoSells/AwsCoSells';

/** Co-sell Widget for Salesforce canvas
 *  TODO: add tabs component to select cloud provider
 */
export default function CoSellSfTackleWidget({ crmId }: { crmId: string }) {
  /** temporary until Tabs component is added to manage selected cloud state */
  const selectedCloud = DisplayCloudType.AWS;

  return (
    <>
      {selectedCloud === DisplayCloudType.AWS && <AwsCoSells crmId={crmId} />}
    </>
  );
}
