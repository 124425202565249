import { Loader } from '@tackle-io/platform-ui';
import AceInvitationDetails from './AceInvitationDetails/AceInvitationDetails';
import { useAceInvitation } from 'packages/cosell/api/hooks/useAceInvitation';
import { useMemo } from 'react';

const UnifiedInvitationDetails = ({
  match,
}: {
  match: { params: { [key: string]: string }; url: string };
}) => {
  const invitationId = useMemo(
    () => match.params.invitationId?.split('?')[0],
    [match.params.invitationId],
  );
  const { aceInvitationQuery } = useAceInvitation({ invitationId });
  const invitation = aceInvitationQuery?.data;

  // this invitationError property from useQuery
  if (aceInvitationQuery.isError) {
    return <div>Error loading invitation</div>;
  }

  if (aceInvitationQuery.isLoading) {
    return <Loader />;
  }

  if (!invitation) {
    return <div>No invitation found</div>;
  }

  return <AceInvitationDetails invitation={invitation} />;
};

export default UnifiedInvitationDetails;
