import { createContext, useContext } from 'react';
import { PageInfo, useDataGridQueryQuery } from 'generated/graphql';
import { useParams } from 'react-router';

export interface ContractPayment {
  PAYMENT_ID: string | null;
  DISBURSEMENT_DATE: string | null;
  PAYMENT_TERMS: string | null;
  CLOUD_MARKETPLACE: string | null;
  INVOICE_DATE: string | null;
  PAYMENT_DUE_DATE: string | null;
  STATUS: string | null;
  GROSS_AMOUNT: string | null;
  MARKETPLACE_FEE: string | null;
  MARKETPLACE_FEE_PERCENT: string | null;
  NET_AMOUNT: string | null;
  CURRENCY: string | null;
  EXCHANGE_RATE: string | null;
  ORIGIN_CURRENCY_GROSS_AMOUNT: string | null;
  ORIGIN_CURRENCY_MARKETPLACE_FEE: string | null;
  ORIGIN_CURRENCY_NET_AMOUNT: string | null;
}

const getColumns = (): string[] => {
  return [
    'PAYMENT_ID',
    'DISBURSEMENT_DATE',
    'PAYMENT_TERMS',
    'CLOUD_MARKETPLACE',
    'INVOICE_DATE',
    'PAYMENT_DUE_DATE',
    'STATUS',
    'GROSS_AMOUNT',
    'MARKETPLACE_FEE',
    'MARKETPLACE_FEE_PERCENT',
    'NET_AMOUNT',
    'CURRENCY',
    'EXCHANGE_RATE',
    'ORIGIN_CURRENCY_GROSS_AMOUNT',
    'ORIGIN_CURRENCY_MARKETPLACE_FEE',
    'ORIGIN_CURRENCY_NET_AMOUNT',
  ];
};

const mapDataGridToContractPayments = (
  headers?: string[],
  rows?: Array<Array<string | null>>,
): ContractPayment[] | null => {
  if (!rows || !headers) return null;

  return rows.map((row) => {
    const contractPayment: Partial<ContractPayment> = {};
    headers.forEach((header, index) => {
      contractPayment[header as keyof ContractPayment] = row[index] ?? null;
    });
    return contractPayment as ContractPayment;
  });
};

interface ContractPaymentsContextProps {
  payments?: ContractPayment[] | null;
  pageInfo?: PageInfo | null;
  loading?: boolean;
  error?: any;
}

const ContractPaymentsContext =
  createContext<ContractPaymentsContextProps | null>(null);

export const ContractPaymentsDataProvider: React.FC = ({ children }) => {
  const { contractId } = useParams<{ contractId: string }>();
  const columns = getColumns();
  const { data, loading, error } = useDataGridQueryQuery({
    variables: {
      input: {
        dataPool: {
          name: process.env.REACT_APP_PAYMENTS_DATA_POOL,
        },
        columns,
        orderByColumn: columns.indexOf('DISBURSEMENT_DATE'),
        filterSql: ` SUBSCRIPTION_ID = '${contractId}'`,
        sort: 'DESC',
      },
    },
  });

  const payments = mapDataGridToContractPayments(
    data?.dataGrid?.headers,
    data?.dataGrid?.rows,
  );

  return (
    <ContractPaymentsContext.Provider
      value={{
        payments,
        pageInfo: data?.dataGrid?.pageInfo || null,
        loading,
        error,
      }}
    >
      {children}
    </ContractPaymentsContext.Provider>
  );
};

export const useContractPaymentsData = (): ContractPaymentsContextProps => {
  const context = useContext(ContractPaymentsContext);
  if (!context) {
    throw new Error(
      'useContractPaymentData must be used within a ContractPaymentsDataProvider',
    );
  }
  return context;
};
