import { Tag } from '@tackle-io/platform-ui';
import React from 'react';
import { useStatusTagV2Styles } from './StatusTag.styles';
import {
  ContractStatusEnum,
  getContractStatusText,
} from '../ContractsTable/utils';

interface StatusTagProps {
  label?: ContractStatusEnum | string;
}

const getTextColor = (status?: ContractStatusEnum | string): string => {
  switch (status?.toLowerCase()) {
    case 'active':
      return 'green';
    case 'cancelled':
      return 'red';
    case 'expired':
      return 'blue';
    case 'renewed':
      return 'green';
    case 'archived':
      return 'blue';
    case 'terminated':
      return 'red';
    default:
      return 'standard';
  }
};

const formatLabel = (status: string): string => {
  if (status === 'Expired') return 'Ended';
  if (status === status.toUpperCase()) {
    return status.charAt(0) + status.slice(1).toLowerCase();
  }
  return status;
};

export const StatusTag: React.FC<StatusTagProps> = ({ label: status }) => {
  const classes = useStatusTagV2Styles();
  const statusText = getContractStatusText(status);
  const color = getTextColor(status);
  const tagClass =
    color === 'standard' ? classes.standardTag : classes.statusTag;

  return status ? (
    <Tag className={tagClass} color={color}>
      {formatLabel(statusText)}
    </Tag>
  ) : null;
};
