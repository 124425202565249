import { useQuery } from '@tanstack/react-query';
import coSellClient from '../coSellClient';
import { AceInvitationResponse } from 'packages/cosell/src/types/responses/AceInvitationResponse';

export const useAceInvitation = ({
  invitationId,
}: {
  invitationId?: string;
}) => {
  const aceInvitationQuery = useQuery<AceInvitationResponse>({
    queryKey: ['co-sell-aws-invitation', invitationId],
    queryFn: () => coSellClient.getInvitationById(invitationId!),
    enabled: !!invitationId,
    initialData: {} as AceInvitationResponse,
  });

  return {
    aceInvitationQuery,
  };
};
