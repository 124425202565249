import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import SalesforceCanvasEntry from 'packages/cosell/src/pages/SalesForceCanvasEntry';

/** opportunity refers to Tackle Opportunity */
export default function TackleWidgetCoSellView() {
  const { vendorId, record } = useCanvasSession();

  if (!record?.Id) {
    return (
      <p>
        Unable to get Salesforce Opportunity ID. Please verify with your admin
        this component is on the Salesforce Opportunity page.
      </p>
    );
  }

  const handleCoSellAction = ({
    action,
    data,
  }: {
    action: string;
    data: Record<string, string>;
  }) => {
    console.log('handleCoSellAction', action, data);
  };

  return (
    <SalesforceCanvasEntry
      component={{
        id: 'tackleWidget',
        crmId: record.Id,
        onAction: handleCoSellAction,
      }}
      vendorId={vendorId}
    />
  );
}
