import { Button } from '@tackle-io/platform-ui';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import useCoSellSfCanvasActions from '../hooks/useCoSellSfCanvasActions';

export default function CanvasCoSellDetails() {
  const { payload } = useCanvasSession();
  const { handleCoSellAction } = useCoSellSfCanvasActions();
  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'start',
        height: '100%',
      }}
    >
      <h1>{`${payload.action} ${payload.cloud} ${payload.domain}`}</h1>
      <pre>{JSON.stringify(payload, null, 2)}</pre>
      <Button
        onClick={() =>
          handleCoSellAction({ id: 'go-back-to-opportunity-root' })
        }
      >
        Back
      </Button>
    </div>
  );
}
