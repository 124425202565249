import React from 'react';
import { Box, Divider, Grid } from 'vendor/material';
import { Page } from 'components';
import useStyles from './AceInvitationDetails.styles';
import { AceInvitationResponse } from 'packages/cosell/src/types/responses/AceInvitationResponse';
import { Banner } from '@tackle-io/platform-ui';
import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import InvitationDetailsHeader from './components/InvitationDetailsHeader';
import ReceiverResponsibilities from './components/ReceiverResponsibilities';
import AceCustomerDetails from './components/AceCustomerDetails';
import AceProjectDetails from './components/AceProjectDetails';
import RightRailCard from './components/RightRail/RightRailCard';
import { SOURCE_TAG } from 'packages/cosell/src/utilities/constants';

interface AceSingleInvitationProps {
  invitation: AceInvitationResponse;
}

const AceInvitationDetails: React.FC<AceSingleInvitationProps> = ({
  invitation,
}) => {
  const classes = useStyles();
  return (
    <Page className={classes.detailsPage}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box mt={2}>
            <InvitationDetailsHeader
              title={invitation.engagementTitle}
              status={invitation.status}
            />
          </Box>
        </Grid>
        {invitation.status === AceInvitationCloudStatusEnum.PENDING && (
          <Grid item xs={12}>
            <Banner
              borderPosition="top"
              defaultOpen={false}
              isCollapsible={false}
              title="Invitations must be accepted to view all opportunity details."
            />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <ReceiverResponsibilities />

          <Box mt={4}>
            <AceCustomerDetails {...invitation?.opportunityInvitationPayload} />
          </Box>
          <Box mt={4}>
            <AceProjectDetails {...invitation?.opportunityInvitationPayload} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <RightRailCard
            status={invitation?.status}
            origin={SOURCE_TAG.INBOUND}
            awsOpportunityTeam={
              invitation?.opportunityInvitationPayload?.senderContacts
            }
          />

          <Box mt={4} mb={4}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default AceInvitationDetails;
